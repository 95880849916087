@import "../../../styles/colors";

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  border-bottom: 1px solid var(--sidebar-border-color-right);
  -webkit-box-shadow: 0 3px 5px var(--shadow-color);
  -moz-box-shadow: 0 3px 5px var(--shadow-color);
  box-shadow: 2px 5px 10px var(--shadow-color);

  &-block {
    display: flex;
    align-items: center;

    &-uilogo {
      display: flex;
      margin-right: 10px;
    }
  }

  &-block__sidebar {
    display: flex;
    justify-content: center;
    gap: 8px;
    width: 66px;
    border-right: 1px solid #96a0bc;

    &--single-expand-icon {
      margin-left: 3px;
    }

    &--double-icon {
      width: 82px;
      margin-left: 12px;

      @media screen and (max-width: 1280px) {
        margin-left: -8px;
      }
    }

    div {
      padding: 5px 10px;
      cursor: pointer;

      &:first-of-type {
        padding-right: 0;
      }
      &:nth-of-type(2) {
        padding-left: 0;
      }
    }
  }

  &-block__notification {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
    padding: 13px 0;
    cursor: pointer;
  }

  &-block__toggle {
    padding: 8px 20px 8px 0;
    border-right: 1px solid #96a0bc;
    margin-right: 10px;
  }

  &-block__profile {
    display: flex;
    margin: 0 10px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: var(--text-color-card);
    cursor: pointer;
    position: relative;
  }
}
