@import "../../../styles/colors";

.agent {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 25px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 40px;
  }

  .search-sort-wrapper {
    display: flex;

    .search-wrapper {
      margin: 0 0.5rem 0 0.5rem;
      width: 250px;

      &__header {
        color: var(--settings-header-text);
        font-size: 12px;
        display: flex;
        align-items: center;
        min-height: 20px;
        margin-bottom: 5px;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        border-radius: 4px;

        svg {
          margin-left: 0px;
          margin-right: 5px;
        }

        input {
          font-size: 16px;

          border: none;
          background-color: transparent;
          color: var(--header-search-text-color);

          &:focus {
            outline: none;
          }
        }
      }
    }

    .sort-dropdown {
      margin: 0 0.5rem 0 0.5rem;

      &__header {
        color: var(--settings-header-text);
        font-size: 12px;
        display: flex;
        align-items: center;
        min-height: 20px;
        margin-bottom: 5px;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
      }
    }
  }

  &__details {
    position: absolute;
    background: var(--analysis-report-modal-background);
    width: 70%;
    height: 100%;
    right: 0;
    top: 0;
    animation: animate 0.3s linear;
    transition: 1ms;

    &__tabs {
      height: 86%;

      &--more-info {
        .button-block {
          position: relative;
          top: 0 !important;
          margin-bottom: -1px;
          display: flex;
          flex-wrap: wrap-reverse;
        }

        .button {
          border: 1px solid var(--border);
          border-radius: 0;
          margin-right: -1px;
        }
      }
    }

    &--exit {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 18px;
      height: 18px;

      svg,
      path {
        fill: var(--analysis-report-modal-close-button);
      }
    }
  }

  @keyframes animate {
    0% {
      right: -1000px;
    }

    100% {
      right: 0;
    }
  }
}