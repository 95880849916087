@import "../../../../styles/colors";

.malware {
  background: var(--dashboard-card-color);
  border-radius: 4px;
  padding: 26px 10px;
  width: 100%;
  color: var(--dashboard-text-color);
  font-weight: bold;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 18px;

  &__counter {
    position: inherit;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid var(--dashboard-table-row-border);

    &-item {
      display: table-cell;
      padding-bottom: 0.5em;
      float: center;
      padding-left: 0.5%;
      padding-right: 8.6px !important;
      width: 1%;

      &-text {
        text-align: center;
        display: table-cell;
        padding-right: 1em;
        font-size: 15px;
        border-right: 2px solid var(--dashboard-table-row-border);
        line-height: 1.6;
      }

      &-icon {
        align-items: center;
        display: table-cell;
        padding-left: 1em;
      }

      &-number {
        font-weight: bold;
        font-size: 25px;
        line-height: 0em;
      }

      &:last-child {
        p {
          font-weight: bold;
        }
      }
    }
  }

  &__chart {
    display: flex;
    width: 100%;
    justify-content: center;

    &-item {
      display: table-cell;
    }
  }
}

textarea {
  margin-top: 8px;
  resize: none;
  width: 100%;
  min-height: 150px;
  font-family: Nunito, serif;
  border-radius: 4px;
  padding: 10px;
}

textarea::placeholder {
  color: var(--text-color-card);
  font-family: Nunito, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
