@import "../../../styles/colors";

.search-wrapper-cl {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-left: 2em;
  margin-right: 2em;

  .search-input-cl {
    position: relative;
    input {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      border: none;
      font-size: 16px;
      transition: box-shadow 0.3s ease;
      background-color: var(--card-background-color);
      color: #ddd;

      &:focus {
        box-shadow: 0 0 10px $main--blue;
        outline: none;
        border: none;
      }
    }

    &__loading {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--header-background);
    border: 1px solid var(--header-search-border-color);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    z-index: 10;
    list-style: none;

    li {
      padding: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
