@import "../../../styles/colors";

.gap {
  gap: 50px;
}

.profile__card {
  width: 100%;
  padding: 0 0 0 15px;
  min-height: 500px;

  &-inner {
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &-header {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
    color: var(--profile-name-text-color);
    margin: 20px 0 10px 0;
  }

  &-inner__text {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    text-align: left;
    color: var(--profile-name-text-color);
    margin: 30px 0 0 0;
  }
}
