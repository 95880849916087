@import "../../../styles/colors";
@import "../../../styles/main";
@import "../../../styles/themes/lightTheme";
@import "../../../styles/themes/darkTheme";
@import "../../../styles/themes/dataflowxTheme";

.loader {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: auto;
}

.loaderdefault {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: auto;
  background: url("../../assets/spinner-light.png");
  background-size: cover;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

html[data-theme="dataflowx"] {
  .loader {
    background: url("../../assets/spinner-light.png");
    background-size: cover;
  }
}

html[data-theme="dark"] {
  .loader {
    background: url("../../assets/spinner-light.png");
    background-size: cover;
  }
}

html[data-theme="light"] {
  .loader {
    background: url("../../assets/spinner-dark.png");
    background-size: cover;
  }
}

