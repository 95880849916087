.analyze-script {
  &__second-row {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
  }

  &__input {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 240px;
    max-height: 240px;
    background-color: var(--file-card-input-background-color);
    border: 1px dashed var(--border);
    color: var(--file-card-input-color);
    padding: 10px;
    margin-top: 0px;

    &:focus {
      outline: none;
    }
  }

  &__script {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--file-card-input-background-color);
    color: var(--file-card-input-color);

    &__label {
      font-size: 13px;
    }

    &__value {
      border-radius: 4px;
      color: white;
      background-color: var(--dashboard-table-tabs);
      outline: none;
      border: 1px solid var(--border);
      padding: 8px;
      font-family: Nunito, sans-serif;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      width: 93px;
    }

    &:focus {
      outline: none;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;

    &__container {
      &--submit {
        justify-content: flex-start !important;
      }
    }
  }

  &__submitting-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    color: white;

    option {
      background-color: var(--submit-popup-background);
    }

    select {
      // background-image: url("./icon.svg");
      // background-size: 28px;
      // background-position: 8px center;
      // border: 1px solid var(--border);
      // background-color: var(--submit-popup-background);
      // background-repeat: no-repeat;
      // border-radius: 4px;
      // text-align: right;
      // color: var(--submit-popup-text);
      // display: flex;
      // align-items: center;
      // flex-direction: row;
      // justify-content: flex-end;

      border-radius: 4px;
      color: white;
      background-color: var(--dashboard-table-tabs);
      outline: none;
      border: 1px solid var(--border);
      padding: 8px;
      font-family: Nunito, sans-serif;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    select:hover {
      cursor: pointer;
      background-color: var(--hover-item-color);
    }
  }

  &__second-column {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    margin-right: 50px;
  }

  &__button-block {
    display: flex;
    align-items: center;
    margin-top: -70px;
  }
}