.selectlogo {
    display: flex;
    border: 1px solid var(--dark-theme-element);
    padding: 10px;
    width: 280px;

    &--img {
        border: none;
        width: 100%;
        height: 100%;
        margin-right: 20px;
    }

    input {
        background: transparent;
        color: white;
        height: 32px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px 3px;
    }

    tr {
        color: white;
        width: 100px;
    }

    input[type="file"] {
        display: none;
    }

    &--img-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 5px;

        font-weight: 600;
        color: var(--header-search-text-color);
        background: var(--dark-theme-element);
        border-radius: 4px;
        border: none;
        cursor: pointer;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
    }

    &--img-upload:hover {
        background-color: var(--dark-theme-element)80;
    }
}