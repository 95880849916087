@import "../../../../styles/colors";

.status {
  border-radius: 43px;
  padding: 3px 5px;
  color: var(--header-search-text-color);
  text-align: center;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito, serif;
  text-transform: capitalize;

  &__enabled {
    background: $green;
  }

  &__disabled {
    background: var(--network-blocks-sbox-border);
  }
}