.backup {
  &__body {
    position: relative;
    margin-top: 65px;
    background: var(--settings-background-page);

    .border {
      border-radius: 0 4px 4px 4px;
    }
  }

  &__drag-drop {
    width: 65%;
    padding: 0 20px;
  }

  &__content {
    min-height: 300px;
  }

  .custom-table {
    min-height: 300px !important;
  }

  .custom-table th {
    background: var(--settings-table-header);
    padding: 10px 30px;

    @media screen and (max-width: 1300px) {
      padding: 10px;
    }
  }

  .custom-table td {
    padding: 10px 30px;

    @media screen and (max-width: 1300px) {
      padding: 10px;
    }
  }

  .primary-button {
    padding: 6px 10px;

    @media screen and (max-width: 1300px) {
      font-size: 13px;
    }
  }
}
