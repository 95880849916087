.settings {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;

  &-sidebar {
    background: var(--settings-sidebar-bg);
    border-right: 1px solid var(--settings-sidebar-border);
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    margin-right: 15px;

    @media screen and (max-width: 1300px) {
      margin-right: 5px;
    }

    p {
      font-size: 13px !important;
      cursor: pointer;
    }
  }

  &-sidebar-open {
    width: 210px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;

    @media screen and (max-width: 1400px) {
      width: 180px;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px 30px;

    @media screen and (max-width: 1300px) {
      margin: 20px 10px;
    }

    h3 {
      color: var(--settings-article-value) !important;
      font-size: 16px;
    }
  }
}

.nav {
  width: 60px;
  position: fixed;

  @media screen and (max-width: 1300px) {
    position: initial;
  }

  &-open {
    width: 210px;

    @media screen and (max-width: 1400px) {
      width: 180px;
    }
  }

  &__item {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--settings-header-text);
    padding: 15px 10px 15px 20px;
    height: 54px;

    &-hidden {
      padding: 15px 10px 15px 20px;
      max-width: 60px;
    }
  }

  .menu__item-text {
    @media screen and (max-width: 1400px) {
      margin-right: 0;
    }

    transition: width 0.4s ease-in-out;
  }

  &__item__active {
    border-left: 3px solid var(--color-blue);
    color: var(--sidebar-active-item-color);
    background: var(--sidebar-active-item-background);
    padding: 15px 10px 15px 17px;
    width: 60px;
  }

  &__item-open.nav__item__active {
    padding: 15px 10px 15px 17px !important;
    width: 210px;

    @media screen and (max-width: 1400px) {
      width: 180px;
    }
  }
}