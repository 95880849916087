@import "../colors";

html[data-theme="dark"] {
  --theme-logo-cyber-text: #{$white};
  --theme-logo-fortress-text: #{rgb(60, 130, 180)};

  --layout-bg: #{$dark--bg};
  --border: #{#565d73};
  --background-color: #{$dark--bg};
  --card-background-color: #{$dark--element};
  --text-color-card: #{$grey--text};
  --text-color-card-article: #{$white};
  --text-color-label: #{$grey--text};
  --text-color-checkbox: #{$grey--text};
  --color-blue: #{$main--blue};
  --header-background: #{#262b3c};
  --header-search-border-color: #{$grey--text};
  --header-search-text-color: #{$white};
  --header-search-placeholder-color: #{$grey--text};
  --sidebar-background: #{$dark--element};
  --sidebar-border-color: #{#565d73};
  --sidebar-active-item-color: #{$white};
  --sidebar-active-item-background: #{rgba(159, 162, 180, 0.1)};
  --sidebar-active-icon: #{$white};
  --sidebar-item-secondary: #{rgb(108, 160, 190)};
  --hover-item-color: #{rgba(255, 255, 255, 0.06)};
  --content-background: #{$dark--bg};
  --menu-color: #{$grey--text};

  --tabs-button-color: #{$white};
  --tabs-button-number-bg: #{$dark--element};
  --tabs-button-number-text: #{$white};

  --checkbox-border: #{#4e556a};
  --checkbox-background: #{$dark--bg};

  --file-card-tab-active-background: #{$dark--element};
  --file-card-tab-non-active-background: #{#272c39};
  --file-card-tab-text-active: #{$white};
  --file-card-tab-text-non-active: #{$grey--text};
  --file-card-main-background: #{$dark--element};
  --file-card-icons-color: #{$white};
  --file-card-icons-color-hover: #{$white};
  --file-card-input-background-color: #{$dark--gray};
  --file-card-input-background: #{$dark--gray};
  --file-card-input-color: #{$white};
  --file-card-button-bg-color: #{#444c5e};
  --file-card-button-bg-color-hover: #{#2c313d};
  --file-card-button-shadow-color: #{$main--blue};
  --drag-and-drop-text-color: #{$white};
  --drag-and-drop-icon-background: #{#3a4050};

  --dashboard-value-color: #{$white};
  --dashboard-card-color: #{$dark--element};
  --textarea-background-color: #{$dark--gray};
  --textarea-border-color: #{#565d73};
  --dashboard-table-tabs: #{#272c39};
  --dashboard-table-border: #{$dark--element};
  --dashboard-table-header: #{#404759};
  --dashboard-table-row-border: #{#565d73};
  --dashboard-table-row-file-name: #{$white};
  --dashboard-table-row-hash-icon-hover: #{$white};
  --dashboard-table-row-count: #{#404759};
  --dashboard-table-row-count-text-color: #{$white};
  --dashboard-table-row-os: #{#404759};
  --dashboard-table-row-os-text-color: #{$white};
  --dashboard-table-progress: #{#404759};
  --dashboard-table-resubmit-icon: #{$white};
  --dashboard-table-resubmit-icon-hover: #{$grey--text};
  --dashboard-table-color: #{$grey--text};
  --dashboard-text-color: #{$grey--text};

  --analysis-reports-information-value: #{$white};
  --analysis-reports-information-bg: #{#404759};
  --analysis-reports-classification-bg: #{$dark--element};
  --analysis-reports-information-exe: #{$dark--element};
  --analysis-reports-information-exe-text: #{$grey--text};
  --analysis-reports-table-classification-bg: #{#404759};
  --analysis-reports-table-classification-color: #{$grey--text};
  --analysis-reports-table-tabs: #{#272c39};
  --analysis-reports-table-border: #{$dark--element};
  --analysis-reports-table-header: #{#404759};
  --analysis-reports-table-footer: #{#404759};
  --toggle-button-bg: #{$dark--bg};
  --analysis-reports-color: #{$grey--text};

  --matrix-table-color: #{$dark--element};
  --matrix-table-color2: #{#2e3442};
  --matrix-header-bg: #{#404759};
  --matrix-card-color: #{$grey--text};
  --matrix-card-bg: #{#555d74};

  --new-comment-bg: #{$dark--bg};
  --new-comment-text-color: #{$grey--text};
  --comments-message-color: #{$white};
  --chart-value-color: #{$white};

  --map-region-color: #{#404759};
  --map-button-color: #{$white};
  --map-text-color: #{$grey--text};

  --submit-popup-background: #{$dark--element};
  --submit-popup-text: #{$white};
  --submit-popup-color-dark: #{$light--label};
  --submit-popup-color-bleached: #{#565d73};
  --submit-popup-color-bold: #{$white};
  --submit-popup-color-light: #{$grey--text};
  --submit-popup-br-line: #{#565d73};
  --submit-popup-buttons-background: #{#262b3c};
  --submit-popup-button-cancel: #{$dark--element};

  --submit-popup-submission-title: #{$grey--text};

  --collapse-menu-header: #{#404759};
  --collapse-menu-text: #{$grey--text};
  --collapse-content-background: #{$dark--element};
  --collapse-content-text-color: #{$grey--text};
  --api-call-text-color: #{$white};

  --dropped-file-lists-sidebar-card: #{#555d74};
  --dropped-file-lists-sidebar-color: #{$grey--text};
  --dropped--file-lists-sidebar-border-color: #{#565d73};
  --dropped--file-lists-table-row-border: #{#404759};
  --dropped--file-pagination-color: #{$white};
  --dropped--file-pagination-arrow-active-color: #{$grey--text};
  --dropped--file-pagination-arrow-color: #{#626778};
  --dropped--file--hover--table: #{rgba(204, 204, 204, 0.1)};

  --table-yet-color: #{$grey--text};
  --search-input-bg: #{#404759};
  --search-input-text-color: #{$grey--text};

  --attack-map-popup-background: #{#404759};
  --attack-map-popup-file-background: #{$dark--element};
  --attack-map-popup-title-text: #{$grey--text};
  --attack-map-popup-button: #{$main--blue};
  --attack-map-title-text-color: #{$grey--text};
  --attack-map-button-text: #{$white};
  --attack-map-bg: #{$dark--element};
  --attack-map-card-default-bg: #{#545d76};
  --attack-map-card-text-color: #{$white};
  --attack-map-card-line-color: #{#b2cad4};
  --attack-map-card-virus-line-color: #{#ea7373};
  --attack-map-card-bg-hover: #{#434a5e};

  --details-tab-bg: #{#404759};
  --details-tab-block-bg: #{#272c39};
  --details-text-color: #{$grey--text};
  --details-text-active-color: #{$grey--text};

  --analysis-report-modal-background: #{#404759};
  --analysis-report-modal-close-button: #{$white};
  --analysis-report-modal-file-background: #{$dark--element};
  --analysis-report-modal-file-text: #{$grey--text};
  --analysis-report-modal-title-color: #{$grey--text};
  --analysis-report-modal-text-data: #{$white};
  --analysis-report-modal-content-background: #{$dark--element};

  --av-yara-content-background: #{$dark--element};
  --files-info-text-color: #{$grey--text};
  --files-info-all-text-color: #{$white};

  --scroll-bar-color: #{rgba(0, 0, 0, 0.5)};
  --sort-color: #{#282d3d};
  --sort-color-asc: #{$grey--text};
  --sort-color-desc: #{$grey--text};

  --settings-background-page: #{$dark--element};
  --settings-sidebar-bg: #{$dark--bg};
  --settings-article-value: #{$white};
  --settings-table-header: #{#404759};
  --settings-button-bg: #{#3a4050};
  --settings-field-bg: #{$dark--bg};
  --settings-field-border-color: #{#4e556a};
  --settings-description: #{$grey--text};
  --settings-sidebar-icon: #{#565d73};
  --settings-sidebar-border: #{#565d73};
  --settings-sub-bg: #{#262b3c};
  --settings-text-table: #{$grey--text};
  --settings-header-text: #{$grey--text};

  --toggle-background: #{#626a81};
  --tooltip-background: #{#565d73};
  --tooltip-modal-background: #{$dark--bg};

  --tags-fields-bg: #{$dark--bg};
  --tags-fields-border: #{#4e556a};
  --tags-fields-color: #{$white};
  --tags-fields-placeholder: #{$grey--text};
  --tags-fields-bg-tags: #{#3a4050};
  --tags-filed-label: #{$grey--text};
  --tags-tag-color: #{$grey--text};

  --runningan-vm-connection: #{#3a4050};

  --profile-name-text-color: #{$white};
  --profile-text-color: #{$grey--text};

  --network-blocks-detail-header: #{$grey--text};
  --network-blocks-detail-text: #{$white};
  --network-blocks-domain-border: #{$yellow};
  --network-blocks-snort-url-border: #{$orange};
  --network-blocks-sbox-border: #{$red};
  --network-blocks-sbox-submit-border: #{$main--blue};
  --network-blocks-sbox-button-bg: #{$main--blue};
  --network-blocks-sbox-button-text: #{$white};
  --network-blocks-sbox-button-disabled-bg: #{#5b6269};
  --network-blocks-sbox-button-disabled-text: #{$dark--element};

  --dark-theme-element: #{$dark--element2};
  --shadow-color: #{rgba(15, 75, 125, 0.4)};

  --expandable-card-border-left: #{$green};
  --unexpandable-card-border-left: #{$blue};

  --datepicker-close-bg: #{#216ba5};
  --datepicker-date-bg: #{$main--blue};

  --integrationmarket-card-bg: #{rgba(0, 0, 0, 0.1)};

  --pricing-main: #{#55a0e67e};
  --pricing-main2: #{#222736};
  --pricing-main3: #{#313748};
  --pricing-button: #{#55a0e6};
  --pricing-text: #{$white};
}
