.integration-config {
  background: var(--submit-popup-background);
  width: 610px;
  height: 370px;
  border-radius: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 300px !important;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &__addicon {
    background: transparent;
    display: flex;
    width: auto;
    padding: 5px;
    border: none;
    cursor: pointer;
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
    font-size: 12px;
    font-weight: bolder;
    margin-right: auto;
    height: auto;
    margin-left: 30px;
    margin-top: 10px;
    border-radius: 4px;

    span {
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  &__addicon:hover {
    background-color: var(--color-blue);
  }
}

.integration-config-backdrop {
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.integration-configTable-wrapper {
  // height: 250px;
  min-height: 65%;
  margin-bottom: 10px;
  width: 90%;
  color: #ffffff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  .integration-configTable-header {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    color: var(--dashboard-table-color);

    thead {
      width: 100%;
      text-align: left;
      border-bottom: 1px solid #ffffff;

      td {
        padding: 0 6px;
      }
    }
  }

  .integration-configTable-body-wrapper {
    width: 100%;
    height: 220px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    border-bottom: 2px solid var(--border);
  }

  .integration-configTable-body {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    tbody {
      tr {
        border-bottom: 1px solid var(--border);

        &:hover {
          background-color: var(--analysis-reports-table-header);
          cursor: pointer;
        }

        td {
          padding: 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .no-data {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      td {
        text-align: center;
      }
    }
  }
}

.integration-config-buttons {
  // margin-bottom: -10px;
  padding: 10px 10px 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/***CLOSE BUTTON START**/

.integration-config-close-button {
  &__outer {
    position: relative;
    margin-left: auto;
    width: 30px;
    cursor: pointer;
  }

  &__inner {
    width: inherit;
    text-align: center;
  }

  &__inner:before,
  &__inner:after {
    position: absolute;
    content: "";
    height: 1px;
    width: inherit;
    background: var(--dark-theme-element);
    left: 0;
    transition: all 0.3s ease-in;
  }

  &__inner:before {
    top: 50%;
    transform: rotate(45deg);
  }

  &__inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }

  &__outer:hover label {
    opacity: 1;
  }

  &__outer:hover &__inner:before,
  &__outer:hover &__inner:after {
    transform: rotate(0);
  }

  &__outer:hover &__inner:before {
    top: 0;
  }

  &__outer:hover &__inner:after {
    bottom: 0;
  }

  &__close {
    font-size: 0.55em;
    line-height: 4em;
    text-transform: uppercase;
    color: var(--dark-theme-element);
    transition: all 0.3s ease-in;
    opacity: 0;
    font-weight: bold;
    cursor: pointer;
  }
}

/***CLOSE BUTTON END**/
