.file-upload {
  border: 1px dashed var(--border);
  border-radius: 3px;
  height: 10rem;
  width: 20rem;
  text-align: center;
  position: relative;

  &__input {
    display: none;
  }

  &__label {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    p {
      padding: 0 45px;
      color: white !important;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
    }

    &--active {
      background-color: rgba(255, 255, 255, 0.03);
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--drag-and-drop-icon-background);
  }

  &__drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &__uploaded-file {
    display: flex;
    gap: 10px;
    color: white;
  }
}
