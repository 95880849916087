@import "../../../../styles/colors";

.dashboard__table {
  width: 100%;
  background: var(--card-background-color);
  position: relative;
  border-radius: 0 4px 4px 4px;

  &__container {
    width: 88%;
    display: flex;
    flex-direction: column;
    gap: 55px;
    margin: 5px 7px 150px 0;
  }

  &__header {
    color: white;
  }

  .button-block {
    left: -1px;
    top: -44px;

    @media screen and (max-width: 1300px) {
      top: -40px;
    }
  }

  p,
  td {
    color: var(--dashboard-table-color);
    font-size: 12px;
  }

  .custom-table td {
    padding: 10px 10px;
  }

  .custom-table th {
    padding: 10px 10px;
  }

  &__link {
    display: block;
    word-wrap: normal;
    overflow-wrap: anywhere;
  }

  &__first-row {
    display: flex;
    align-items: center;

    p.filename,
    p.filehash {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.1;
    }

    p.filename {
      color: var(--dashboard-table-row-file-name);
      top: 12px;
    }
  }

  &__filename-hash,
  &__filename-hash-running {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    left: 38px;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__filename-hash-running {
    left: 10px;
    top: 49%;
    transform: translateY(-50%);
  }

  &__hash-copy {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 8px;
    line-height: 1;
  }

  &__time {
    display: block;
    width: 150px;
    word-wrap: normal;
    overflow-wrap: anywhere;
  }

  &__os {
    background: var(--dashboard-table-row-os);
    border-radius: 4px;
    padding: 5px 0;
    width: 50px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    p {
      color: var(--dashboard-table-row-os-text-color);
    }
  }

  &__groupCounter {
    background: var(--dashboard-table-row-os);
    padding: 5px 0;
    border-radius: 4px;
    width: 45px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    p {
      color: var(--dashboard-table-row-os-text-color);
    }
  }

  &__cloudOS {
    border-radius: 4px;
    width: 45px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    p {
      color: var(--dashboard-table-row-os-text-color);
    }
  }

  &__recipient,
  &__sender,
  &__sender-imap,
  &__user {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;

    &--icon {
      float: left;
      margin-right: 8px;
      line-height: 1;
    }
  }

  &__recipient,
  &__sender,
  &__sender-imap {
    width: 120px;
  }

  &__id {
    color: white !important;
  }

  &__server {
    padding-left: 30px;
    padding-right: 30px;
    color: white !important;
  }

  &__secret {
    padding-left: 220px;
    font-size: 11px;
    color: white !important;
  }

  &__networkTraffic {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    color: white !important;
  }

  &__progress {
    height: 6px;
    width: 100px;
    background: var(--dashboard-table-progress);
    border-radius: 19px;
  }

  &__progress-line {
    &__success {
      height: 6px;
      border-radius: 19px;
      background: $dark--element2;
    }

    &__error {
      height: 6px;
      border-radius: 19px;
      background: var(--network-blocks-sbox-border);
    }
  }

  &__linkVm {
    text-decoration: none;
    color: var(--header-search-text-color);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }

  &__linkVmDiv {
    border: 2px black;
    border-radius: 5px;
    background-color: var(--drag-and-drop-icon-background);
    width: 10em;
    height: 2.4em;
    text-align: center;
    padding-top: 0.5em;
  }

  &__compressedFileCount {
    background-color: var(--dashboard-table-row-count);
    width: 30px !important;
    height: 30px !important;
    margin: 0;
    padding-top: 5px;
    border-radius: 100%;
    text-align: center;
    color: var(--dashboard-table-row-count-text-color);
  }

  &__threedots {
    position: relative;

    &__icon {
      width: min-content;
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: white;
          }
        }
      }
    }

    &__menu {
      position: absolute;
      min-width: 10rem;
      color: var(--tabs-button-color);
      display: flex;
      flex-direction: column;
      border: 1px solid var(--border);
      padding: 6px 0;
      align-items: start;
      background-color: var(--attack-map-bg);
      z-index: 999;
      cursor: default;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    &__menu div {
      cursor: pointer;
      width: 100%;

      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 4px 12px;

      &:hover {
        background-color: var(--analysis-reports-table-header);
      }
    }
  }

  &__staticResultsRunning {
    width: 50px;

    &__clean {
      background-color: #58e655 !important;
      color: #000000 !important;
      // border: 1px solid #58e655;
    }

    &__suspicious {
      background-color: #e69b55 !important;
      color: #000000 !important;
      // border: 1px solid #e69b55;
    }

    &__malicious {
      background-color: #e65555 !important;
      color: #000000 !important;
      // border: 1px solid#e65555;
    }

    &__none {
      background-color: (--border) !important;
      // border: 1px solid var(--border);
    }
  }
}

.severity-status {
  border-radius: 12px;
  width: 90px;
  padding: 6px 0;
  color: white;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  color: black;

  @media screen and (max-width: 1200px) {
    font-size: 11px;
  }

  &__success {
    background: #58e655;
    padding: 5px 0;
  }

  &__loading {
    text-align: center;
    //height: 30px;
    padding: 6px 0;
    margin-right: 8px;
  }

  &__apt {
    background: #e65555;
    position: relative;
    overflow: hidden;

    p {
      position: relative;
      color: black;
      z-index: 9999;
      background-color: #e65555;
      border-radius: 3px;
      padding: 1px 2px;
      display: inline-block;
      width: 100%;
      line-height: 1;
      color: black !important;
    }

    &::after {
      content: "APT";
      position: absolute;
      color: white;
      font-size: 11.5px;
      font-weight: 500;
      right: 4px;
      bottom: -2px;
      line-height: 1;
      z-index: 9999;
    }
  }

  &__secondary {
    background: #e65555;
    p {
      color: black !important;
    }
  }

  &__suspicious {
    background: #e69b55;
  }

  &__none {
    background: var(--border);
  }

  &__check {
    cursor: pointer;
    font-size: 10.5px !important;
    border: none;
    background-color: var(--attack-map-popup-button);
    color: var(--attack-map-button-text);
    border-radius: 4px;
    padding: 8px;
    width: 100% !important;
    margin: 0 !important;
    height: unset !important;

    &--disabled {
      cursor: not-allowed;
      background-color: var(--network-blocks-sbox-button-disabled-bg);
      color: var(--card-background-color);

      &:hover {
        cursor: not-allowed;
        background-color: var(--network-blocks-sbox-button-disabled-bg) !important;
        color: var(--card-background-color);
      }
    }
  }

  &__icon {
    -webkit-animation: fadeinout 1.6s linear infinite;
    animation: fadeinout 1.6s linear infinite;
    opacity: 0;

    @-webkit-keyframes fadeinout {
      50% {
        opacity: 1;
      }
    }

    @keyframes fadeinout {
      50% {
        opacity: 1;
      }
    }

    &:hover {
      opacity: 1;
      border-bottom: 1px solid var(--dark-theme-element);

      svg {
        path {
          fill: var(--dark-theme-element);
        }
      }
    }
  }

  &__error {
    font-size: 15px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
  }

  &__delete-from-list {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: unset !important;
    height: unset !important;
    margin: 0 !important;

    &:hover {
      background: none;
    }
  }
}

span.dashboard__table__link {
  color: var(--color-blue);
  font-size: 12px;
}

td.dashboard-table_submit-type {
  color: white !important;
}

td.dashboard-table_link-run {
  position: relative;
  width: 522px !important;

  .running-filename {
    color: var(--dashboard-table-row-file-name);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
  }

  .running-filehash {
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 16px;
      margin-left: -4px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

td.dashboard-table_time-run {
  .dashboard__table__time {
    width: max-content;
  }
}

td.dashboard-table_static-run {
  // max-width: 65px;
}

td.dashboard-table_os {
  padding-left: 10%;
}

td.dashboard-table_severity {
  padding-left: 10%;
}

td.dashboard-table_link {
  position: relative;
  width: 555px;

  &:before {
    content: " ";
    visibility: hidden;
  }
}

td.dashboard-table_user {
  width: 100px;
}

td.dashboard-table_user-run {
  width: 140px;
  position: relative;

  .dashboard__table__user {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 0;

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }
}

.favorite__icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    path,
    polygon {
      fill: var(--text-color-card);
    }
  }
}
