@import "../../../../../../../styles/colors";

.summary {
  border-radius: 0 4px 4px 4px;

  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 14px 0;
  }

  &__dot {
    color: var(--settings-text-table);
    font-size: 20px;
    margin: 0 15px;
  }

  .send-test {
    border-bottom: 1px solid var(--border);
    padding: 5px 20px 20px 20px;

    &__type {
      color: var(--text-color-card);
      font-family: Nunito, serif;
      font-size: 12px;
    }
  }

  .digest {
    padding: 5px 20px 20px 20px;
  }

  &__article {
    color: var(--settings-article-value);
    font-size: 16px;
    line-height: 22px;
    margin: 14px 0;
  }

  .select-box--box {
    margin-top: 5px;
  }

  .label {
    color: var(--text-color-card) !important;
  }

  .form-group input:checked+label:after {
    top: 2px;
  }

  .toggle-button {
    margin-left: 0;
  }
}