.file-card-button {
  width: 73px;
  height: 55px;
  padding: 4px;
  text-align: center;
  color: var(--file-card-icons-color);
  cursor: pointer;
  margin-right: 12px;
  background-color: var(--file-card-button-bg-color);
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;

  path,
  circle {
    stroke: var(--file-card-icons-color);
  }

  &:hover {
    background-color: var(--file-card-button-bg-color-hover);
    box-shadow: 0 0 5px 2px var(--file-card-button-shadow-color);
    color: var(--file-card-icons-color-hover);
  }

  &:hover path,
  &:hover circle {
    stroke: var(--file-card-icons-color-hover);
  }
}

