@import "../../../../styles/colors";
.container-services {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    &__heading {
      color: white;
      display: flex;
      align-items: center;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background: var(--card-background-color);
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;

    &--icon {
      flex: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 50%;
      border: 1px solid white;
      color: white;
      font-size: 9px;
    }

    &--text {
      color: white;
      font-size: 12px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--signal {
      margin-left: auto;

      &--status {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        -ms-transform: none;
        transform: none;

        &.online {
          border-color: #78d965;
          background: #94e185;
        }

        &.offline {
          background-color: #c9404d;
          border-color: #c42c3b;
        }

        @keyframes pulse {
          0% {
            opacity: 0.5;
          }

          70% {
            opacity: 0;
            transform: scale(2.5);
          }
          100% {
            opacity: 0;
          }
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          animation: pulse 2s infinite;
          opacity: 1;
          border-radius: 50%;
          top: 0;
          left: 0;
        }

        &.online:after {
          background: #94e185;
        }

        &.offline:after {
          background: #c9404d;
        }
      }
    }
  }

  &__spinner {
    margin: 0;
    height: 20px;
    width: 21px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
