@import "../../../styles/colors";

.email {
  display: flex;
  flex-direction: column;
  margin: 0 30px 70px 40px;
  @media screen and (max-width: 1280px) {
    margin: 0 30px 10px 20px;
  }

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }
  &__body {
    display: flex;
    width: 100%;
    margin-bottom: 60px;
    gap: 12px;

    &__item {
      display: flex;
    }

    &__card {
      flex: 1.5;
      max-width: 270px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    table {
      flex: 1;
      padding: 20px;
      text-align: center;
      thead {
        color: white;
        font-size: 14px;
        font-weight: bolder;
      }
      color: var(--dashboard-text-color);
      border-radius: 4px 4px 4px 4px;
      width: 20%;
      background: var(--card-background-color);
    }
  }
}
.bg {
  background: var(--card-background-color);
}
