@import "./colors";
@import "./themes/darkTheme";
@import "./themes/lightTheme";
@import "./themes/dataflowxTheme";
@import "./flexbox";
@import "./borders";
@import "indents";

* {
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.button-block {
  position: absolute;
  top: -43px;
  left: 0;
  border-radius: 4px 4px 0 0;
  z-index: 100;

  @media screen and (max-width: 1300px) {
    top: -39px;
  }
}

.button {
  outline: none;
  padding: 10px;
  border: 1px solid var(--border);
  cursor: pointer;
  background: var(--dashboard-table-tabs);
  margin: 0;
  color: var(--tabs-button-color);
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border-left: 1px solid var(--card-background-color);
  min-width: 50px;

  @media screen and (max-width: 1300px) {
    padding: 8px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-left: 1px solid var(--border);
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-right: 1px solid var(--border);
    border-top: 1px solid var(--border);
  }

  &__active {
    background: var(--card-background-color);
    margin: 0;
    color: var(--dashboard-value-color);
    border-bottom: 1px solid var(--card-background-color) !important;
    border-left: 1px solid var(--card-background-color);
  }

  &__active2 {
    background: var(--card-background-color);
    margin: 0;
    color: var(--dashboard-value-color);

    border-left: 1px solid var(--card-background-color);
  }
}

.article {
  color: var(--analysis-reports-information-value);
  font-weight: 700;
  font-size: 16px;
}

.primary-button {
  background: var(--datepicker-date-bg);
  border: 1px solid var(--datepicker-date-bg);
  border-radius: 4px;
  font-family: Nunito, serif;
  font-size: 14px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: $white;
  display: block;
  cursor: pointer;
  text-transform: capitalize;
}

.default-button {
  background: var(--settings-button-bg);
  border: 1px solid var(--settings-button-bg);
  border-radius: 4px;
  font-family: Nunito, serif;
  font-size: 14px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: $white;
  display: block;
  cursor: pointer;
}

.danger-button {
  background: #ff4c4c;
  border: 1px solid #ff4c4c;
  border-radius: 4px;
  font-family: Nunito, serif;
  font-size: 14px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: $white;
  display: block;
  cursor: pointer;
}

.success-button {
  background: #28c30f;
  border: 1px solid #28c30f;
  border-radius: 4px;
  font-family: Nunito, serif;
  font-size: 14px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: $white;
  display: block;
  cursor: pointer;
}