@import "../../../../../styles/colors.scss";

.yara-rules-table {
  background: var(--card-background-color);
  margin-top: 20px;
  min-height: 300px;
  position: relative;
  padding-bottom: 80px;
  border-radius: 4px;

  &__button {
    position: absolute;
    bottom: 24px;
    right: 24px;
  }

  .custom-table th {
    background: var(--settings-table-header);
    padding: 10px;
  }

  .custom-table td {
    padding: 10px 30px;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    color: var(--settings-article-value);
    padding: 20px;
  }
}
