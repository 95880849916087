@import "../../../styles/colors";
.tooltip {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: relative;

  &__modal {
    position: absolute;
    top: 0;
    left: 30px;
    width: 242px;
    z-index: 100000;
    color: var(--settings-article-value);
    background: var(--tooltip-modal-background);
    padding: 10px 20px 10px 10px;
    border-radius: 4px;
    border: 1px solid var(--border);
    font-size: 12px;
    font-family: Nunito, serif;

    &-button {
      position: absolute;
      top: 5px;
      right: 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      color: var(--settings-article-value);
    }
  }
}
