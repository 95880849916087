@import "../../../styles/colors";

.drag-and-drop {
  border: 1px dashed var(--border);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  height: 240px;
  max-height: 240px;

  &__main {
    width: 100%;
    border: none;
    border-color: transparent;
    color: var(--color-blue);
    padding: 6px 8px;
    height: 100%;
  }

  &__button-wrapper {
    background-color: var(--file-card-button-bg-color);

    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  &__button {
    width: 100%;
    border: none;
    border-color: transparent;
    color: var(--color-blue);
  }

  &__dropzone {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--text-color-card) white;
    height: 100%;
    max-height: 240px;
    width: 100%;

    &__wrapper {
      height: 100%;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }

    &__browse {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      width: 100%;
    }
  }

  &__dropzone::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &__dropzone::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &__dropzone::-webkit-scrollbar-thumb {
    background: var(--text-color-card);
    border-radius: 10px;
  }

  /* Handle on hover */
  &__dropzone::-webkit-scrollbar-thumb:hover {
    background: #7c849d;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--drag-and-drop-icon-background);
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  &__text {
    width: 148px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: var(--drag-and-drop-text-color);

    &--highlight {
      color: var(--color-blue);
    }

    &--file {
      text-transform: capitalize;
      color: var(--drag-and-drop-text-color);
      display: flex;

      table {
        width: 100%;
      }

      tr {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 4px;
        font-size: 12px;
        margin-bottom: 3px;

        td {
          display: flex;
          align-items: center;
        }
      }

      &--name {
        position: relative;
        width: 43%;

        &--file {
          position: absolute;
          left: 27px;
          right: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--icon {
          display: flex;
          align-items: center;
        }
      }

      &--size {
        width: 21%;
        word-wrap: break-word;

        &--text {
          margin-left: 1%;
          vertical-align: text-bottom;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &--type {
        position: relative;
        width: 31%;
        word-wrap: break-word;

        &:before {
          content: " ";
          visibility: hidden;
        }

        &--text {
          position: absolute;
          left: 27px;
          right: 0;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &--button {
        cursor: pointer;
        margin-left: auto;
        margin-right: 3px;

        &--remove {
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          background-color: var(--file-card-button-bg-color);
          border-radius: 4px;

          &:hover {
            background-color: var(--file-card-button-bg-color-hover);
            border: 1px solid var(--file-card-button-shadow-color);
          }
        }
      }
    }
  }

  &__files-table {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    font-weight: 500;
  }
}

.cloudmodal-login {
  width: 0;
  height: 0;

  position: absolute !important;
  left: 40% !important;
}
