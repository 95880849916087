.dropdown {
  width: 100%;

  &__title {
    color: white;
    background-color: var(--dashboard-table-tabs);
    outline: none;
    border: 1px solid var(--border);
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    font-family: Nunito, sans-serif;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: var(--hover-item-color);
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-left: 1px solid var(--border);
    margin: 8px 0 8px 12px;
  }

  &__item {
    padding: 4px 8px;
    color: white;
    font-size: 12px;
    cursor: pointer;
    position: relative;

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 3px;
    }

    &__active {
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      width: 11px;
      height: 11px;
      border-radius: 100%;
      background-color: var(--details-text-active-color);
      border: 1px solid black;
    }
  }
}
