@import "../../../../styles/colors";

.files-info {
  width: 42%;
  position: relative;

  @media screen and (max-width: 1250px) {
    width: 100%;
    margin-bottom: 40px;
  }

  &__tabs {
    position: absolute;
    top: -43px;
    left: 0;
    z-index: 100;

    @media screen and (max-width: 1300px) {
      top: -39px;
    }

    button {
      border-bottom: 1px solid var(--border);
    }
  }

  &__body {
    border: 1px solid var(--border);
    background: var(--matrix-table-color);
    border-radius: 0 4px 4px 4px;
    position: relative;
    height: 100%;
    padding: 15px;

  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    p {
      color: var(--analysis-reports-color);
      font-size: 12px;
      overflow-wrap: break-word;
      word-wrap: break-word;

      @media screen and (max-width: 1600px) {
        font-size: 11px;
      }

      @media screen and (max-width: 1250px) {
        font-size: 12px;
      }
    }
  }

}
