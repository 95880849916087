@import "../../styles/colors.scss";


.maintenance {
  background-color: var(--layout-bg);
  color: var(--header-search-text-color);
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

p {
  margin-bottom: 40px;
}