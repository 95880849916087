@import "../../../../styles/colors";

.verdict {
  border-radius: 4px;
  padding: 3px;
  color: var(--header-search-text-color);
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito, serif;

  &__malicios {
    background: #ec374a;
  }

  &__suspicion {
    background: var(--network-blocks-snort-url-border);
  }

  &__clean {
    background: $green;
  }
}