.android__download_artifacts {
  outline: none;
  border-radius: 4px;
  color: white;
  background-color: var(--dashboard-table-tabs);
  padding: 8px;
  padding-right: 5px;
  border: 1px solid var(--border);
  font-family: Nunito, sans-serif;
  font-size: 13px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
