p,
h1,
h2,
h3,
h4,
h5,
h6,
body {
    margin: 0;
    font-family: Nunito, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
