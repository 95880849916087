.url-analysis {
  &__content {
    padding: 14px 14px 0 14px;
  }
  &__tabs {
    color: var(--file-card-tab-text-non-active);
    display: inline-flex;

    div {
      cursor: pointer;
      margin-left: 18px;
      margin-bottom: 12px;

      &:first-child {
        margin-left: 0;
      }
    }
    &--active {
      color: var(--file-card-tab-text-active);
    }
  }
}
