@import "../../../styles/colors";

.dropped-files {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  background: var(--matrix-table-color);
  border-radius: 0 4px 4px 4px;
  min-height: 572px;
  position: relative;

  &__sidebar {
    width: 16%;
    border-right: 1px solid var(--dropped--file-lists-sidebar-border-color);
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 2px;

    &__download {
      outline: none;
      border-radius: 4px;
      color: white;
      background-color: var(--dashboard-table-tabs);
      padding: 8px;
      padding-right: 5px;
      border: 1px solid var(--border);
      font-family: Nunito, sans-serif;
      font-size: 13px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &__type-card {
      color: white;
      background-color: var(--dashboard-table-tabs);
      outline: none;
      border: 1px solid var(--border);
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
      font-family: Nunito, sans-serif;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: var(--hover-item-color);
      }

      &--active {
        background-color: var(--card-background-color);
      }

      &__files {
        display: flex;
        flex-direction: column;
        gap: 2px;
        border-left: 1px solid var(--border);
        margin: 8px 0 8px 12px;
        // max-height: 164px;
        // overflow: auto;

        // /* width */
        // &::-webkit-scrollbar {
        //   width: 6px;
        //   border-radius: 40px;
        // }

        // /* Handle */
        // &::-webkit-scrollbar-thumb {
        //   background: rgba($color: #000000, $alpha: 0.5);
        //   border-radius: 10px;
        // }

        // /* Handle on hover */
        // &::-webkit-scrollbar-thumb:hover {
        //   background: var(--header-search-text-color);
        //   border-radius: 10px;
        // }
      }

      &__file {
        padding: 4px 8px;
        color: white;
        font-size: 12px;
        cursor: pointer;
        position: relative;

        // &--active {
        //   border-left: 1px solid #58e655;
        // }

        &--matched {
          border-left: 1px solid #ec374a;
        }

        // &--unmatched {
        //   border-left: 1px solid #58e655;
        // }

        &__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__active-circle {
          position: absolute;
          right: 6px;
          top: 50%;
          transform: translateY(-50%);
          width: 11px;
          height: 11px;
          border-radius: 100%;
          background-color: var(--details-text-active-color);
          border: 1px solid black;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 84%;
    position: relative;
    padding: 10px;
    padding-bottom: 40px;

    &-th {
      width: 20%;

      @media screen and (max-width: 1500px) {
        min-width: 320px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }
    &-last-child {
      width: 5%;
      @media screen and (max-width: 1500px) {
        min-width: 320px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }
    &-type {
      width: 150px;
      @media screen and (max-width: 1500px) {
        min-width: 320px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }

    .custom-table th,
    td {
      @media screen and (max-width: 1400px) {
        font-size: 10px;
      }
    }

    &__error {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 18px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--dropped--file-lists-sidebar-border-color);
      width: 100%;
      gap: 6px;
    }

    &__file-name {
      display: flex;
      align-items: center;
      gap: 6px;
      color: white;
      font-size: 14px;
    }

    &__icon-container {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__submit {
      cursor: pointer;
      font-size: 12px;
      border: none;
      background-color: var(--attack-map-popup-button);
      color: var(--attack-map-button-text);
      border-radius: 4px;
      padding: 8px;
    }

    &__key-value {
      color: white;
      font-size: 12px;
      // white-space: nowrap;

      span {
        color: var(--details-text-color);
        font-weight: bold;
      }
    }

    &__bottom {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      padding-top: 10px;
      border-top: 1px solid var(--dropped--file-lists-sidebar-border-color);
    }

    &__table {
      flex: 1;
      min-width: 0;

      @media screen and (max-width: 1570px) {
        min-width: unset;
        max-width: 100%;
      }
    }

    &__static-results {
      flex: 0 0 350px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      @media screen and (max-width: 1570px) {
        min-width: 350px;
        flex: 1;
      }

      .static-info-card-item {
        max-width: 350px;
      }

      .static-info-card-item__subtitle {
        font-size: 12px;
      }

      .static-info-card-item__item {
        font-size: 12px;
      }

      .static-info-card-item__yara {
        font-size: 12px;
        max-width: 350px;
      }

      .static-info-card-item__prev-matches {
        font-size: 12px;
        max-width: 350px;
      }
    }
  }

  &__table-button-block {
    background: var(--details-tab-block-bg);
  }

  &__tab-button {
    padding: 11px;
    border: 1px solid var(--details-tab-block-bg);
    cursor: pointer;
    background: var(--details-tab-block-bg);
    margin: 0;
    color: var(--details-text-color);
    font-family: Nunito, serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    outline: none;

    @media screen and (max-width: 1300px) {
      font-size: 13px;
      padding: 6px;
    }
  }

  &__tab-button__active {
    background: var(--details-tab-bg);
    border: 1px solid var(--details-tab-bg);
    margin: 0;
    color: var(--details-text-active-color);
  }
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-table {
  td {
    color: var(--collapse-content-text-color);
  }

  th {
    color: var(--collapse-content-text-color);
    background: var(--attack-map-bg);
  }
}
