@import "../../../../../styles/colors";

.details-block {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid var(--dropped--file-lists-sidebar-border-color);
  }

  &__row-item {
    font-size: 12px;
    line-height: 16px;
    padding: 6px;
    color: var(--details-text-active-color);
    overflow-wrap: break-word;

    @media screen and (max-width: 1300px) {
      font-size: 11px;
    }

    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 80%;
    }
  }
}
