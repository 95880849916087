@import "../../styles/colors";

.system-info {
  display: flex;
  flex-direction: column;
  margin: 20px 30px 20px 40px;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1300px) {
      margin: 20px 10px;
    }

    h3 {
      color: var(--settings-article-value) !important;
      font-size: 16px;
    }

    td {
      color: var(--settings-article-value) !important;
      font-size: 14px;
    }

    tr {
      height: 40px;
    }
  }

  .field {
    height: 40px;
    width: 148px;
    margin: 0;
    background: var(--settings-field-bg) !important;
    border: 1px solid var(--settings-field-border-color) !important;
  }

  &__active {
    display: block;
  }

  &__disabled {
    display: none;
  }

  &__email {
    width: 225px;
  }

  .label {
    margin: 0;
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);
    height: auto;

    &-header {
      background: var(--settings-sub-bg);

      h3 {
        padding: 20px 20px 15px 20px;
      }
    }
  }

  &__create {
    padding: 0 20px 20px;
    border-bottom: 1px solid var(--border);

    h3 {
      padding: 15px 0;
    }

    .label {
      font-size: 12px;
      color: var(--text-color-card);
      margin: 5px 0 !important;
    }

    &__group {
      display: flex;
      align-items: center;
      gap: 10px;

      .field {
        padding: 10px;
        height: 33.4px;
        width: 200px;
      }
    }

    .password__eye {
      top: 10px;
    }

    &__unique-id-container {
      margin-top: 16px;
    }

    &__unique-id {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: white;

      span {
        color: var(--text-color-card);
        margin-right: 8px;
      }
    }

    &__eye-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      margin-left: 8px;
      cursor: pointer;
    }

    &__unique-id-text {
      color: var(--settings-article-value) !important;
    }

    &:last-of-type {
      border-bottom: 0px;
    }
  }

  &__update {
    &-item {
      @media screen and (max-width: 1270px) {
        width: 100% !important;
        border-right: none;
        padding: 10px;
      }
    }

    h3 {
      padding: 15px 0;
    }

    .field {
      width: 100%;
      background: var(--settings-field-bg) !important;
      border: 1px solid var(--settings-field-border-color) !important;
    }

    .label {
      font-size: 12px;
      color: var(--text-color-card);
      margin: 5px 0 !important;
    }

    &-user {
      padding: 0 20px 20px;
      border-bottom: 1px solid var(--border);

      h3 {
        padding: 15px 0;
      }

      .label {
        font-size: 12px;
        color: var(--text-color-card);
        margin: 5px 0 !important;
      }

      .password__eye {
        top: 12px;
      }
    }

    &-button {
      margin-top: 20px;

      @media screen and (max-width: 1270px) {
        right: 0;
        height: 40px;
      }
    }

    &-cancel {
      position: absolute;
      margin-top: -31.7px;
      margin-left: 450px;

      @media screen and (max-width: 1270px) {
        right: 0;
        height: 40px;
      }
    }
  }

  &__user {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);

    @media screen and (max-width: 1270px) {
      padding: 0 10px 0 15px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &-name {
      color: var(--text-color-card);
      padding: 10px 0;
      width: 30%;
    }

    &-email {
      color: var(--text-color-card);
      padding: 10px 0;
      width: 30%;
    }

    &-role {
      color: var(--text-color-card);
      padding: 10px 0;
      width: 30%;
    }

    &-actions {
      color: var(--text-color-card);
      padding: 10px 0;
      width: 15%;
    }

    &-header {
      color: white;
    }

    &-list {
      width: 248px;
    }
  }
}
