.custom-table-row {
  background: var(--attack-map-bg);
  color: var(--attack-map-card-text-color);

  td {
    word-break: break-all;
  }

  &--malicious {
    border-left: 2px solid rgb(255, 49, 49);
  }

  &--suspicious {
    border-left: 2px solid var(--network-blocks-snort-url-border);
  }

  &--clean {
    border-left: 2px solid rgb(57, 255, 20);
  }

  &--DomainBlock {
    border-left: 3px solid var(--network-blocks-domain-border);
  }

  &--UrlBlock {
    border-left: 3px solid var(--network-blocks-snort-url-border);
  }

  &--SnortBlock {
    border-left: 3px solid var(--network-blocks-snort-url-border);
  }

  &--SboxBlock {
    border-left: 3px solid var(--network-blocks-sbox-border);
  }

  &--SboxSubmitLog {
    border-left: 3px solid var(--network-blocks-sbox-submit-border);
  }

  &--dynamic {
    &:hover {
      background-color: var(--analysis-reports-table-header);
      cursor: pointer;
    }
  }
}

.custom-table-row-expansion {
  background: var(--attack-map-bg);
  color: var(--attack-map-card-text-color);
}
