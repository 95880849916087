@import "../../../styles/colors";

.fileshare {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;

  @media screen and (max-width: 1280px) {
    margin: 0 30px 10px 20px;
  }

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__body {
    display: flex;
    width: 100%;
    margin-bottom: 60px;
    gap: 12px;

    &__item {
      display: flex;
    }

    &__card {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__logo {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      max-width: 300px;
      border-radius: 4px 4px 4px 4px;
      background: var(--card-background-color);

      @keyframes mymove {
        100% {
          transform: rotate3d(0, 1, 0, 360deg);
        }
      }
    }

    table {
      padding: 20px;
      text-align: center;

      thead {
        color: white;
        font-size: 14px;
        font-weight: bolder;
      }

      color: var(--dashboard-text-color);
      border-radius: 4px 4px 4px 4px;
      height: 315px;
      width: 20%;
      background: var(--card-background-color);
    }
  }
}

.bg {
  background: var(--card-background-color);
}

.fileshare__table {
  width: 86%;
  background: var(--card-background-color);
  position: relative;
  border-radius: 4px 4px 4px 4px;
  margin-top: -15px;
  margin-bottom: 80px;

  .button-block {
    left: -1px;
    top: -44px;

    @media screen and (max-width: 1300px) {
      top: -40px;
    }
  }

  p,
  td {
    color: var(--dashboard-table-color);
    font-size: 12px;
  }

  .custom-table td {
    padding: 10px 10px;
  }

  .custom-table th {
    padding: 10px 10px;
  }

  &__os {
    background: var(--dashboard-table-row-os);
    border-radius: 4px;
    padding: 5px 0;
    width: 50px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  &__groupCounter {
    background: var(--dashboard-table-row-os);
    padding: 5px 0;
    border-radius: 4px;
    width: 45px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  &__user {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;

    &--icon {
      float: left;
      margin-right: 10px;
    }
  }

  &__networkTraffic {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    color: white !important;
  }

  &__progress {
    height: 6px;
    width: 157px;
    background: var(--dashboard-table-progress);
    border-radius: 19px;
  }

  &__progress-line {
    &__success {
      height: 6px;
      border-radius: 19px;
      background: $dark--element2;
    }

    &__error {
      height: 6px;
      border-radius: 19px;
      background: var(--network-blocks-sbox-border);
    }
  }

  &__linkVm {
    text-decoration: none;
    color: var(--header-search-text-color);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }

  &__linkVmDiv {
    border: 2px black;
    border-radius: 5px;
    background-color: var(--drag-and-drop-icon-background);
    width: 10em;
    height: 2.4em;
    text-align: center;
    padding-top: 0.5em;
  }
}