@import "../../../../../../styles/colors.scss";

.comments-messages {
  &__block {
    background: var(--matrix-table-color);
    padding: 15px;
    border-radius: 0 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    h3 {
      color: white;
    }
  }

  &__left-block {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
  }

  &__right-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 585px;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--header-search-text-color);
      border-radius: 10px;
    }
  }

  &__message-reply {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__reply-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-left: 1px solid var(--analysis-reports-information-bg);
    padding-left: 20px;
    margin-left: 20px;
  }
}

.new-comments__block {
  position: relative;
  padding: 12px 20px;
  background-color: var(--analysis-reports-information-bg);
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  gap: 12px;

  // background: var(--matrix-table-color);

  &-textarea {
    display: block;
    background: var(--new-comment-bg);
    // background-color: transparent;
    color: var(--new-comment-text-color);
    outline: none;
    min-height: 70px;
    margin-top: 0;
    border: 1px solid var(--checkbox-border);
    // border: 1px solid var(--border);

    &::placeholder {
      color: var(--checkbox-border);
      font-family: Nunito, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--header-search-text-color);
      border-radius: 10px;
    }
  }
}

#new-comments__block__scroll-target {
  position: absolute;
  top: -140px;
}