@import "../../../styles/colors";

.toggle-button {
  width: 40px;
  height: 20px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  padding-left: 1px;
  padding-right: 2px;
  cursor: pointer;
  background: var(--toggle-background);

  margin: 0 10px;
  transition: all 0.4s ease-in-out;

  &__active {
    background: var(--color-blue);
    transition: all 0.4s ease-in-out;
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--analysis-reports-information-value);

    @media screen and (max-width: 1370px) {
      font-size: 12px;
    }
  }

  &-icon {
    z-index: 100;
    opacity: 100%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--header-search-text-color);
    transition: all 0.4s;
    transform: translateX(0);
  }

  &-icon__active {
    background: var(--header-search-text-color);
    transform: translateX(110%);
  }
}