@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../../../styles/colors";

.analysis-reports__body-block__screenshots {
  position: relative;
  padding: 12px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    &__article {
      font-size: 16px;
      font-weight: 700;
      color: var(--analysis-reports-information-value);
    }

    &__button {
      font-size: 14px;
      color: var(--color-blue);
      font-weight: bold;
      background: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      outline: none;
      font-family: Nunito, serif;
    }

    &__button:hover {
      color: var(--analysis-reports-information-value);
      background: none;
    }
  }

  &__image-gallery {
    overflow: hidden;
  }

  .small-bullet {
    padding: 3px;
    margin: 0 2px;
  }
}