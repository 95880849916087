@import "../../../../../styles/colors";

.matrix {
  min-height: 303px;
  background: var(--matrix-table-color);
  margin: 0 0 30px 0;
  border-radius: 0 4px 4px 4px;
  position: relative;

  &__toggle-button-block {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--text-color-card);
    padding: 15px 0;
    margin: 0 20px 20px 20px;
  }

  &__block-columns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px 20px 10px;
    padding-bottom: 15px;
  }

  &__block-columns--report {
    padding-top: 0.2cm;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
  }

  &__block-column--report {
    width: 15%;
    margin-left: 1.5%;
    text-align: center;

    &__header {
      color: var(--analysis-reports-information-value);
      background: var(--matrix-header-bg);
      padding: 10px 0;
    }
  }

  &__block-column {
    width: 200px;
    margin: 10px 5px;
    text-align: center;

    &__header {
      font-size: 12px;
      color: var(--analysis-reports-information-value);
      background: var(--matrix-header-bg);
      padding: 10px 0;
    }
  }

  &-card {
    color: var(--analysis-reports-information-value);

    p {
      margin-top: 2px;
      margin-bottom: 2px;
      background-color: var(--border);
      border-left: 4px solid var(--unexpandable-card-border-left);
    }
  }

  &-card--report {
    color: var(--analysis-reports-information-value);

    p {
      background-color: var(--border);
      border-left: 4px solid var(--unexpandable-card-border-left);
    }
  }

  &__no-data {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
  }
}
