@import "../../../../../../../styles/colors";

.restore {
  padding: 20px 0;

  &__content {
    padding: 0 20px;
  }

  .label {
    height: 40px;
    margin: 0 0 20px 0;
  }

  .field {
    height: 40px;
    max-width: 310px;
    margin: 0;
    color: var(--settings-text-table) !important;
  }

  p {
    color: var(--settings-header-text);
    font-family: Nunito, serif;
    font-size: 12px;
  }

  .toggle-button__text {
    color: var(--settings-article-value);
  }

  .toggle-button {
    margin-left: 0;
  }
}
