.mobile-submit-popup {
  padding: 20px;
  height: 669px;

  &__container {
    position: relative;
  }

  &__close-button-container {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__apply-all {
    position: absolute;
    top: -1px;
    right: 20px;

    button {
      height: 30px;
      margin-right: 20px;
      padding: 0 6px;
      font-weight: 600;
      color: var(--header-search-text-color);
      border-radius: 4px;
      border: 1px solid var(--dark-theme-element);
      background: transparent;
      cursor: pointer;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
    }

    button:hover {
      background: var(--dark-theme-element);
    }
  }

  .files-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__choosen-file {
      display: flex;
      align-items: center;

      &__file-name {
        font-size: 12px;
        color: var(--submit-popup-color-light);
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-bottom: 1px solid var(--submit-popup-br-line);
      height: 100px;
      word-wrap: break-word;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: var(--text-color-card) white;

      &__item {
        display: flex;
        align-items: center;
        gap: 20px;
        border-bottom: 1px solid var(--submit-popup-br-line);
        padding-bottom: 6px;

        &__remove {
          cursor: pointer;
          border: 1px solid var(--submit-popup-br-line);
          background-color: var(--dark-theme-element);
          border-radius: 20%;
          color: white;
          padding: 0 6px;
          font-weight: 700;
        }

        &__file-name {
          cursor: pointer;
          font-weight: bolder;
          background-color: white;
          border: 1px solid var(--dark-theme-element);
          padding: 0 6px;
          font-weight: 900;
        }
      }
    }
  }

  .vms-container {
    &__label {
      font-size: 12px;
      color: var(--submit-popup-color-light);
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--submit-popup-color-light);
    height: 377px;

    &__exp {
      color: white;
      font-size: 14px;
      margin-top: auto;
      margin-bottom: auto;
      text-align: center;
    }

    &__item {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--submit-popup-br-line);

      &__options {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      &__option {
        display: flex;
        align-items: center;
        gap: 12px;
        color: white;
        font-size: 14px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 22px;
    margin-right: 10px;
    padding-top: 12px;

    &__cancel {
      border-radius: 4px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
      color: var(--header-search-text-color);
      cursor: pointer;
      font-weight: 600;
      width: 70px;
      border: none;
      background-color: var(--layout-bg) 60;
      padding: 7.5px 0;
    }

    &__submit {
      border-radius: 4px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
      color: var(--header-search-text-color);
      cursor: pointer;
      font-weight: 600;
      width: 70px;
      background-color: var(--dark-theme-element);
      border: none;
      padding: 7.5px 0;
    }
  }
}
