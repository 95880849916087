.http {
  border-radius: 0 4px 4px 4px;

  .select-box--box {
    margin-top: 5px;
  }

  .label {
    color: var(--text-color-card) !important;
  }

  .custom-table {
    padding-bottom: 30px;

    td {
      padding: 10px 15px;
    }

    th {
      padding: 10px 15px;
      background: var(--settings-table-header);
      border: none;
    }
  }
}