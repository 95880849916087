@import "../../styles/colors";

.report {
  display: block;
  margin-bottom: 50px;
}

.report-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
}

.report-header {
  margin: 0 30px 30px 40px;
  font-size: 100%;

  h1 {
    color: var(--dashboard-value-color);
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 5px;
    margin-top: 20px;
  }

  p {
    color: var(--dashboard-text-color);
    margin: 10px 0 20px 0;
  }

  td {
    padding-right: 20px;
  }
}

.report-divider {
  height: 0px;
  border: none;
  border-top: 1px solid;
  width: 90%;
  margin-left: 2.5%;
}

.report-main {
  flex: 1;
  width: 100%;
  height: auto;
  margin-left: 2.5%;

  &__content {
    width: 90%;

    > * {
      background-color: var(--header-background);
      margin-top: 20px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid var(--border);

      h2 {
        cursor: pointer;
        color: var(--dashboard-value-color);
        padding: 10px;
        font-weight: 500;
        background-color: var(--card-background-color);
        border-radius: 4px 4px 4px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &--general {
      &-content {
        color: var(--tabs-button-color);
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        table {
          flex: 1;
          margin: 10px;
          font-size: 12.5px !important;

          @media screen and (max-width: 1870px) {
            font-size: 12px !important;
          }

          @media screen and (max-width: 1810px) {
            font-size: 11px !important;
          }

          @media screen and (max-width: 1689px) {
            font-size: 10px !important;
          }

          @media screen and (max-width: 1569px) {
            font-size: 9px !important;
          }
        }

        &_score {
          flex: 1;
          border-left: 1px solid var(--border);
          padding-left: 10px;

          span {
            padding: 5px;
            font-size: 14px;
          }

          &-verdict {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          &-chart {
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      &-content.visible {
        display: flex;
      }

      &-content.hidden {
        display: none;
      }
    }

    &--classification {
      &-content {
        color: var(--tabs-button-color);
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        table {
          flex: 1;
          margin: 10px;
          font-size: 12.5px !important;

          @media screen and (max-width: 1870px) {
            font-size: 12px !important;
          }

          @media screen and (max-width: 1810px) {
            font-size: 11px !important;
          }

          @media screen and (max-width: 1689px) {
            font-size: 10px !important;
          }

          @media screen and (max-width: 1569px) {
            font-size: 9px !important;
          }
        }

        &_score {
          flex: 1;
          border-left: 1px solid var(--border);
          padding-left: 10px;

          span {
            padding: 5px;
            height: 25px;
            font-size: 14px;
          }

          &-chart {
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      &-content.visible {
        display: flex;
      }

      &-content.hidden {
        display: none;
      }
    }

    &--signatures {
      height: 10% !important;

      &-content.visible {
        display: flex;
      }

      &-content.hidden {
        display: none;
      }
    }

    &--processes {
      height: 10% !important;
      width: 100%;
      word-wrap: break-word;

      &-content.visible {
        display: flex;
      }

      &-content.hidden {
        display: none;
      }

      label {
        width: 65%;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid var(--border);
        padding: 10px;
      }
    }

    &--network {
      height: 10% !important;
      width: 100%;
      word-wrap: break-word;

      &-content.visible {
        display: flex;
      }

      &-content.hidden {
        display: none;
      }
    }

    &--mitre {
      height: 10% !important;
      width: 100%;
      word-wrap: break-word;

      &-content.visible {
        display: flex;
      }

      &-content.hidden {
        display: none;
      }
    }
  }

  .classcategories {
    &__text {
      color: white !important;
    }
  }
}

.report-actions {
  // flex: 1;
  // background-color: var(--header-background);
  // width: 90%;
  // height: 150px;

  // margin-top: 20px;
  // // margin-right: 3%;
  // margin-left: 2.5%;
  // border-radius: 4px 4px 4px 4px;
  // border: 1px solid var(--border);

  // h2 {
  //   color: var(--dashboard-value-color);
  //   padding: 10px;
  //   font-weight: 500;
  //   background-color: var(--card-background-color);
  //   border-radius: 4px 4px 4px 4px;
  //   height: 30%;
  // }

  // &__buttons {
  //   width: 100%;
  //   height: 70%;

  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   &__container {
  //     font-size: 12px;
  //     width: 70%;
  //     margin-left: 2.5%;
  //     gap: 10px;

  //     display: flex;
  //     // justify-content: space-evenly;
  //     align-items: center;

  //     &--export {
  //       width: 70px;
  //       font-weight: 600;
  //       color: var(--header-search-text-color);
  //       border-radius: 4px;
  //       border: none;
  //       background: var(--dark-theme-element);
  //       cursor: pointer;
  //       box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  //     }

  //     &--export:hover {
  //       background-color: var(--dark-theme-element)80;
  //     }
  //   }
  // }

  &__buttons {
    &__container {
      display: flex;
      // justify-content: center;
      justify-content: end;
      align-items: center;
      gap: 10px;
      height: 70%;
      margin-left: 2.5%;
      width: 90%;
      margin-bottom: 10px;

      &--export {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 5px;

        font-weight: 600;
        color: var(--header-search-text-color);
        background: var(--dark-theme-element);
        border-radius: 4px;
        border: none;
        cursor: pointer;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);

        &--disabled {
          cursor: not-allowed;
          background-color: var(--network-blocks-sbox-button-disabled-bg);
          color: var(--card-background-color);

          &:hover {
            cursor: not-allowed;
            background-color: var(--network-blocks-sbox-button-disabled-bg) !important;
            color: var(--card-background-color);
          }
        }
      }

      &--export:hover {
        background-color: var(--dark-theme-element) 80;
      }
    }
  }
}
