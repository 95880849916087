.info-icon-container {
  float: right;
  position: relative;
}

.info-icon {
  cursor: pointer;
}

.info-popup {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 10px;
  background-color: #41414e;
  color: var(--header-search-text-color);
  width: 200px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 99;
}