@import "../../../../../../styles/colors.scss";

.chatgpt-container {
  flex: 1;
  padding: 30px 30px;
  min-height: 200px;
  background: var(--matrix-table-color);
  margin: 0 0 30px 0;
  border: 1px solid var(--border);
  border-radius: 4px 4px 4px 4px;
  color: var(--header-search-text-color);
  position: relative;
  margin-bottom: 0;

  &__button-block {
    position: absolute;
    display: flex;
    align-items: center;
    right: -14px;
    top: 6px;
  }

  &__content {
    padding-right: 10px;
    height: 417px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--header-search-text-color);
      border-radius: 10px;
    }

    &__no-data {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.spinner {
  height: 20px;
  width: 21px;
  animation: spin 1s linear infinite;
  margin: -5px 15px 0 15px !important;
}