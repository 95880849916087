@import "../../../styles/colors";

.cloudProfile {
  display: flex;
  flex-direction: column;
  margin: 0 30px 200px 40px;

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--card-background-color);
    border-radius: 4px;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
    margin: 20px 7px 20px 7px;

    .password__eye {
      top: 20px;
      right: 14px;
    }

    &__divider {
      height: 0px;
      border: none;
      border-top: 1px solid;
      width: 90%;
    }

    &__content {
      width: 90%;
    }
  }

  &__body-logo {
    height: 300px;

    &__content {
      width: 90%;
    }
  }

  .bg {
    background: var(--card-background-color);
  }
}
