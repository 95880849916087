@import "../../../../../../../styles/colors.scss";

.web__email {
  padding: 0 20px;
  border-radius: 0 4px 4px 4px;

  &__text {
    color: var(--text-color-card);
    font-size: 12px;
  }

  .label {
    width: 350px;
    color: var(--text-color-card) !important;

    &:first-child {
      margin-right: 25px;
    }
  }

  .field {
    height: 40px;
    width: 100%;
  }

  .toggle-button {
    margin: 5px 10px 5px 0;
  }

  .select-box--box {
    margin: 6px 25px 0 0;
  }

  .toggle__article {
    color: var(--text-color-card);
    font-size: 12px;
    margin-bottom: 5px;
  }
}