@import "../../../../../styles/colors.scss";

.mailing {
  margin: 20px 40px;

  h1 {
    font-size: 24px;
  }

  h3 {
    color: var(--settings-article-value) !important;
    font-size: 16px;
  }

  &__header {
    display: flex;
    gap: 20px;
  }

  &__body {
    position: relative;
    margin-top: 64px;
  }

  &__content {
    border-radius: 0 4px 4px 4px;
    background: var(--card-background-color);
    position: relative;

    &__header {
      background: var(--settings-sub-bg);
      display: flex;
      align-items: center;

      h3 {
        padding: 20px 20px 15px 20px;
      }

      p {
        border-left: 1px solid var(--settings-header-text);
        padding-left: 15px;
        flex: 1 1;
        font-size: 12px;
        color: var(--settings-header-text);
      }
    }

    &__body {
      padding: 15px;

      &__divider {
        height: 0px;
        border: none;
        border-top: 1px solid;
        width: 90%;
        margin: 40px 0;
      }
    }

    &__security {
      color: var(--header-search-text-color);
      font-size: 12px;

      &__header {
        color: var(--settings-header-text);
        display: flex;
        align-items: center;
        min-height: 20px;
      }

      &__labels {
        display: flex;
        align-items: start;
      }

      &__input {
        accent-color: var(--dark-theme-element) !important;
      }
    }

    &__recipient-container {
      max-width: 400px;
      color: var(--header-search-text-color);

      &__input-container {
        width: 100%;
        // display: flex;
        // flex-direction: column;
        margin-bottom: 10px;

        // &__input {
        //   flex-grow: 1;
        // }

        &__add-button {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--color-blue);
          border: 1px solid var(--color-blue);
          color: var(--header-search-text-color);
          font-size: 18px;
        }
      }

      &__list {
        width: 100%;
        height: 200px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--text-color-card) white;
        background: var(--settings-field-bg);

        &__item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          padding: 8px;
          margin-bottom: 4px;
          border-radius: 4px;
          border-bottom: 1px solid var(--border);
          position: relative;

          &__email {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__remove-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }

      &__list-no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--settings-field-bg);
      }

      &__list::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      &__list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      &__list::-webkit-scrollbar-thumb {
        background: var(--text-color-card);
        border-radius: 10px;
      }

      /* Handle on hover */
      &__list::-webkit-scrollbar-thumb:hover {
        background: #7c849d;
      }
    }
  }

  .toggle-button {
    margin-left: 0;
  }
}