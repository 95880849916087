.backup .custom-table .url {
  .label {
    margin: 0;
  }
  .field {
    width: 100% !important;
    padding: 10px;
    margin: 0;
    height: 40px;
  }
}
