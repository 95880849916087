@import "../../../styles/colors";

.footer {
  background: var(--header-background);
  position: relative;
  z-index: 10000;
  bottom: 0;

  display: block;
  width: 100%;
  padding: 0 4px;
  border-top: 2.4px solid var(--sidebar-background);

  .footer__inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.1rem 1rem;
    gap: 0.5rem;

    &__link {
      color: var(--color-blue);
      cursor: pointer;
      text-decoration: underline;
      text-decoration: none;
    }

    &__text {
      color: var(--dashboard-value-color);
    }

    &__pipe {
      color: var(--dashboard-value-color);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 10px;

    &__text {
      font-family: "HandelGotDLig" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: 0.2px;
      -moz-osx-font-smoothing: grayscale;
      font-size: 20px;
    }

    &__text-cyber {
      color: var(--theme-logo-cyber-text);
    }

    &__text-fortress {
      color: var(--theme-logo-fortress-text);
    }
  }

  &__brand {
    font-family: "HandelGotDLig" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
  }

  &__brand-cyber {
    color: var(--theme-logo-cyber-text);
  }

  &__brand-fortress {
    color: var(--theme-logo-fortress-text);
  }
}
