.custom-signatures {
  margin: 20px 40px;
}

.upload-signature {
  padding: 20px 0;

  &__header {
    font-size: 24px;
  }

  h3 {
    color: var(--settings-article-value) !important;
    font-size: 16px;
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);

    &__header {
      background: var(--settings-sub-bg);
      display: flex;
      align-items: center;

      h3 {
        padding: 20px 20px 15px 20px;
      }

      p {
        border-left: 1px solid var(--settings-header-text);
        padding-left: 15px;
        flex: 1 1;
        font-size: 12px;
        color: var(--settings-header-text);
      }
    }

    &__content {
      padding: 20px 20px 15px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      p {
        color: #ec374a;
        font-size: 14px;
      }

      .primary-button {
        width: 65px;

        &:disabled {
          background-color: grey;
          border-color: grey;
          color: var(--settings-sub-bg);
          cursor: not-allowed;

          &:active {
            background-color: grey !important;
          }
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }
}

.signature-list {
  padding: 20px 0;

  &__header {
    font-size: 24px;
  }

  h3 {
    color: var(--settings-article-value) !important;
    font-size: 16px;
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);

    &__header {
      background: var(--settings-sub-bg);
      display: flex;
      align-items: center;

      h3 {
        padding: 20px 20px 15px 20px;
      }

      p {
        border-left: 1px solid var(--settings-header-text);
        padding-left: 15px;
        flex: 1 1;
        font-size: 12px;
        color: var(--settings-header-text);
      }
    }

    &__content {
      padding: 20px 20px 15px 20px;

      table {
        max-width: fit-content;

        thead {
          text-align: left;
          color: var(--text-color-card);
        }

        th,
        td {
          padding: 0 18px 2px 6px;
        }

        th {
          padding-bottom: 12px;
        }

        td {
          color: white;
        }

        td.disabled {
          color: var(--network-blocks-sbox-button-disabled-bg);
        }

        td:last-child {
          display: flex;
          align-items: center;
          gap: 6px;

          button:first-child {
            width: 67px;
          }

          button:last-child {
            width: 61px;
            background-color: #ec374a;
            border-color: #ec374a;

            &:hover {
              background-color: var(--hover-item-color);
            }
          }
        }
      }
    }
  }
}
