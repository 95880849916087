.warning {
  background: var(--submit-popup-background);
  width: 410px;
  min-height: 320px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
  transition: all linear 1s;

  &__icon {
    margin-top: 40px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 16px;
    text-align: center;
    color: var(--text-color-checkbox);
    margin-bottom: 30px;
  }

  button {
    margin: 0 12px;
    width: 63px;
    height: 40px;
    font-size: 16px;
  }
}

.warning-backdrop {
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 180px;
  display: flex;
  justify-content: center;
}