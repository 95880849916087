.backup .custom-table .filename {
  .label {
    margin: 0;
  }
  .field {
    width: 100%;
    padding: 10px;
    height: 40px;
    margin: 0;
    max-width: 150px;
  }
}
