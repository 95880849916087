$main--blue: #55a0e6;
$grey--text: #a6b0cf;
$grey-text-dark: #7c849d;
$grey--text-dark: #7c849d;
$white: #ffffff;
$black: #000000;
$alert-red: #924040;
$dark--bg: #222736;
$dark--gray: #303546;
$dark--element: #313748;
$dark-text: #32373c;
$light--bg: #f4f5f7;
$light--label: #495057;
$dark--gray-text: #32373c;
$red: #ff3d00;
$green: #28c30f;
$orange: #ffb800;
$blue: blue;
$yellow: yellow;
$dark-yellow: cyan;
$purple: purple;
$pink: pink;
$dark--element2: #0e8bff;

$main--orange: #e56c4d;
$df-grey--text: #a6b0cf;
$df-alert-red: #e56c4d;
$df-dark--bg: #000000;
$df-dark--gray: #282828;
$df-dark--element: #111111;
$df-dark-text: #0d0d0d;
$df-light--label: #262626;
$df-dark--gray-text: #262626;
$df-grey--text-dark: #2e2620;

// $main--orange: #ff4a1c;
// $df-grey--text: #a6b0cf;
// $df-alert-red: #ff4a1c;
// $df-dark--bg: #2e3e45;
// $df-dark--gray: #3a4f57;
// $df-dark--element: #27353a;
// $df-dark-text: #33464e;
// $df-light--label: #3a4f57;
// $df-dark--gray-text: #3a4f57;
// $df-grey--text-dark: #7c849d;
