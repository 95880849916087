@import "../../../styles/colors";

.analysis-reports__table {
  width: 100%;
  position: relative;
  border-radius: 0 4px 4px 4px;
  margin: 6vh 0 1vh 0;

  &__information-popup {
    position: absolute;
    right: 29px;
    top: -29.5px;
  }
}

.spinner-block {
  display: flex;
  background: var(--attack-map-bg);
  height: 500px;
  margin: auto;
}

.bottom-border-tab {
  border-bottom: 1px solid var(--analysis-reports-table-header) !important;
}
