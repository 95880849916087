@import "../../styles/colors";

.dashboard {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;

  @media screen and (max-width: 1440px) {
    margin: 0 30px 10px 20px;
  }

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 25px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: stretch;
    margin-bottom: 40px;

    &__item {
      width: 50%;
      margin: 0 0 20px 0;

      @media screen and (max-width: 1405px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &:first-child {
        width: 43%;
        @media screen and (max-width: 1405px) {
          display: block;
          width: 100%;
          margin-bottom: 40px;
        }
      }

      &:nth-child(2) {
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        margin-left: auto;

        @media screen and (max-width: 1405px) {
          width: calc(100% - 96px);
          gap: 14px;
        }
      }

      &:nth-child(3) {
        width: 96px;
      }
    }

    &__services-container {
      display: flex;
      flex-direction: column;
      gap: 28px;
      margin-left: auto;
      margin-top: 5px;
      width: 10%;
    }

    &__item-card {
      display: flex;
      justify-content: space-between;
      gap: 14px;

      .dashboard__card:first-child {
        flex: 1;
        background-color: var(--content-background);

        &:hover {
          background-color: var(--hover-item-color);
        }
      }
    }
  }
}
.bg {
  background: var(--card-background-color);
}
