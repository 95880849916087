.file-card {
  margin-top: 44px;
  height: 100%;
  position: relative;

  &__content {
    background-color: var(--file-card-main-background);
    width: 100%;
    height: 380px;
    border-radius: 0 4px 4px 4px;


    &--licence {
      width: 100%;
      height: 90%;
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.9);
      border-radius: 4px;
      z-index: 200;
      color: white;
      text-align: center;
      padding-top: 25%;

      &-font {
        font-weight: bolder;
        text-decoration: none;
        color: white;
      }
    }


    &--frastlci4lw {
      pointer-events: none;
    }

    &--spudrapis8o {
      pointer-events: auto;
    }
  }

  &__information-popup {
    position: absolute;
    right: 12px;
    bottom: 75px;
  }
}