@import "../../../styles/colors";

.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background: var(--layout-bg);

  @media screen and (max-width: 1200px) {
    min-width: 1200px;
  }
}

.main-block {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  @media screen and (max-width: 1300px) {
    margin-top: 0;
  }
}

.header {
  height: 56px;
  background: var(--header-background);
  position: fixed;
  z-index: 10000;
  top: 0;

  @media screen and (max-width: 1300px) {
    position: initial;
    width: 100% !important;
  }
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--content-background);
}

#scroll-target {
  position: absolute;
  top: -100px;
}
