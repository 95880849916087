@import "../../../styles/colors";

.card {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--background-color);

  &__body {
    text-align: center;
  }

  &__body__icon {
    margin: 30px 0;
  }

  &__error {
    background: #fde1e1;
    color: $alert-red;
    font-size: 12px;
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
  }

  &__form {
    padding: 30px 30px 20px 30px;
    margin: auto;
    width: 410px;
    min-height: 270px;
    border-radius: 8px;
    background: var(--card-background-color);
    color: var(--text-color-card);
  }

  &__form-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid var(--text-color-card);
    padding-bottom: 15px;
  }

  &__form-btn {
    background: var(--color-blue);
    border-radius: 4px;
    color: var(--header-search-text-color);
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0;
    height: 48px;
    width: 350px;
    outline: none;
    border: 1px solid var(--color-blue);
    cursor: pointer;
    font-family: Nunito, serif;
  }

  &__form-header__text {
    width: 70%;
  }

  &__form-header__logo {
    width: 30%;
    text-align: right;
  }

  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
    color: var(--text-color-card-article);
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    margin-top: 5px;
  }

  a {
    color: var(--color-blue);
    text-decoration: none;
  }

  &__toggle-button {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .password__eye {
    margin-top: 2px;
  }
}