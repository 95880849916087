.search {
  padding: 0 20px;

  .toggle-button {
    margin-left: 0;
  }

  .field {
    width: 100%;
    height: 40px;
    padding-right: 0;
  }

  .label {
    max-width: 400px;
    color: var(--text-color-card) !important;
  }
}