.content-container {
  padding-left: 1px;

  &__header {
    color: var(--text-color-card);
    margin-bottom: 10px;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;
  }

  &__text {
    color: white;
    margin-bottom: 24px;
  }

  &__list-item {
    color: white;

    &::before {
      display: inline-block;
      background-color: var(--text-color-card);
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
}

.integration-modal {
  &__input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: var(--text-color-label);
  }

  &__input {
    width: 220px;
    height: 40px;
    background-color: var(--submit-popup-background);
    border: 1px solid var(--border);
    color: var(--file-card-input-color);
    font-size: 14px;
    padding: 12px;
  }
}