.backdrop-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(black, 0.7);
  cursor: pointer;
  z-index: 99998;
}
