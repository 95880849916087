@import "../../../../../../../styles/colors";

.smtp {
  border-radius: 0 4px 4px 4px;

  .field {
    height: 40px;
    width: 100%;
  }

  .label {
    width: 312px;
  }

  .field::placeholder {
    font-size: 14px;
  }

  &__label {
    font-size: 12px;
    color: var(--text-color-card);
    font-family: Nunito, serif;
  }

  &__host {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 558px;
  }

  &__address {
    color: var(--settings-article-value);
  }

  .label {
    color: var(--text-color-card) !important;
  }

  .custom-table {
    padding-bottom: 30px;

    td {
      padding: 10px 15px;
    }

    th {
      padding: 10px 15px;
      background: var(--settings-table-header);
      border: none;
    }
  }
}