@import "../../../../../styles/colors";

.snort-suricata {
  background: var(--attack-map-bg);
  height: 100%;
  border: 1px solid var(--border);
  border-radius: 0 4px 4px 4px;
  position: relative;

  &__no-data {
    &__header {
      border-bottom: 1px solid var(--text-color-card);
      margin: 0 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__item {
      color: var(--map-text-color);
      font-size: 12px;
      line-height: 16px;
      padding: 12px 5px;

      &:nth-child(1) {
        width: 19%;
      }

      &:nth-child(2) {
        width: 19%;
      }

      &:nth-child(3) {
        width: 6%;
      }

      &:nth-child(4) {
        width: 6%;
      }

      &:nth-child(5) {
        width: 47%;
      }
    }
  }

  .custom-table__footer-pagination {
    bottom: 0px;
    margin-left: 0;
    width: 100%;
  }

  &__table-block {
    flex: 1;

    &-signature {
      width: 47%;
    }

    &-ip {
      width: 19%;
    }

    &-protocol {
      width: 6%;
    }
  }

  .custom-table th,
  td {
    @media screen and (max-width: 1600px) {
      font-size: 11px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 12px;
    }
  }
}