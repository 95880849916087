@import "../../../styles/colors";

.simplebar-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  border-radius: 7px;
  @media screen and (min-width: 1400px) {
    width: 9px;
  }

  &::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 7px;
    border-radius: 4px;
    background-color: var(--scroll-bar-color);
    -webkit-box-shadow: 0 0 1px var(--scroll-bar-color);
    @media screen and (min-width: 1400px) {
      width: 9px;
    }
  }

  &.simplebar-visible {
    &::before {
      opacity: 1;
    }
  }
}

.simplebar-track {
  &.simplebar-vertical {
    width: 10px;
    @media screen and (min-width: 1400px) {
      width: 12px;
    }
  }
}
