@import "../../styles/colors";

.analysis-reports {
  display: flex;
  flex-direction: column;
  padding: 18px 30px 20px 40px;

  @media screen and (max-width: 1200px) {
    padding: 18px 10px 20px 20px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-doughnutChart {
      width: 70px;
      height: 70px;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
      margin-right: auto;
    }

    &-verdict {
      padding: 0 10px;
      border-radius: 13px;
      color: black;
      font-size: 12px;
      vertical-align: middle;
      font-weight: 400;
      text-transform: capitalize;

      &--malicious {
        background: #ec374a;
      }

      &--suspicious {
        background: #e69b55;
      }

      &--not-suspicious {
        background: #58e655;
      }

      &--apt {
        background: #ec374a;
        position: relative;
        overflow: hidden;
        width: 90px;
        padding: 0;

        span {
          position: relative;
          color: black;
          z-index: 9999;
          background-color: #ec374a;
          border-radius: 3px;
          padding: 1px 2px;
          display: inline-block;
          line-height: 1;
          width: 100%;
          text-align: center;
        }

        &::after {
          content: "APT";
          position: absolute;
          color: white;
          font-size: 11.5px;
          font-weight: 500;
          right: 4px;
          bottom: -2px;
          line-height: 1;
          z-index: 9999;
        }
      }
    }

    &-explanation {
      color: var(--analysis-reports-color);
      font-size: 16px;
    }

    h1 {
      font-family: Nunito, serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      color: var(--dashboard-value-color);
      width: 100%;
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  &__body-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 4px;
    margin-bottom: 80px;

    &__video-player {
      flex: 2;
      display: flex;
      justify-content: center;
    }

    &__strings-container {
      max-height: 161px;
      overflow: auto;
      margin-top: -5px;

      &::-webkit-scrollbar {
        margin-left: 10px;
        width: 8px;
        border-radius: 40px;
      }

      &::-webkit-scrollbar-track {
        background: var(--border);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba($color: #000000, $alpha: 0.5);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--header-search-text-color);
        border-radius: 10px;
      }

      p {
        color: white;
        margin-left: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__string {
      color: white;
    }
  }

  &__body-block__information {
    position: relative;
    background: var(--analysis-reports-information-bg);
    max-width: 745px;
    min-width: 360px;
    flex: 1.25 1;
    padding: 12px;
    border-radius: 4px;

    &__view-related {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &__button {
      font-size: 14px;
      color: var(--color-blue);
      font-weight: bold;
      background: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      outline: none;
      font-family: Nunito, serif;

      &:hover {
        color: var(--analysis-reports-information-value);
        background: none;
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--card-background-color);
      }
    }

    h3 {
      margin-bottom: 12px;
    }

    &-value {
      color: var(--analysis-reports-information-value);
    }

    &-exe {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: fit-content;
      border-radius: 4px;
      padding: 4px;
      background: var(--analysis-reports-information-exe);
      font-size: 12px;
      text-align: center;
      word-break: break-word;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--analysis-reports-information-exe-text);
      }

      svg {
        min-width: 20px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--analysis-reports-color);
      margin: 8px 0;
      font-size: 11px;
      overflow-wrap: break-word;
    }

    &--report {
      max-width: 50%;
      min-width: 50%;

      p {
        margin: 8px 0;
      }
    }
  }

  &__body-block__classification {
    position: relative;
    // background: var(--analysis-reports-classification-bg);
    max-width: 470px;
    min-width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    border-radius: 4px;

    &__information-popup {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    h3.article {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    &__no-data {
      color: var(--analysis-reports-color);
    }

    &--report {
      max-width: 50%;
      min-width: 50%;
      background: none;
      border: none !important;
      margin: 0 auto;
    }

    &__iconSet {
      &--report {
        min-width: auto;
        width: 220px;
        margin-left: 80px;
        margin-top: 75px;
      }

      &__trojan {
        position: absolute;
        top: -3%;
        left: 43.5%;

        &--report {
          left: 45.5%;
        }
      }

      &__stealth {
        position: absolute;
        top: 0%;
        right: 29%;
      }

      &__network {
        position: absolute;
        top: 8.5%;
        right: 15%;
      }

      &__execution {
        position: absolute;
        top: 20.5%;
        right: 4%;
      }

      &__infostealer {
        position: absolute;
        top: 34.5%;
        right: 0%;
      }

      &__ransomware {
        position: absolute;
        top: 51.5%;
        right: -1%;
      }

      &__generic {
        position: absolute;
        top: 65.5%;
        right: 4%;
      }

      &__other {
        position: absolute;
        top: 78.5%;
        right: 14%;
      }

      &__exploit {
        position: absolute;
        top: 85%;
        right: 28.5%;

        &--report {
          top: 89%;
          right: 29.5%;
        }
      }

      &__office {
        position: absolute;
        bottom: -4%;
        right: 43.5%;

        &--report {
          right: 46.5%;
        }
      }

      &__banker {
        position: absolute;
        bottom: -1.5%;
        left: 27%;
      }

      &__encrypt {
        position: absolute;
        bottom: 7.5%;
        left: 12%;
      }

      &__spreading {
        position: absolute;
        bottom: 20.5%;
        left: 3%;
      }

      &__injection {
        position: absolute;
        bottom: 34%;
        left: -2%;
      }

      &__discovery {
        position: absolute;
        bottom: 49%;
        left: -1.5%;
      }

      &__bot {
        position: absolute;
        top: 22%;
        left: 3.5%;
      }

      &__static {
        position: absolute;
        top: 8%;
        left: 12.5%;
      }

      &__credentials {
        position: absolute;
        top: -1%;
        left: 27.5%;
      }
    }

    &__tags {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__malwarenames {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      &__separator {
        margin: 0 6px;
        height: 1px;
        background-color: var(--border);
      }
    }

    &__border {
      border: 2px solid var(--border);
      border-radius: 10px;
      padding: 0 8px;

      &--malicious {
        border-color: #ec374a;
      }
      &--suspicious {
        border-color: #e69b55;
      }
    }

    &__text {
      font-size: 11px !important;
      color: white;
      font-weight: bold;
    }
  }

  &__body-block__screenshots {
    width: 415px;
    min-height: 267.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--analysis-reports-classification-bg);
    border-radius: 4px;
    margin: 0 6px;

    .overflow-auto {
      height: 75%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px;
    }

    &-img {
      width: 100%;
      margin: 20px 0;
      max-height: 340px;
      cursor: ne-resize;
    }
  }
}

.block-network-map {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 80px !important;
}

.block-memory {
  position: relative;
}

.pre-report {
  background-color: var(--layout-bg);
  color: var(--header-search-text-color);
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 40px;
  }
}
