@import "../../../../../../../styles/colors.scss";

.main {
  padding: 0 20px;

  .field {
    width: 100%;
    height: 40px;
    padding-right: 0;
  }

  .field::placeholder {
    font-size: 13px;
  }

  .label {
    margin-right: 25px;
    max-width: 200px;

    &:first-child {
      max-width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__text {
    color: var(--settings-header-text);
    font-size: 12px;
  }

  h5 {
    margin: 10px 0;
  }

  .toggle-button {
    margin-left: 0;
  }
}
