.urls {
  padding: 20px 40px;

  &__header {
    margin: 0 6px;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 15px;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        outline: none;
        border-radius: 4px;
        color: white;
        background-color: var(--dashboard-table-tabs);
        padding: 8px;
        padding-right: 5px;
        border: 1px solid var(--border);
        font-family: Nunito, sans-serif;
        font-size: 13px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 36px;
        width: 150px;

        &:disabled {
          cursor: not-allowed;
          background-color: var(--network-blocks-sbox-button-disabled-bg);
          color: var(--card-background-color);
        }
      }
    }

    p {
      color: var(--dashboard-text-color);
    }
  }

  &__table {
    position: relative;
    margin-top: 50px;

    &__file-name {
      width: 120px;

      span {
        display: block;
        width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__file-hash {
      width: 460px;

      span {
        display: block;
        width: 460px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__report-date {
      width: 145px;
    }

    &__server-ip {
      width: 145px;
    }

    &__url {
      position: relative;

      &::before {
        content: " ";
        visibility: hidden;
      }

      span {
        color: var(--collapse-content-text-color);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        right: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}