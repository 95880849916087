.header__menu {
  position: absolute;
  bottom: -120px;
  right: -15px;
  background: var(--header-background);
  border: 1px solid var(--border);
  width: 220px;
  border-radius: 4px;
  padding: 10px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  z-index: 99999;

  &__first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 12px;
    }
  }

  p {
    font-weight: 900;
    font-size: 14px;
    color: var(--menu-color);
  }

  &-button {
    display: flex;
    background: transparent;
    color: var(--text-color-card);
    outline: none;
    border: none;
    cursor: pointer;
    font-family: Nunito, serif;
    font-size: 14px;
    padding: 8px 0;
    text-decoration: none;

    span {
      margin-left: 10px;
    }
  }
}
