.android-plugins {
  position: relative;
  margin-bottom: 130px !important;
  background: var(--attack-map-bg);

  &__highlighted-cell {
    // background-color: var(--dark-theme-element);
    // padding: 4px;
    // border-radius: 5px;
    // text-align: center;
    color: yellow !important;
  }
}
