@import "../colors";

html[data-theme="light"] {
  --theme-logo-cyber-text: #{#000000};
  --theme-logo-fortress-text: #{rgb(60, 130, 180)};

  --layout-bg: #{$light--bg};
  --border: #{#ccd5f1};
  --sidebar-border-color-right: #{#ccd5f1};
  --background-color: #{$light--bg};
  --card-background-color: #{$white};
  --text-color-card: #{$grey-text-dark};
  --text-color-card-article: #{$dark-text};
  --text-color-label: #{$light--label};
  --text-color-checkbox: #{$dark--gray-text};
  --color-blue: #{$main--blue};
  --header-background: #{$white};
  --header-search-border-color: #{#a6b0cf};
  --header-search-text-color: #{$black};
  --header-search-placeholder-color: #{#a6b0cf};
  --sidebar-background: #{$white};
  --sidebar-border-color: #{#ccd5f1};
  --sidebar-active-item-color: #{#32373c};
  --sidebar-active-item-background: #{rgba(156, 180, 228, 0.1)};
  --sidebar-active-icon: #{$grey--text};
  --sidebar-item-secondary: #{rgb(108, 160, 190)};
  --content-background: #{$light--bg};
  --menu-color: #{$grey-text-dark};

  --tabs-button-color: #{$grey-text-dark};
  --tabs-button-number-bg: #{#f4f5f7};
  --tabs-button-number-text: #{$grey-text-dark};

  --checkbox-border: #{$grey-text-dark};
  --checkbox-background: #{$white};

  --file-card-tab-active-background: #{$white};
  --file-card-tab-non-active-background: #{#f7f9fd};
  --file-card-tab-text-active: #{$dark--gray-text};
  --file-card-tab-text-non-active: #{$grey-text-dark};
  --file-card-main-background: #{$white};
  --file-card-icons-color: #{$grey-text-dark};
  --file-card-icons-color-hover: #{#32373c};
  --file-card-input-background-color: #{#f7f9fd};
  --file-card-input-background: #{#f7f9fd};
  --file-card-input-color: #{#32373c};

  --drag-and-drop-text-color: #{#32373c};
  --drag-and-drop-icon-background: #{#e4e9f3};

  --dashboard-value-color: #{#32373c};
  --dashboard-card-color: #{$white};
  --textarea-backgroun-color: #{#f7f9fd};
  --textarea-border-color: #{$grey-text-dark};
  --dashboard-table-tabs: #{#f7f9fd};
  --dashboard-table-border: #{#f4f5f7};
  --dashboard-table-header: #{#f7f9fd};
  --dashboard-table-row-border: #{#ccd5f1};
  --dashboard-table-row-file-name: #{$black};
  --dashboard-table-row-hash-icon-hover: #{#a6b0cf};
  --dashboard-table-row-count: #{#eaf1fe};
  --dashboard-table-row-count-text-color: #{$black};
  --dashboard-table-row-os: #{#eaf1fe};
  --dashboard-table-row-os-text-color: #{$black};
  --dashboard-table-progress: #{#e3e8f2};
  --dashboard-table-resubmit-icon: #{$black};
  --dashboard-table-resubmit-icon-hover: #{#a6b0cf};
  --dashboard-table-color: #{$grey-text-dark};
  --dashboard-text-color: #{$grey-text-dark};

  --analysis-reports-information-value: #{#000};
  --analysis-reports-information-bg: #{$white};
  --analysis-reports-classification-bg: #{#f7f9fd};
  --analysis-reports-information-exe: #{#e4e9f3};
  --analysis-reports-information-exe-text: #{#646c85};
  --analysis-reports-table-classification-bg: #{#e4e9f3};
  --analysis-reports-table-classification-color: #{#646c85};
  --analysis-reports-table-tabs: #{#f7f9fd};
  --analysis-reports-table-border: #{#f4f5f7};
  --analysis-reports-table-header: #{#f7f9fd};
  --analysis-reports-table-footer: #{$white};

  --toggle-button-bg: #{$main--blue};
  --matrix-table-color: #{$white};
  --matrix-header-bg: #{#dee7f9};
  --matrix-card-color: #{#32373c};
  --matrix-card-bg: #{#f7f9fd};

  --new-comment-bg: #{$white};
  --new-comment-text-color: #{$dark--bg};
  --comments-message-color: #{#32373c};

  --chart-value-color: #{#32373c};

  --map-region-color: #{#4047593f};
  --map-button-color: #{#32373c};
  --map-text-color: #{#32373c};

  --submit-popup-background: #{$white};
  --submit-popup-text: #{#32373c};
  --submit-popup-color-light: #{#495057};
  --submit-popup-color-bleached: #{#a6b0cf};
  --submit-popup-color-bold: #{#32373c};
  --submit-popup-color-subscription: #{#a6b0cf};
  --submit-popup-br-line: #{#a6b0cf};
  --submit-popup-buttons-background: #{#f8f8fb};
  --submit-popup-button-cancel: #{#a6b0cf};

  --collapse-menu-header: #{#ebf0fa};
  --collapse-menu-text: #{#32373c};
  --collapse-content-background: #{$white};
  --collapse-content-text-color: #{#32373c};
  --api-call-text-color: #{#32373c};

  --dropped-file-lists-sidebar-card: #{#f7f9fd};
  --dropped-file-lists-sidebar-color: #{#32373c};
  --dropped--file-lists-sidebar-border-color: #{$grey-text-dark};
  --dropped--file-lists-table-row-border: #{$grey-text-dark};
  --dropped--file-pagination-color: #{#32373c};
  --dropped--file-pagination-arrow-active-color: #{#32373c};
  --dropped--file-pagination-arrow-color: #{$grey-text-dark};
  --dropped--file--hover--table: #{rgba(189, 199, 219, 0.6)};

  --table-yet-color: #{#32373c};
  --search-input-bg: #{#f7f9fd};
  --search-input-text-color: #{#32373c};

  --attack-map-popup-background: #{#55a0e6};
  --attack-map-popup-file-background: #{#3e7db8};
  --attack-map-popup-title-text: #{$white};
  --attack-map-popup-button: #{$white};
  --attack-map-title-text-color: #{#3e7db8};
  --attack-map-button-text: #{#55a0e6};
  --attack-map-bg: #{$white};
  --attack-map-card-default-bg: #{#e9edf5};
  --attack-map-card-text-color: #{#32373c};
  --attack-map-card-line-color: #{$grey-text-dark};
  --attack-map-card-virus-line-color: #{#ea7373};
  --attack-map-card-bg-hover: #{#bdc7db};

  --details-tab-bg: #{$white};
  --details-tab-block-bg: #{#f7f9fd};
  --details-text-color: #{$grey-text-dark};
  --details-text-active-color: #{#32373c};

  --analysis-report-modal-background: #{#f7f9fd};
  --analysis-report-modal-close-button: #{#32373c};
  --analysis-report-modal-file-background: #{#e4e9f3};
  --analysis-report-modal-file-text: #{#646c85};
  --analysis-report-modal-title-color: #{#a6b0cf};
  --analysis-report-modal-text-data: #{#32373c};
  --analysis-report-modal-content-background: #{$white};
  --analysis-reports-color: #{$grey-text-dark};

  --av-yara-content-background: #{$white};
  --files-info-text-color: #{#32373c};
  --files-info-all-text-color: #{#32373c};

  --scroll-bar-color: #{rgba(0, 0, 0, 0.5)};
  --sort-color: #{$grey-text-dark};
  --sort-color-asc: #{#282d3d};
  --sort-color-desc: #{#282d3d};

  --settings-background-page: #{$white};
  --settings-sidebar-bg: #{#fbfbfb};
  --settings-article-value: #{#32373c};
  --settings-table-header: #{#ebf0fa};
  --settings-button-bg: #{$grey-text-dark};
  --settings-field-bg: #{$white};
  --settings-field-border-color: #{$grey-text-dark};
  --settings-description: #{$grey-text-dark};
  --settings-sidebar-icon: #{$grey--text};
  --settings-sidebar-border: #{#ccd5f1};
  --settings-sub-bg: #{#ebf0fa};
  --settings-text-table: #{#32373c};
  --settings-header-text: #{#7c849d};

  --toggle-background: #{$grey--text};
  --tooltip-background: #{$grey--text};
  --tooltip-modal-background: #{$white};

  --tags-fields-bg: #{$white};
  --tags-fields-border: #{$grey-text-dark};
  --tags-fields-color: #{#000};
  --tags-fields-placeholder: #{#7c849d};
  --tags-fields-bg-tags: #{$grey--text};
  --tags-filed-label: #{#7c849d};
  --tags-tag-color: #{$white};

  --runningan-vm-connection: #{#ebf0fa};

  --profile-name-text-color: #{$dark--bg};
  --profile-text-color: #{#32373c};

  --network-blocks-detail-header: #{#a6b0cf};
  --network-blocks-detail-text: #{#32373c};
  --network-blocks-domain-border: #{$yellow};
  --network-blocks-snort-url-border: #{$orange};
  --network-blocks-sbox-border: #{$red};
  --network-blocks-sbox-submit-border: #{$main--blue};
  --network-blocks-sbox-button-bg: #{$main--blue};
  --network-blocks-sbox-button-text: #{$white};
  --network-blocks-sbox-button-disabled-bg: #{#e4e9f3};
  --network-blocks-sbox-button-disabled-text: #{#bbc2d6};
}
