@import "../../../styles/colors";

.user__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-inner {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 15px 0;
  }

  &-inner__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    margin-top: -75px;
  }

  &-inner__text-name {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    color: var(--profile-name-text-color);
    margin: 20px 0 0 0;
  }

  &-inner__text-mail {
    font-size: 18px;
    font-style: normal;
    line-height: 22px;
    text-align: center;
    color: var(--profile-text-color);
    margin: 10px 0 0 0;
  }
}
