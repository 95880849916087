@import "../../../../styles/colors";

.classcategories {
  &__display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
  }
  &__border {
    border-style: solid;
    border-width: 3px 3px;
    border-radius: 15px;
    white-space: nowrap;
    padding: 5px;
    margin: 5px;

    &--malicious {
      border-color: #ec374a;
      text-transform: capitalize;
    }
    &--suspicious {
      border-color: #e69b55;
    }
    &--nothing {
      border-color: gray;
    }
    &--pointer {
      cursor: pointer;
    }
  }
  &__text {
    font-size: 14px !important;
    color: black;
    font-weight: bold;
    border: 1px solid transparent;
    font-family: Nunito, serif;
  }
  &__no-data {
    color: var(--analysis-reports-color);
  }
}
