.border {
  border: 1px solid var(--border);
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);

  &-top {
    border-top: 1px solid var(--border);
  }

  &-right {
    border-right: 1px solid var(--border);
  }

  &-bottom {
    border-bottom: 1px solid var(--border);
  }

  &-left {
    border-left: 1px solid var(--border);
  }
}
