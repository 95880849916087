.guest-table {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  font-family: Nunito, sans-serif;
  width: 100%;
  margin: 0 auto;

  .thead {
    .th {
      display: flex;
      align-items: center;
      width: 20%;
    }

    .th-sort {
      display: flex;
      align-items: center;
    }
  }

  .tr {
    display: flex;
    align-items: center;
    background: var(--analysis-reports-table-header) !important;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid var(--border);
    padding: 2px 0 2px 20px;
    color: var(--collapse-content-text-color);
  }

  .tbody {
    .tr {
      background: var(--attack-map-bg) !important;
      color: var(--attack-map-card-text-color);

      &:last-child {
        border: none;
      }
    }

    .tr:hover {
      background-color: var(--analysis-reports-table-header);
    }

    .td {
      width: 20%;
      color: var(--collapse-content-text-color);
    }
  }

  .th,
  .td {
    display: flex;
    padding: 8px;
    font-size: 12px;
  }
}
