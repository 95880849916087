.backdrop {
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_active {
  opacity: 1;
  pointer-events: all;
}

.submit-popup {
  overflow: visible;
  padding: 0 5px;
  max-width: 984px;
  width: 100%;
  max-height: 90vh;
  background-color: var(--submit-popup-background);
}

.modal__content {
  padding: 20px;
  border-radius: 12px;
  background-color: white; // // NEED TO CHANGE
  min-height: 400px;
  min-width: 400px;
}
