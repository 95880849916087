.analyze-url {
  &__input {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 240px;
    max-height: 240px;
    background-color: var(--file-card-input-background-color);
    border: 1px dashed var(--border);
    color: var(--file-card-input-color);
    padding: 10px;
    margin-top: 0px;

    &:focus {
      outline: none;
    }
  }

  &__button-block {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}