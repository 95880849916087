.static-info-card-item {
  width: 100%;
  color: var(--analysis-reports-information-value);

  &__title {
    font-size: 12px;
    color: var(--analysis-reports-information-value);
    background: var(--matrix-header-bg);
    padding: 10px 0;
  }

  &__item,
  &__subtitle {
    margin: 2px 0;
    padding: 2px 6px;
    background-color: var(--border);
    border-left: 4px solid var(--unexpandable-card-border-left);
    overflow-wrap: break-word;
    text-align: left;
  }

  &__subtitle {
    position: relative;
    border-color: var(--expandable-card-border-left);
    padding-right: 32px;
  }

  &__collapse {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 14px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--matrix-header-bg);
    background-color: var(--matrix-header-bg);
    cursor: pointer;
  }

  &__subitem,
  &__yara,
  &__prev-matches,
  &__virustotal {
    position: relative;
    margin: 2px 0;
    padding: 2px 20px;
    background-color: var(--border);
    overflow-wrap: break-word;
    text-align: left;
  }

  &__yara,
  &__prev-matches,
  &__virustotal {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 4px 20px;

    &__header {
      color: var(--text-color-card);
      font-weight: bold;
    }

    &__matches {
      display: flex;
      flex-direction: column;
      gap: 2px;

      p {
        padding: 2px 4px;
      }
    }

    &__href {
      color: var(--text-color-card);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__no-data {
    margin: 2px 0;
    padding: 2px 6px;
    background-color: var(--border);
    // border-left: 4px solid var(--unexpandable-card-border-left);
    overflow-wrap: break-word;
    text-align: center;
  }
}
