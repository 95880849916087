.services-container {
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      font-size: 24px;
      color: white;
    }

    p {
      color: var(--dashboard-text-color);
    }
  }
}

.services {
  width: 100%;
  display: flex;
}

.services-sidebar {
  width: 17%;
  min-height: 100vh;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-right: 1px solid var(--dropped--file-lists-sidebar-border-color);
}

.services-no-select {
  width: 82%;
  display: flex;
  align-items: center;
  height: 37.5px;
  margin-left: 12px;
  color: white;
}

.services-body {
  width: 82%;
  position: relative;
  align-self: flex-start;
  margin-left: 12px;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: var(--settings-background-page);
}

.services-body-header {
  display: flex;
  align-items: center;
  background-color: var(--settings-sub-bg);
  padding: 12px 12px 10px 12px;

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 15px;

    h3 {
      color: white;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__spinner {
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(360deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }
  }

  &__auto-update {
    display: flex;
    align-items: center;
    color: var(--settings-header-text);
    border-left: 1px solid var(--settings-header-text);
    padding-left: 15px;
    padding-right: 5px;
  }

  &__description {
    border-left: 1px solid var(--settings-header-text);
    padding-left: 15px;
    flex: 1 1;
    font-size: 12px;
    color: var(--settings-header-text);
  }
}

.services-body-content {
  min-height: 200px;
  position: relative;

  table {
    max-width: fit-content;
    padding: 24px;

    thead {
      // color: white;
      color: var(--dashboard-text-color);
      text-align: left;
    }

    tbody {
      // color: var(--dashboard-text-color);
      color: white;
    }

    th,
    td {
      padding: 0 18px 2px 6px;

      button {
        cursor: pointer;
        font-size: 10.5px !important;
        border: none;
        background-color: var(--attack-map-popup-button);
        color: var(--attack-map-button-text);
        border-radius: 4px;
        padding: 8px;
        margin: 0 !important;
        width: 55px !important;
        height: unset !important;

        &:disabled {
          cursor: not-allowed;
          color: var(--network-blocks-sbox-button-disabled-text);
          background-color: var(--network-blocks-sbox-button-disabled-bg);
        }
      }
    }
  }

  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__no-data {
    color: white;
  }
}
