@import "../../../../../styles/colors";

.hosts-and-domains {
  height: 100%;
  position: relative;
  border: 1px solid var(--border);
  border-radius: 0 4px 4px 4px;
  background-color: var(--attack-map-bg);

  .custom-table__footer-pagination {
    bottom: 0px;
    margin-left: 0;
    width: 100%;
  }

  &__no-data {
    &__header {
      border-bottom: 1px solid var(--text-color-card);
      margin: 0 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__item {
      color: var(--map-text-color);
      font-size: 12px;
      line-height: 16px;
      padding: 12px 5px;

      &:nth-child(1) {
        width: 22%;
      }

      &:nth-child(2) {
        width: 58%;
      }

      &:nth-child(3) {
        width: 20%;
      }
    }
  }

  &__table {
    flex: 1;

    &__server-ip {
      width: 22%;
    }

    &__domain {
      width: 58%;
    }

    &__country-name {
      width: 20%;
    }
  }

  .custom-table th,
  td {
    @media screen and (max-width: 1600px) {
      font-size: 11px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 12px;
    }
  }
}
