.tag-fields {
  width: 286px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  background: var(--tags-fields-bg);
  border: 1px solid var(--tags-fields-border);
  color: var(--tags-fields-color);
  font-family: Nunito, serif;
  margin-bottom: 15px;

  &__label {
    color: var(--tags-filed-label);
    font-size: 12px;
    display: flex;
    align-items: center;
    min-height: 20px;
  }

  &__tag {
    background: var(--tags-fields-bg-tags);
    color: var(--tags-tag-color);
    padding: 5px;
    margin: 10px 10px 10px 0;
    border-radius: 4px;
  }

  &__button {
    background: transparent;
    outline: none;
    border: none;
    color: var(--tags-fields-color);
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
  }

  &__suggestions {
    color: var(--tags-fields-color);
    cursor: pointer;
    margin: 0;
  }
}

.tag-fields::placeholder {
  color: var(--tags-fields-placeholder);
}
