@import "../../../styles/colors";

.search-wrapper {
  display: inline-block;
  align-items: center;

  .search-input {
    position: relative;
    right: 3rem;
    margin-top: 30px;
    transform: translateY(-50%);
    height: 2.3rem;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease;
    overflow: hidden;
    border: 1px solid var(--header-search-border-color);

    input {
      width: 100%;
      height: 100%;
      border: none;
      color: var(--header-search-text-color);
      text-align: center;
      padding: 0 1.2rem;
      padding-top: 2px;
      background-color: var(--header-background);
      font-family: Nunito, sans-serif;

      &::placeholder {
        color: var(--header-search-placeholder-color);
        opacity: 1; /* Firefox */
      }

      &::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: var(--header-search-placeholder-color);
      }

      &:focus {
        outline: none;
      }
    }

    &__loading {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.active .search-icon {
    background-color: var(--header-background);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);

    &:hover {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
      background-color: #f4f4f4;

      svg {
        fill: #007eff;
      }
    }
  }

  &.active .search-input {
    width: 20rem;
    right: 0;
    border-radius: 0px;
  }

  &.active .search-input input {
    display: block;
  }

  &.active .search-input::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-top: 2rem solid transparent;

    border-bottom: 2rem solid transparent;
  }

  &.active .search-input::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;

    border-radius: 5px;
  }
  ul {
    position: absolute;
    color: var(--header-search-text-color);
    background-color: var(--matrix-table-color);
    padding: 10px;
    border: 1px solid var(--border);
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    width: 20rem;
    list-style: none;

    li {
      list-style: none;
      margin-bottom: 5px;
      padding: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #80889f;
      }
    }
  }
}
.uilogosfix {
  margin-left: 180px;
}
