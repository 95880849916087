@import "../../../../../styles/colors.scss";

.publicapi {
  margin: 20px 40px;

  h3 {
    color: var(--settings-article-value) !important;
    font-size: 16px;
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);

    &__header {
      background: var(--settings-sub-bg);
      display: flex;
      align-items: center;

      h3 {
        padding: 20px 20px 15px 20px;
      }

      p {
        border-left: 1px solid var(--settings-header-text);
        padding-left: 15px;
        flex: 1 1;
        font-size: 12px;
        color: var(--settings-header-text);
      }
    }

    &__content {
      padding: 20px 20px 15px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      &__row {
        display: flex;
        gap: 10px;

        &__apikey {
          color: var(--header-search-text-color);
          font-size: 12px;

          &__header {
            color: var(--settings-header-text);
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            min-height: 20px;
          }

          &__labels {
            color: var(--header-search-text-color);
            font-size: 12px;
            display: flex;
            align-items: start;
          }

          &__input {
            accent-color: var(--dark-theme-element) !important;
          }
        }

        &__range-container {
          color: var(--submit-popup-text);
          flex-direction: row;
          align-items: center;
          margin-top: 1px;
          position: relative;
          height: 35px;
          width: 200px;
          border: 1px solid var(--border);
          margin-bottom: 10px;

          input[type="range"] {
            width: inherit;
            height: 7px;
            background: var(--dark-theme-element);
            border-radius: 5px;
            accent-color: var(--dark-theme-element) !important;
            background-size: 70% 100%;
            background-repeat: no-repeat;
            position: absolute;
            bottom: -8px;
            left: -3px;
          }

          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
            background: transparent;
            color: $white;
            border: none;
          }

          input[type="number"]:focus {
            outline: none;
            border: 2px solid transparent; /* ya da istediğiniz herhangi bir renk */
          }

          &__data {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;
          }

          &-header {
            margin-bottom: 10px;
          }
        }
      }

      .primary-button {
        &:disabled {
          background-color: grey;
          border-color: grey;
          color: var(--settings-sub-bg);
          // color: rgb(107, 106, 106);
          cursor: not-allowed;

          &:active {
            background-color: grey !important;
          }
        }
      }
    }
  }

  &__field {
    width: 160px;

    &__label {
      color: var(--text-color-card);
      margin-bottom: 4px;
    }

    &__input {
      width: 57%;
      text-align: right;
      padding: 12px 10px;
      color: var(--settings-article-value);
      background-color: var(--settings-field-bg);
      border: 1px solid var(--settings-field-border-color);
      border-radius: 4px;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: var(--text-color-card);
      }
    }

    &__unit {
      color: var(--text-color-card);
      margin-left: 4px;
    }
  }
}
