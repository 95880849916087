@import "../../../styles/colors";

.chart-doughnut {
  position: relative;
  cursor: pointer;

  &__value {
    position: absolute;
    top: 49%;
    left: 50%;
    color: var(--analysis-reports-information-value);
    font-size: 14.5px;
    text-align: center;
    transform: translate(-50%, -25%);
    text-decoration: none;
  }

  &__sub-value {
    font-size: 10px;
  }
}

.chart-radar {
  position: relative;
  min-width: 230px;
  margin-top: 4px;
}

.report-chart-radar {
  position: relative;
  min-width: 230px;
  margin-top: 4px;
  background-color: var(--layout-bg);
  border-radius: 100%;
}

.chart-bar {
  float: right;
  display: table-cell;
  max-width: 457px;
  margin-top: 5px;
  height: 100%;
  width: 80em;
  background-color: var(--dashboard-card-color);
}