@import "../../../../styles/colors";

.clamcloudml {
  &__display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 4px;

    &--report {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
    }
  }
  &__vt {
    &-cursor {
      text-decoration: none;
      cursor: pointer;
    }

    &-default {
      text-decoration: none;
      cursor: default;
    }
  }
  &__border {
    border-style: solid;
    border-width: 2px 2px;
    border-radius: 10px;
    white-space: nowrap;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // padding: 5px;

    &--hidden {
      display: none;
    }
    &--na {
      border-color: var(--border);
    }
    &--detected {
      border-color: #ec374a;
    }
    &--undetected {
      border-color: #58e655;
    }
    &--vt {
      border-color: #0b4dda;
    }
    &--pointer {
      cursor: pointer;
    }
    &--below {
      margin-top: 10px;
    }
  }
  &__text {
    margin: 5px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    border: 1px solid transparent;
    font-family: Nunito, serif;
  }
}
