.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--card-background-color);
  border: 1px solid var(--dashboard-table-header);
  border-radius: 3px;
  width: 650px;
  max-height: 700px;
  color: var(--text-color-card);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  overflow-y: auto;

  &__header {
    font-size: 20px !important;
    margin-bottom: 4px !important;
    padding: 0 !important;
  }

  &__exp {
    font-size: 17px !important;
    color: var(--header-search-text-color) !important;
    margin: 0 0 8px 0 !important;
  }

  &__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;

    &:hover {
      background-color: inherit;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--card-background-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(black, 0.5);
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b;
  }
}

.popup-content {
  &__subsection-container {
    display: grid;
    justify-items: left;

    &__header {
      font-size: 14px !important;
      white-space: wrap;
    }

    &__exp {
      color: var(--header-search-text-color) !important;
    }

    &--row {
      grid-template-columns: 3fr 10fr;
      column-gap: 6px;
    }

    &--column {
      grid-template-columns: 1fr;
      margin: 10px 0 4px;
    }
  }
}