.id-input {
  width: 176px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color) !important;
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.react-ip-input {
  padding: 10px 8px !important;
}

.react-ip-input input {
  background: var(--settings-field-bg) !important;
  color: var(--settings-article-value);
}
