.dateTime {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Main {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 8%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Search {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Email {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Auxilary {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 10%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.Summary {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.RSYSLOG {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.SMTP {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.SNMP {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 10%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.HTTP {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 10%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.yararules {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 12%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.analysisImage {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 8%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.kvm {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 2%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Backup {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 12%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Restore {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 3%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.cfs {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 3%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.resultserver {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 3%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Processing {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 3%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Timeout {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 3%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.process {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 5%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.mlchecker {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 7%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.FTI {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 6%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Detections {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Network {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 11%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Static {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.virustotal {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Suricata {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 13%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.SBOX {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 12%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.maliciousmacrobot {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Reporting {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 12%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.JsonDump {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 11%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.MAEC41 {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 11%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.Routing {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.VPN {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.generatediocs {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15%;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
