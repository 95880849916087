@import "../../../styles/colors";

:root {
  --top-offset: -46px;
}

@-moz-document url-prefix() {
  :root {
    --top-offset: -44px;
  }
}

.submit-popup {
  overflow: visible;
  width: 35em !important;
  // height: 670px;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 2px 2px rgba(60, 130, 180, 0.3);
  -moz-box-shadow: 2px 2px 2px 2px rgba(60, 130, 180, 0.3);
  box-shadow: 2px 2px 2px 1px rgba(15, 75, 125, 0.4);

  .toggle-button {
    margin-left: 0;
  }

  .submit-popup-container {
    margin: 20px 20px 0 20px;
    position: relative;

    &__content {
      height: 382px;
      color: var(--submit-popup-text);

      &--text {
        position: absolute;
        margin: auto;
        width: 90%;
        top: 60%;
        text-align: center;
      }
    }

    &__close-button {
      position: absolute;
      right: 0;
      top: 0;
    }

    &__submitting-block {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--submit-popup-br-line);
      height: 50px;

      &--text {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      select {
        background-image: url("../../Icon-SVG/OSIcon.svg");
        background-size: 1.8em;
        background-position: 10px center;
        border: 1px solid var(--border);
        background-color: var(--submit-popup-background);
        background-repeat: no-repeat;
        width: 165px;
        padding-right: 5px;
        text-align: right;
        height: 35px;
        font-size: 12px;
        color: var(--submit-popup-text);
        margin-bottom: 10px;
      }
    }

    &__submitting-data {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    //CHOOSEN FILES
    &__choosen-files-block {
      border-bottom: 1px solid var(--submit-popup-br-line);
      align-items: center;
      height: 100px;
      word-wrap: break-word;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin;
      scrollbar-color: var(--text-color-card) white;
    }

    &__choosen-files-block::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &__choosen-files-block::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    &__choosen-files-block::-webkit-scrollbar-thumb {
      background: var(--text-color-card);
      border-radius: 10px;
    }

    /* Handle on hover */
    &__choosen-files-block::-webkit-scrollbar-thumb:hover {
      background: #7c849d;
    }

    &__choosen-files-item {
      color: white;
      text-align: left;
      border-bottom: 1px solid var(--submit-popup-br-line);
      margin-top: 5px;
      height: 30px;

      &__icon {
        cursor: pointer;
        border: 1px solid var(--submit-popup-br-line);
        background-color: var(--dark-theme-element);
        border-radius: 20%;
        color: white;

        &__active {
          padding: 6px;
        }
      }

      &__icon:hover {
        cursor: pointer;
        border: 1px solid var(--submit-popup-br-line);
        background-color: var(--submit-popup-br-line);
        border-radius: 20%;

        &__active {
          padding: 6px;
        }
      }

      &__file {
        button {
          cursor: pointer;
          font-weight: bolder;
          background-color: white;
          border: 1px solid var(--dark-theme-element);
        }

        button:hover {
          background-color: var(--dark-theme-element);
          border: 1px solid white;
          color: white;
        }
      }
    }

    &__chosen-files-data {
      font-weight: 400;
      display: block;
      margin-bottom: 4px;
      flex-direction: column;

      &__clip {
        display: block;
        flex-direction: row;

        &:only-child {
          margin-left: 10px;
        }
      }
    }

    // ACTION SCRIPT
    &__action-script {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--submit-popup-br-line);

      &__enforce {
        display: flex;
        margin-top: 1px;

        &--toggle {
          display: flex;
        }
      }

      &__selector {
        select {
          padding-left: 8px;
          border: 1px solid var(--border);
          background-position: center left;
          background-color: var(--submit-popup-background);
          background-repeat: no-repeat;
          width: 155px;
          text-align: left;
          height: 30px;
          font-size: 14px;
          color: var(--submit-popup-text);
          margin: 5px 0;
          flex: 1;
        }
      }

      &__inputs {
        max-width: 730px;
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
      }
    }

    // RANGE
    &__range-container {
      color: var(--submit-popup-text);
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1px;
      position: relative;
      height: 35px;
      width: 200px;
      border: 1px solid var(--border);

      // &--range {
      //   background-color: aqua;
      // }
      input[type="range"] {
        //-webkit-appearance: inherit;
        width: inherit;
        height: 7px;
        background: var(--dark-theme-element);
        border-radius: 5px;
        accent-color: var(--dark-theme-element) !important;
        background-size: 70% 100%;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -8px;
        left: -3px;
      }

      &__data {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 12px;
        cursor: pointer;
      }
    }

    // TYPE
    &__type {
      color: var(--submit-popup-text);
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;

      &--input {
        width: 14px;
        accent-color: var(--dark-theme-element) !important;
        height: 14px;
      }

      &--labels {
        display: flex;
        //flex-direction: row;
        height: 30px;
        margin-top: 4px;
        border-bottom: 1px solid var(--submit-popup-br-line);
        padding-bottom: 10px;
        //margin-top: 4px;
      }
    }

    // MEMORY DUMP
    &__memory-dump {
      color: var(--submit-popup-text);
      height: 50px;
      border-bottom: 1px solid var(--submit-popup-br-line);
      display: flex;
      flex-direction: column;

      &--input {
        width: 14px;
        accent-color: var(--dark-theme-element) !important;
        height: 14px;
      }

      &--labels {
        display: flex;
        //flex-direction: row;

        margin-top: 1px;
      }
    }

    // SELECT VM
    &__vm {
      color: var(--submit-popup-text);
      height: 64px;
      border-bottom: 1px solid var(--submit-popup-br-line);
      display: flex;
      flex-direction: column;
      text-align: right;

      &--input {
        width: 14px;
        accent-color: var(--dark-theme-element) !important;
        height: 14px;
      }

      &--labels {
        display: flex;
        margin-top: 1px;
        height: 50px;

        &__label {
          border-right: 1px solid var(--submit-popup-br-line);
          border-top: 1px solid var(--submit-popup-br-line);

          margin-right: 0;
        }

        &__label:hover {
          background: var(--layout-bg) 60;
          cursor: pointer;
        }

        .button {
          font-size: 13px;
          padding-right: 14px;
        }
      }

      &--button {
        height: 30px;
        background: transparent;
        border: none;
        color: var(--submit-popup-text);
      }

      &--button:hover {
        cursor: pointer;
      }
    }

    // NETWORK TRAFFIC
    &__network-traffic {
      color: var(--submit-popup-text);
      height: 55px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--submit-popup-br-line);

      &--labels {
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: row;

        &--input {
          width: 14px;
          accent-color: var(--dark-theme-element) !important;
          height: 14px;
        }
      }
    }

    &__buttons {
      height: 45px;
      display: flex;
      justify-content: flex-end;
      padding-top: 12px;
      padding-left: 3.5%;
      margin-bottom: 10px;

      &__container {
        margin-right: 30px;
        display: flex;
        justify-content: flex-end;
        height: 30px;
        font-size: 12px;

        &--cancel {
          cursor: pointer;
          width: 70px;
          font-weight: 600;
          color: var(--header-search-text-color);
          border: none;
          border-radius: 4px;
          background: var(--layout-bg);
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
        }

        &--submit {
          width: 70px;
          font-weight: 600;
          color: var(--header-search-text-color);
          border-radius: 4px;
          margin-left: 30px;
          border: none;
          background: var(--dark-theme-element);
          cursor: pointer;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
        }

        &--apply {
          position: absolute;
          top: -1px;
          right: 20px;

          button {
            height: 30px;
            margin-right: 20px;
            padding: 0 6px;
            font-weight: 600;
            color: var(--header-search-text-color);
            border-radius: 4px;
            border: 1px solid var(--dark-theme-element);
            background: transparent;
            cursor: pointer;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
          }

          button:hover {
            background: var(--dark-theme-element);
          }
        }

        &--force {
          margin-top: 5px;
          margin-right: 20px;
        }

        &--extract {
          width: 80px;
          font-weight: 600;
          color: var(--header-search-text-color);
          border-radius: 4px;
          margin-left: 30px;
          border: none;
          background: var(--dark-theme-element);
          cursor: pointer;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 8px 40px 0 rgba(0, 0, 0, 0.19);
          font-size: 12px !important;
        }

        &--cancel:hover {
          background: var(--layout-bg) 60;
        }

        &--submit:hover {
          background-color: var(--dark-theme-element) 80;
        }

        &--submit:hover {
          background-color: var(--dark-theme-element) 80;
        }
      }
    }

    &__information-popup {
      position: absolute;
      top: -1px;

      &--dashboard {
        right: 120px;
      }

      &--artifact {
        right: 40px;
      }
    }
  }

  .button-block {
    top: var(--top-offset);
  }

  .form {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--submit-popup-color-light);
    height: 382px;

    &__exp {
      color: white;
      font-size: 14px;
      margin-top: auto;
      margin-bottom: auto;
      text-align: center;
    }

    &__item {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--submit-popup-br-line);

      &__options {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      &__option {
        display: flex;
        align-items: center;
        gap: 12px;
        color: white;
        font-size: 14px;
      }
    }
  }
}

// ADDITIONAL STYLES
.light {
  font-size: 12px;
  color: var(--submit-popup-color-light);
}

.font-size-12 {
  font-size: 12px;
}

.bleached {
  color: var(--submit-popup-color-bleached);
}

.submission-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--submit-popup-submission-title);
}

.bold {
  font-size: 16px;
  font-weight: 600;
  color: var(--submit-popup-color-bold);
}

.check {
  display: flex;
  flex-direction: row;

  &:only-child {
    margin-left: 10px;
  }
}

.margin-top {
  margin-top: 1px;
}

.margin-top-14 {
  margin-top: 1px;
}

.margin-top-bottom-8 {
  margin: 1px 0;
}

.align-items {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.margin-left-12 {
  margin-left: 12px;
}

/***CLOSE BUTTON START**/

.close-button {
  &__outer {
    float: right;
    position: relative;
    width: 30px;
    cursor: pointer;
  }

  &__inner {
    width: inherit;
    text-align: center;
  }

  &__inner:before,
  &__inner:after {
    position: absolute;
    content: "";
    height: 1px;
    width: inherit;
    background: var(--dark-theme-element);
    left: 0;
    transition: all 0.3s ease-in;
  }

  &__inner:before {
    top: 50%;
    transform: rotate(45deg);
  }

  &__inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }

  &__outer:hover label {
    opacity: 1;
  }

  &__outer:hover &__inner:before,
  &__outer:hover &__inner:after {
    transform: rotate(0);
  }

  &__outer:hover &__inner:before {
    top: 0;
  }

  &__outer:hover &__inner:after {
    bottom: 0;
  }

  &__close {
    font-size: 0.55em;
    line-height: 4em;
    text-transform: uppercase;
    color: var(--dark-theme-element);
    transition: all 0.3s ease-in;
    opacity: 0;
    font-weight: bold;
    cursor: pointer;
  }
}

/***CLOSE BUTTON END**/
