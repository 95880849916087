@import "../../../../../styles/colors";

.overview {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  background: var(--matrix-table-color);
  border-radius: 0 4px 4px 4px;
  position: relative;

  &__table-block {
    flex: 1;
    height: auto;
    position: relative;

    &-main {
      background: var(--attack-map-bg);
      min-height: 585px;
      margin-bottom: 130px;
    }

    &-report {
      background: var(--header-search-text-color);
    }

    .custom-table__footer-pagination {
      width: 100%;
      margin-left: 0;
      bottom: -50px;
    }

    &-description {
      width: 60%;

      @media screen and (max-width: 1500px) {
        min-width: 320px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }

    &-categories {
      width: 13%;

      &__icons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        @media screen and (max-width: 1500px) {
          gap: 2px;
        }
      }

      @media screen and (max-width: 1500px) {
        min-width: 140px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }

    &-ttps {
      width: 12%;

      @media screen and (max-width: 1500px) {
        min-width: 125px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }

    &-last-child {
      width: 5%;

      @media screen and (max-width: 1500px) {
        min-width: 90px;
      }

      @media screen and (max-width: 1350px) {
        font-size: 10px;
      }
    }

    .custom-table th,
    td {
      @media screen and (max-width: 1400px) {
        font-size: 10px;
      }
    }
  }
}

.signature-matches {
  &__details {
    &__information {
      flex: 0.7 1;
    }
  }

  &__json-view {
    &__container {
      letter-spacing: 0.6px;
    }

    &__basic-child-style {
      margin: 4px 0 4px 32px;
    }

    &__expander {
      margin-left: -5px;
    }

    &__label {
      color: #4fdee5 !important;
      margin-right: 8px;
    }

    &__null-value {
      color: #aaa;
    }

    &__undefined-value {
      color: #aaa;
    }

    &__number-value {
      color: #ff8c00 !important;
    }

    &__string-value {
      color: #0ad161 !important;
      overflow-wrap: anywhere;
    }

    &__punctuation {
      color: var(--header-search-text-color) !important;
    }

    &__pointer {
      font-size: 16px;
      color: #aaa !important;
      cursor: pointer;
    }
  }
}