@import "../../../../../styles/colors";

.summary__table {
  border-bottom: 1px solid var(--border);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--settings-table-header);
    padding: 10px 20px;

    p {
      width: 16.6%;
      display: flex;
      color: var(--settings-text-table);
    }
  }

  &-body {
    padding: 0 20px;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 5px 0;
      padding: 10px 0;
      border-bottom: 1px solid var(--border);

      &:last-child {
        border: none;
      }

      p {
        width: 16.6%;
        display: flex;
        color: var(--settings-header-text);

        &:nth-child(1) {
          color: var(--settings-text-table);
        }
      }
    }
  }
}
