.miniMenu {
    &__hidden {
      visibility: hidden;
    }
  
    &__right {
      left: 0;
    }
  
    &__left {
      right: 0;
    }
}
  