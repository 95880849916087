.updatelogo {
  display: flex;
  padding: 10px;
  width: 480px;
  justify-content: center;
  align-items: center;

  &--img {
    border: none;
    width: 100%;
    height: 100%;
  }

  input {
    background: transparent;
    color: white;
    height: 32px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 3px;
  }

  label {
    width: 130px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  tr {
    color: white;
    width: 100px;
  }

  input[type="file"] {
    display: none;
  }

  &--img-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;

    font-weight: 600;
    color: var(--header-search-text-color);
    background: var(--color-blue);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  &--img-upload:hover {
    background-color: transparent;
    border: 1px solid var(--color-blue);
  }

  &--img-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;

    font-weight: 600;
    color: var(--header-search-text-color);
    background: transparent;
    border-radius: 4px;
    border: 1px solid red;
    cursor: pointer;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  &--img-delete:hover {
    background-color: rgba(255, 0, 0, 0.24);
  }
}
