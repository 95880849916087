@import "../../../../styles/colors.scss";

.tree {
  width: 100%;
}

.tree input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.tree input~ul {
  display: none;
}

.tree input:checked~ul {
  display: block;
}

.tree li {
  line-height: 1.2;
  position: relative;
  padding: 0 0 1em 1em;
}

.tree ul li {
  padding: 1em 0 0 1em;
}

.tree>li:last-child {
  padding-bottom: 0;
}

.tree_label {
  position: relative;
  display: inline-block;
  background: var(--card-background-color);
}

label.tree_label {
  cursor: pointer;
  color: var(--dashboard-value-color);
}

label.tree_label:hover {
  color: var(--dashboard-value-color);
}

label.tree_label:before {
  background: #000;
  color: var(--header-search-text-color);
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 1em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: '+';
  text-align: center;
  line-height: .9em;
}

:checked~label.tree_label:before {
  content: '–';
}

.tree li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.5em;
  display: block;
  width: 0;
  border-left: 1px solid #777;
  content: "";
}

.tree_label:after {
  position: absolute;
  top: 0;
  left: -1.5em;
  display: block;
  height: 0.5em;
  width: 1em;
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
  border-radius: 0 0 0 .3em;
  content: '';
}

label.tree_label:after {
  border-bottom: 0;
}

:checked~label.tree_label:after {
  border-radius: 0 .3em 0 0;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  border-bottom: 0;
  border-left: 0;
  bottom: 0;
  top: 0.5em;
  height: auto;
}

.tree li:last-child:before {
  height: 1em;
  bottom: auto;
}

.tree>li:last-child:before {
  display: none;
}

.tree_custom {
  display: block;
  background: #eee;
  padding: 1em;
  border-radius: 0.3em;
}

.tree span {
  color: var(--dashboard-value-color);
  background: var(--card-background-color);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid var(--border);
  margin-left: 10px;
  padding: 5px;
}

.tree ul {
  width: 100% !important;
}

.tree li {
  width: 100% !important;
}