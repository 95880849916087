@import "../../../styles/colors";

h1 {
  color: var(--settings-article-value);
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 33px;
  margin-bottom: 5px;
}

.settings-article {
  padding: 15px 20px;
  color: var(--settings-article-value);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.settings__label {
  color: var(--settings-header-text);
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: 20px;
}

.settings__field {
  width: 176px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.settings__multi-line-field {
  width: 300px;
  min-height: 200px;
  // font-size: 12px;
  outline: none;
  border-radius: 4px;
  padding: 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.settings__field::placeholder {
  color: var(--settings-header-text);
}
