.listGroup {
  background: var(--submit-popup-background);
  width: 510px;
  height: 300px;
  min-height: 320px;
  max-height: 300px;
  border-radius: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 300px !important;
  // transition: all linear 1s;

  &__icon {
    margin-top: 40px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 16px;
    text-align: center;
    color: var(--text-color-checkbox);
    margin-bottom: 30px;
  }

  button {
    margin: 0 12px;
    width: 63px;
    height: 40px;
    font-size: 16px;
  }

  &--compressed {
    width: 700px;
    height: max-content;
    max-height: 450px;
    min-height: 0;
    transition: none;
    padding-bottom: 32px;
  }

  &--staticResults {
    height: max-content;
    // min-height: 250px;
    min-height: min-content;
    max-height: max-content;
    width: fit-content;
    padding: 20px 50px 30px;
    height: max-content;

    .listGroup-buttons {
      padding: 0;
      width: 100%;

      .listGroup-buttons__go-back {
        margin-left: -30px;
      }
      .listGroup-close-button__outer {
        margin-right: -30px;
      }
    }
  }
}

.listGroup-backdrop {
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.listGroupTable {
  width: 90%;
  height: 250px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &--compressed {
    height: max-content;
  }

  &--staticResults {
    min-width: fit-content;
    height: max-content;
  }
}

.listGroupTable::-webkit-scrollbar {
  display: none;
}

.listGroup-buttons {
  padding: 16px 28px 0 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__go-back {
    color: var(--dark-theme-element);
    font-size: 24px;
    font-weight: bolder;
    cursor: pointer;
  }
}

/***CLOSE BUTTON START**/

.listGroup-close-button {
  &__outer {
    position: relative;
    margin-left: auto;
    width: 30px;
    cursor: pointer;
  }

  &__inner {
    width: inherit;
    text-align: center;
  }

  &__inner:before,
  &__inner:after {
    position: absolute;
    content: "";
    height: 1px;
    width: inherit;
    background: var(--dark-theme-element);
    left: 0;
    transition: all 0.3s ease-in;
  }

  &__inner:before {
    top: 50%;
    transform: rotate(45deg);
  }

  &__inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }

  &__outer:hover label {
    opacity: 1;
  }

  &__outer:hover &__inner:before,
  &__outer:hover &__inner:after {
    transform: rotate(0);
  }

  &__outer:hover &__inner:before {
    top: 0;
  }

  &__outer:hover &__inner:after {
    bottom: 0;
  }

  &__close {
    font-size: 0.55em;
    line-height: 4em;
    text-transform: uppercase;
    color: var(--dark-theme-element);
    transition: all 0.3s ease-in;
    opacity: 0;
    font-weight: bold;
    cursor: pointer;
  }
}

/***CLOSE BUTTON END**/
