@import "../../../styles/colors";

.dashboard__card {
  flex: 2;
  display: flex;
  padding: 18px 20px;
  background: var(--card-background-color);
  border-radius: 4px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 1700px) {
    padding: 18px 12px;
  }

  @media screen and (max-width: 1280px) {
    padding: 18px 24px;
  }

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &-inner__icon {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: var(--color-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-inner__text {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__numbers-container {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: var(--dashboard-value-color);

      &-side-value {
        font-size: 14px;
        border-left: 1px solid #dddddd;
        padding-left: 8px;
        line-height: 1;
        color: #dddddd;
      }
    }
  }

  &-inner__text-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: var(--dashboard-text-color);

    &-selected-color {
      color: white !important;
    }
  }

  &-inner__text-value {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: var(--dashboard-value-color);
  }
}

.analysisFreeRun:hover {
  background-color: var(--hover-item-color);
}
