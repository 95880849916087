@import "../../../../../styles/colors.scss";

.date-time {
  &__link {
    color: var(--color-blue);
    font-family: Nunito, serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0;
    cursor: pointer;
  }

  &__article {
    width: 210px;
    line-height: 0;
    padding: 20px 10px 15px 20px;
  }

  .analysis-images__description {
    margin-top: 6px;

    span {
      color: var(--color-blue);
    }
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);

    &-header {
      background: var(--settings-sub-bg);
    }

    &-item {
      border-bottom: 1px solid var(--border);
      padding-bottom: 25px;

      &:last-child {
        border: none;
      }
    }
  }

  .select-box--box {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__type {
    margin-top: 10px;
    margin-bottom: 5px;
    color: var(--text-color-card);
    font-family: Nunito, serif;
    font-size: 12px;
  }

  &__content {
    padding: 0 20px;
  }

  &__dot {
    margin: 5px;
    color: var(--text-color-card);
    font-size: 20px;
  }

  &__dropdown {
    .select-box--box {
      margin: 0;
    }
  }

  &__description {
    padding: 0 20px 15px 20px;
    color: var(--settings-header-text);
    font-size: 12px;
  }

  button {
    margin-left: 30px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      background: var(--settings-table-header);
      padding: 8px 0;
      color: var(--settings-text-table);
      font-size: 12px;

      &-item {
        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2) {
          width: 50%;
        }

        &:nth-child(3) {
          width: 35%;
        }
      }
    }

    &-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 5px;
        margin: 0 15px;
        border-bottom: 1px solid var(--border);
        color: var(--text-color-card);
        font-size: 12px;

        &__value {
          color: var(--settings-text-table);

          &:nth-child(1) {
            width: 6%;
          }

          &:nth-child(2) {
            width: 50%;
          }

          &:nth-child(3) {
            width: 34.5%;
          }
        }
      }
    }
  }
}