@import "../../../../styles/colors";

$z-index: min(10, 100);

.network-activity-map {
  width: 57%;
  z-index: $z-index;
  position: relative;

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
}

#network-activity-map-scroll-element {
  position: absolute;
  top: -106px;
}
