.Backdrop-cl {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10001;
  left: 0;
  top: 0;
  background-color: var(--scroll-bar-color);
  display: flex;
  align-items: center;
  text-align: center;

  &--absolute {
    position: absolute;
  }

  &__message {
    position: absolute;
    width: 100%;
    padding-top: 150px;
    font-size: 20px;
    color: white;
    font-weight: bolder;
  }
}
