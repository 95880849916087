.postfixemail-settings {
  background: var(--submit-popup-background);
  width: auto;
  border-radius: 8px;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 300px !important;

  button {
    margin: 25px 0 0 0;
    height: 30px;
    font-size: 14px;
  }

  select {
    padding-left: 8px;
    border: 1px solid var(--border);
    background-position: center left;
    background-color: var(--submit-popup-background);
    background-repeat: no-repeat;
    width: 155px;
    text-align: left;
    height: 40px;
    font-size: 14px;
    color: var(--submit-popup-text);
    margin: 5px 0;
    flex: 1;
  }
}

.postfixemail-settings-backdrop {
  z-index: 100000;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.postfixemail-settingsTable {
  width: 90%;
  height: 250px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &--compressed {
    height: max-content;
  }
}

.postfixemail-settingsTable::-webkit-scrollbar {
  display: none;
}

.postfixemail-settings-buttons {
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

/***CLOSE BUTTON START**/

.postfixemail-settings-close-button {
  &__outer {
    position: relative;
    margin-left: auto;
    width: 30px;
    cursor: pointer;
  }

  &__inner {
    width: inherit;
    text-align: center;
  }

  &__inner:before,
  &__inner:after {
    position: absolute;
    content: "";
    height: 1px;
    width: inherit;
    background: var(--dark-theme-element);
    left: 0;
    transition: all 0.3s ease-in;
  }

  &__inner:before {
    top: 50%;
    transform: rotate(45deg);
  }

  &__inner:after {
    bottom: 50%;
    transform: rotate(-45deg);
  }

  &__outer:hover label {
    opacity: 1;
  }

  &__outer:hover &__inner:before,
  &__outer:hover &__inner:after {
    transform: rotate(0);
  }

  &__outer:hover &__inner:before {
    top: 0;
  }

  &__outer:hover &__inner:after {
    bottom: 0;
  }

  &__close {
    font-size: 0.55em;
    line-height: 4em;
    text-transform: uppercase;
    color: var(--dark-theme-element);
    transition: all 0.3s ease-in;
    opacity: 0;
    font-weight: bold;
    cursor: pointer;
  }
}

/***CLOSE BUTTON END**/

.submit-button {
  float: left;
  margin-top: 100px;
  width: 100px !important;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  color: #73bbe8;
  border-radius: 4px;
  border: 1px solid #73bbe8;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #73bbe8;
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(14, 139, 255, 0.4);
  }
}

.delete-button {
  float: right;
  margin-top: 100px;
  width: 100px !important;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  color: red;
  border-radius: 4px;
  border: 1px solid red;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: red;
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(14, 139, 255, 0.4);
  }
}

.eye_pw {
  position: absolute;
  margin-left: -30px;
  margin-top: 10px;
}

///////ERROR///

.error-message {
  color: #cc0033;
  position: absolute;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}

/* Error Styling */

.label-error {
  color: #cc0033;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin: 15px 0 5px 0;
  position: relative;
}