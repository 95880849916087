.integration-backdrop {
  z-index: 1100;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}

.integration-modal {
  position: absolute;
  background: var(--analysis-report-modal-background);
  width: 35%;
  height: 100%;
  right: 0;
  top: 0;
  animation: animate 0.3s linear;
  transition: 1ms;
  padding: 20px 40px;

  &__content {
    position: relative;
    height: 100%;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--header-search-text-color);
      border-radius: 10px;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}