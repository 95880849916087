:root {
  --background-color: rgba(255, 255, 255, 0.8);
  --header-search-text-color: #333;
}

.products-container {
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1 {
      font-size: 24px;
      color: white;
    }

    p {
      color: var(--dashboard-text-color);
    }
  }
}

.products {
  width: 100%;
  display: flex;
}

.products-sidebar {
  width: 17%;
  min-height: 100vh;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-right: 1px solid var(--dropped--file-lists-sidebar-border-color);
}

.products-no-select {
  width: 82%;
  display: flex;
  align-items: center;
  height: 37.5px;
  margin-left: 12px;
  color: white;
}

.products-body {
  width: 82%;
  position: relative;
  align-self: flex-start;
  margin-top: 43px;
  margin-left: 12px;
  border: 1px solid var(--border);
  border-radius: 4px;
  border-top-left-radius: 0px;
  background-color: var(--settings-background-page);

  .button-block {
    top: -44px;
    left: -1px;

    .button {
      padding: 10px !important;
    }
  }
}

.products-body-header {
  display: flex;
  align-items: center;
  background-color: var(--settings-sub-bg);
  padding: 12px 12px 10px 12px;

  &__title {
    color: white;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 15px;
  }

  &__description {
    border-left: 1px solid var(--settings-header-text);
    padding-left: 15px;
    flex: 1 1;
    font-size: 12px;
    color: var(--settings-header-text);
  }
}

.products-body-form {
  padding: 24px;

  &__line {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 12px;

    &:first-child {
      gap: 50px;
      margin-bottom: 8px;
    }

    &--space {
      margin-top: 34px;
    }
  }

  &__button {
    width: 25px;
    height: 25px;
    background-color: var(--datepicker-date-bg);
    margin-top: 17px;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
      border: 1px solid var(--datepicker-date-bg);
    }
  }
}

.allowed-client-ips-list,
.rcpt-domains-list,
.sender-emails-list {
  max-height: 150px;
  overflow-y: auto;
  padding-left: 0;
  margin-top: 10px;
  list-style: none;
  width: 60%;
  min-width: 700px;
  color: var(--dashboard-value-color);
}

.allowed-client-ip-item,
.rcpt-domain-item,
.sender-email-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid var(--border);
}

.allowed-client-ip-item button,
.rcpt-domain-item button,
.sender-email-item button {
  background: none;
  border: none;
  color: var(--header-search-text-color);
  cursor: pointer;
}
