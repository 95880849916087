.app-cart {
  background-color: var(--integrationmarket-card-bg);
  border-radius: 8px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 20px;
  width: 250px;

  &__title {
    font-size: 24px;
  }

  &__button-container {
    justify-content: center;
    margin-top: auto;
  }

  &__button {
    border-radius: 4px;
    margin-top: 10px;
    width: 50%;
    height: 30px;
    display: inline-block;
    background-color: transparent;
    border: 1px solid var(--color-blue);
    color: var(--color-blue);
    font-size: 14px;
    font-weight: bolder;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      animation: none;
      transition: left 0.3s ease-out;
      cursor: wait;
    }

    &.loading::after {
      left: 0;
      animation: progress 2s linear infinite;
    }

    @keyframes progress {
      0% {
        left: -100%;
      }

      100% {
        left: 0%;
      }
    }

    &.waiting {
      border-radius: 4px;
      background-color: gray;
      color: white !important;
      cursor: none;
    }

    &.waiting:hover {
      background-color: gray !important;
    }

    &.error {
      background-color: red;
      color: white !important;
    }

    &.error:hover {
      background-color: red !important;
      cursor: none;
    }
  }

  &__button:hover {
    background-color: #3b82b4;
    color: white;
  }

  &__button:disabled {
    background-color: gray;
  }

  &__settings-button {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    position: relative;
    overflow: hidden;

    &-btn {
      border: none;
      font-size: 14px;
      font-weight: bolder;
      cursor: pointer;
    }

    &-btn:first-child {
      margin-right: 10px;
    }

    &__config {
      background: transparent;
      display: flex;
      width: auto;
      padding: 10px;

      span {
        margin-left: 5px;
        margin-top: 2px;
      }
    }

    &__config:first-child {
      float: left;
    }

    &__config:hover {
      background-color: transparent;
    }

    &__delete {
      background: transparent;
      display: flex;
      width: auto;
      padding: 10px;

      span {
        margin-left: 5px;
        margin-top: 2px;
      }
    }

    &__delete:first-child {
      float: left;
    }

    &__delete:hover {
      background-color: transparent;
    }
  }

  & .app-cart__item {
    display: flex;
    justify-content: space-between;

    &-container {
      margin: 14px 0;
      display: flex;
      flex-direction: column;
    }

    &-name {
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
      color: var(--color-blue);
      cursor: pointer;

      &:hover {
        color: white;
      }
    }

    &-icon {
      border-radius: 4px;
      height: 60px;
      width: 60px;
      margin-bottom: 20px;
      background-color: #313649;
    }

    &-description {
      font-size: 12px;
      min-height: 90px;
      color: var(--text-color-card);
    }
  }
}
