@import "../../../styles/colors";

button:hover {
  background-color: var(--hover-item-color);
}

.tab-number {
  position: absolute;
  color: var(--tabs-button-number-text);
  font-size: 16px;
  font-weight: bold;
  background-color: var(--tabs-button-number-bg);
  width: 25px;
  height: 25px;
  top: -10px;
  right: -10px;
  border-radius: 100px;
}
