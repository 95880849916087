.malwarestats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 4px;
  width: 100%;

  &__item-container {
    width: 100%;
    height: 75px;
    border: 1px solid var(--border);
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 8px;

    @media screen and (max-width: 1405px) {
      height: 70px;
    }
  }

  &__numbers-container {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__item-percent {
    font-size: 16px;
  }

  &__item-count {
    padding-left: 6px;
    border-left: 1px solid #dddddd;
    line-height: 1;
    font-size: 12px;
    color: #dddddd;
  }

  &__item-name {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
