@import "../../../styles/colors.scss";

.pre-static-table-wrapper {
  width: 100%;
  height: max-content;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $white;
}

.pre-static-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;
  width: fit-content;
  border-collapse: collapse;
  gap: 10px;

  &__header-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid var(--border);

    &__header {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 1px 4px 10px;
      width: 100%;

      h2 {
        width: 144px;
        font-size: 13px;
      }

      &__verdict {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__classification {
        font-size: 12.5px;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    min-width: fit-content;

    &__line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      width: 100%;
      padding: 1px 4px;

      overflow-wrap: break-word;

      &__header {
        font-size: 13px;
        width: 144px;
      }
      &__data {
        width: calc(100% - 144px - 6px);
        text-align: left;
      }
    }
  }

  &__clean {
    padding: 2px 10px;
    border-radius: 8px;
    color: black;
    text-align: center;
    font-weight: 400;
    font-size: 12.5px;
    background: #58e655;
    white-space: nowrap;
  }

  &__suspicious {
    padding: 2px 10px;
    border-radius: 8px;
    color: black;
    text-align: center;
    font-weight: 400;
    font-size: 12.5px;
    background: #e69b55;
    white-space: nowrap;
  }

  &__malicious {
    padding: 2px 10px;
    border-radius: 8px;
    color: black;
    text-align: center;
    font-weight: 400;
    font-size: 12.5px;
    background: #e65555;
    white-space: nowrap;
  }

  &__none {
    padding: 2px 10px;
    border-radius: 8px;
    color: black;
    text-align: center;
    font-weight: 400;
    font-size: 12.5px;
    background: var(--border);
    white-space: nowrap;
  }
}
