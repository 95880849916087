@import "../../../styles/colors";

.cloud-home {
  display: flex;
  flex-direction: column;
  margin: 4% 30% 0 30%;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%;

    &-text {
      color: var(--settings-article-value);
      width: 100%;
      text-align: center;
      margin-top: 2%;
      font-size: 18px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      width: 100%;
      margin: 0;
    }

    &__item-card {
      display: flex;
      justify-content: space-between;
      gap: 14px;

      .dashboard__card:first-child {
        flex: 1;
        background-color: var(--content-background);

        &:hover {
          background-color: var(--hover-item-color);
        }
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      flex-shrink: 0;
      margin-right: 15px;
    }

    &__text {
      font-family: "HandelGotDLig" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: 0.2px;
      -moz-osx-font-smoothing: grayscale;
      font-size: 50px;
      cursor: default;
      user-select: none;
    }

    &__text-cyber {
      color: var(--theme-logo-cyber-text);
    }

    &__text-fortress {
      color: #1082b4;
    }
  }
}
