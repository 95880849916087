.network-detections {
  padding: 20px 40px 100px;

  &__header {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 5fr 2fr;
    margin-bottom: 50px;
    margin-left: 6px;
    margin-right: 6px;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
    p {
      color: var(--dashboard-text-color);
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      align-self: center;
    }

    &__select {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
      justify-self: end;
      align-self: center;
      display: flex;
      align-items: center;
      gap: 6px;
      height: 40px;

      .select {
        text-transform: unset;
      }
    }
  }

  &__table {
    position: relative;
    min-height: 476px;

    th:first-child,
    td:first-child {
      width: 35px;
    }

    // to hide expand icon at the first column of the row
    td.hidden {
      svg {
        visibility: hidden;
        opacity: 1;
        display: none;
      }
    }

    &__alert-type {
      width: 164px;
    }
    &__username {
      width: 90px;
      word-break: keep-all !important;
    }
    &__severity {
      width: 80px;
    }
    &__time {
      span {
        color: var(--collapse-content-text-color);
      }
      width: 160px;
    }
    &__client-ip {
      width: 152px;
    }
    &__server-ip {
      width: 152px;
    }
    &__file-name {
      width: 160px;
      span {
        display: block;
        width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__url {
      position: relative;

      &::before {
        content: " ";
        visibility: hidden;
      }

      span {
        color: var(--collapse-content-text-color);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        right: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__action {
      width: 60px;
      span {
        border-radius: 6px;
        padding: 2px 6px;
      }
      &--block {
        background-color: #e65555;
        color: black !important;
      }
      &--request {
        background-color: var(--color-blue);
        color: white !important;
      }
    }

    &__snort,
    &__sbox {
      padding: 5px 24px;
      display: flex;
      align-items: center;
      gap: 30px;

      p {
        color: var(--network-blocks-detail-text) !important;
      }

      span {
        color: var(--network-blocks-detail-header) !important;
        font-weight: bold;
        margin-right: 4px;
      }
    }

    &__sbox {
      p:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
      }

      button {
        cursor: pointer;
        font-size: 12px;
        border: none;
        background-color: var(--network-blocks-sbox-button-bg);
        color: var(--network-blocks-sbox-button-text);
        border-radius: 4px;
        padding: 8px;

        &:disabled {
          cursor: not-allowed;
          color: var(--network-blocks-sbox-button-disabled-text);
          background-color: var(--network-blocks-sbox-button-disabled-bg);
        }
      }
    }

    &__no-details {
      padding: 5px 24px;
      color: var(--network-blocks-detail-text) !important;
    }
  }
}
