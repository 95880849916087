.download-message,
.upload-message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 55px;

  & p {
    margin-top: 30px;
    font-size: 16px;
    color: white;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  &__file-name {
    font-weight: bold;
  }

  &__progress {
    width: 100%;
    height: 20px;
    border-radius: 3px;
    background-color: var(--settings-background-page);
  }
}

.dump-and-restore-container {
  margin: 20px 40px;
}

.dump {
  &__header {
    font-size: 24px;
  }

  h3 {
    color: var(--settings-article-value) !important;
    font-size: 16px;
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);

    &__header {
      background: var(--settings-sub-bg);
      display: flex;
      align-items: center;

      h3 {
        padding: 20px 20px 15px 20px;
      }

      p {
        border-left: 1px solid var(--settings-header-text);
        padding: 0 15px;
        flex: 1 1;
        font-size: 12px;
        color: var(--settings-header-text);
      }
    }

    &__content {
      padding: 20px 20px 15px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .primary-button {
        &:disabled {
          background-color: grey;
          border-color: grey;
          color: var(--settings-sub-bg);
          cursor: not-allowed;

          &:active {
            background-color: grey !important;
          }
        }
      }
    }

    &__info {
      &--success {
        color: #58e655;
      }

      &--error {
        color: #ec374a;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }
}

.restore {
  padding: 20px 0;

  &__header {
    font-size: 24px;
  }

  h3 {
    color: var(--settings-article-value) !important;
    font-size: 16px;
  }

  &__body {
    border: 1px solid var(--border);
    border-radius: 4px;
    margin-top: 20px;
    background: var(--settings-background-page);

    &__header {
      background: var(--settings-sub-bg);
      display: flex;
      align-items: center;

      h3 {
        padding: 20px 20px 15px 20px;
      }

      p {
        border-left: 1px solid var(--settings-header-text);
        padding-left: 15px;
        flex: 1 1;
        font-size: 12px;
        color: var(--settings-header-text);
      }
    }

    &__content {
      padding: 20px 20px 15px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      p {
        color: #ec374a;
        font-size: 14px;
      }

      .primary-button {
        &:disabled {
          background-color: grey;
          border-color: grey;
          color: var(--settings-sub-bg);
          cursor: not-allowed;

          &:active {
            background-color: grey !important;
          }
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }
}
