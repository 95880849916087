@import "../../../styles/colors";

.MalwareChart {
  text-align: center;
  width: 210px;

  &__chartIcon {
    position: absolute;
    margin-top: 50px;
    margin-left: 90px;
  }

  &__numbers-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    span {
      font-size: 14px;
      padding-left: 8px;
      border-left: 1px solid #8992ab;
      color: #8992ab;
    }
  }
}
