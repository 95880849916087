.statistics {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;

  @media screen and (max-width: 1280px) {
    margin: 0 30px 10px 20px;
  }

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__divider {
    height: 0px;
    border: none;
    border-top: 1px solid;
    width: 90%;
  }

  &__body {
    width: 100%;
    display: flex;
    margin-bottom: 40px;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 7px 20px 7px;
      background: var(--card-background-color);
      padding: 2% 2% 6%;

      @media screen and (max-width: 1280px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &__header {
        color: var(--dashboard-value-color);
        font-size: 18px;
        font-weight: 700;
        line-height: 33px;
        margin-bottom: 5px;
        margin-top: 10px;
      }

      &__notification {
        color: var(--dashboard-value-color);
        font-size: 12px;
        font-weight: 700;
        line-height: 33px;
        margin-bottom: 5px;
      }

      &__filter-area {
        display: flex;
        justify-content: center;
        width: 100%;

        &__filter {
          margin: 0 0.5rem 0 0.5rem;
          margin-bottom: 30px;

          &__header {
            color: var(--settings-header-text);
            font-size: 12px;
            display: flex;
            align-items: center;
            min-height: 20px;
            margin-bottom: 5px;
          }

          &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 40px;
          }
        }

        &__date-picker {
          color: var(--header-search-text-color);
          height: 40px;
          background: var(--settings-field-bg);
          border: 1px solid var(--settings-field-border-color);
          display: flex;
          align-items: center;
          border-radius: 4px;
          position: relative;
          font-family: Nunito, sans-serif;
          outline: none !important; 
        }

        button {
          font-size: 12px;
          max-width: 160px;
          font-weight: 600;
          color: var(--header-search-text-color);
          border-radius: 4px;
          padding: 7px 10px;
          border: 1px solid black;
          background: var(--dark-theme-element);
          cursor: pointer;
        }
      }
    }
  }
}

.statistics__table {
  width: 100%;
  background: var(--card-background-color);
  position: relative;
  border-radius: 0 4px 4px 4px;
  margin: 60px 7px 0 7px;

  p {
    color: var(--dashboard-table-color);
    font-size: 12px;
  }
}

td.statistics-table_submit-type {
  color: white !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--datepicker-close-bg) !important;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: var(--datepicker-date-bg);
  color: #fff;
}

