@import "../../../../../styles/colors.scss";

.syslog {
    margin: 20px 40px;

    h3 {
        color: var(--settings-article-value) !important;
        font-size: 16px;
    }

    &__body {
        border: 1px solid var(--border);
        border-radius: 4px;
        margin-top: 20px;
        background: var(--settings-background-page);

        &__header {
            background: var(--settings-sub-bg);
            display: flex;
            align-items: center;

            h3 {
                padding: 20px 20px 15px 20px;
            }

            p {
                border-left: 1px solid var(--settings-header-text);
                padding-left: 15px;
                flex: 1 1;
                font-size: 12px;
                color: var(--settings-header-text);
            }
        }

        &__content {
            padding: 20px 20px 15px 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;

            &__row {
                display: flex;
                gap: 10px;

                &__connection-type {
                    color: var(--header-search-text-color);
                    font-size: 12px;

                    &__header {
                        color: var(--settings-header-text);
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        min-height: 20px;
                    }

                    &__labels {
                        color: var(--header-search-text-color);
                        font-size: 12px;
                        display: flex;
                        align-items: start;
                    }

                    &__input {
                        accent-color: var(--dark-theme-element) !important;
                    }
                }
            }

            .primary-button {
                &:disabled {
                    background-color: grey;
                    border-color: grey;
                    color: var(--settings-sub-bg);
                    // color: rgb(107, 106, 106);
                    cursor: not-allowed;

                    &:active {
                        background-color: grey !important;
                    }
                }
            }
        }
    }

    &__field {
        width: 160px;

        &__label {
            color: var(--text-color-card);
            margin-bottom: 4px;
        }

        &__input {
            width: 57%;
            text-align: right;
            padding: 12px 10px;
            color: var(--settings-article-value);
            background-color: var(--settings-field-bg);
            border: 1px solid var(--settings-field-border-color);
            border-radius: 4px;

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                color: var(--text-color-card);
            }
        }

        &__unit {
            color: var(--text-color-card);
            margin-left: 4px;
        }
    }
}