@import "../../../styles/colors";

.profilelatest {
  display: flex;
  flex-direction: column;
  margin: 0 30px 20px 40px;
  gap: 60px;

  @media screen and (max-width: 1280px) {
    margin: 0 30px 10px 20px;
  }

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__body {
    margin-left: 7px;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: var(--attack-map-bg);
    margin: 0 0 80px 7px;
    border-radius: 4px;
  }

  &__card {
    border-right: 1px solid var(--border);
    width: 20%;
    margin: 10px 0;
    padding: 0 30px 0 30px;

    &:first-child {
      padding-left: 15px;
      width: 14%;
    }

    &:nth-child(2) {
      width: 26%;
    }

    &:nth-child(3) {
      width: 22%;

      p {
        margin-bottom: 5px;
      }
    }

    &:nth-child(5) {
      width: 22%;
    }

    &:last-child {
      border-right: none;
      width: 14%;
    }

    p {
      color: var(--details-text-color);
      font-size: 12px;
      margin-bottom: 10px;

      @media screen and (max-width: 1400px) {
        font-size: 11px;
      }
    }

    h4 {
      color: var(--settings-article-value);
      font-size: 14px;

      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }

    &-remote {
      background: var(--drag-and-drop-icon-background);
      border-radius: 4px;
      font-weight: 600;
      text-align: center;
      padding: 5px;
      max-width: 150px;
    }

    &-status {
      border-radius: 20px;
      max-width: 60px;
      background: #28c30f;
      text-align: center;
    }
  }
}
