@import "../../styles/colors";

.remote-connection {
  display: flex;
  margin: 0 30px 20px 40px;
  gap: 40px;
  height: 100%;

  @media screen and (max-width: 1280px) {
    margin: 0 30px 10px 20px;
    gap: 20px;
  }

  &__header {
    height: 100%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;

    .back-to-dashboard {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      margin-top: 20px;
    }

    .enable-clipboard {
      margin-bottom: 20px;
    }

    .button {
      border: 1px solid var(--border);
      border-radius: 4px;
      text-align: center;

      &:hover {
        background-color: var(--hover-item-color);
      }

      &:disabled {
        cursor: not-allowed;
        background-color: var(--network-blocks-sbox-button-disabled-bg);
        color: var(--card-background-color);
      }

      &--disabled {
        cursor: not-allowed;
        background-color: var(--network-blocks-sbox-button-disabled-bg);
        color: var(--card-background-color);

        &:hover {
          background-color: var(--network-blocks-sbox-button-disabled-bg);
          color: var(--card-background-color);
        }
      }
    }
  }

  &__iframe-container {
    margin-top: 20px;
    width: 1024px;
    height: 770px;
    position: relative;
  }

  &__iframe {
    &--loading {
      visibility: hidden;
      opacity: 1;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__refresh {
    margin-top: 20px;
    height: fit-content;

    div {
      margin: 0 !important;
    }
  }
}

.spinner {
  height: 20px;
  width: 21px;
  animation: spin 1s linear infinite;
}