@import "../colors";

html[data-theme="dataflowx"] {
    --theme-logo-cyber-text: #{$white};
    --theme-logo-fortress-text: #{$main--blue};

    --layout-bg: #{$df-dark--bg};
    --border: #{#454545};
    --background-color: #{$df-dark--bg};
    --card-background-color: #{$df-dark--element};
    --text-color-card: #{$df-grey--text};
    --text-color-card-article: #{$white};
    --text-color-label: #{$df-grey--text};
    --text-color-checkbox: #{$df-grey--text};
    --color-blue: #{$main--orange};
    --header-background: #{$df-dark-text};
    --header-search-border-color: #{$df-grey--text};
    --header-search-text-color: #{$white};
    --header-search-placeholder-color: #{$df-grey--text};
    --sidebar-background: #{$df-dark--element};
    --sidebar-border-color: #{$df-dark-text};
    --sidebar-active-item-color: #{$white};
    --sidebar-active-item-background: #{$df-grey--text-dark};
    --sidebar-active-icon: #{$white};
    --sidebar-item-secondary: #{$main--orange};
    --hover-item-color: #{$df-grey--text-dark};
    --content-background: #{$df-dark--bg};
    --menu-color: #{$df-grey--text};

    --tabs-button-color: #{$white};
    --tabs-button-number-bg: #{$df-dark--element};
    --tabs-button-number-text: #{$white};

    --checkbox-border: #{$df-dark--gray-text};
    --checkbox-background: #{$df-dark--bg};

    --file-card-tab-active-background: #{$df-dark--element};
    --file-card-tab-non-active-background: #{$df-dark-text};
    --file-card-tab-text-active: #{$white};
    --file-card-tab-text-non-active: #{$df-grey--text};
    --file-card-main-background: #{$df-dark--element};
    --file-card-icons-color: #{$white};
    --file-card-icons-color-hover: #{$white};
    --file-card-input-background-color: #{$df-dark--gray};
    --file-card-input-background: #{$df-dark--gray};
    --file-card-input-color: #{$white};
    --file-card-button-bg-color: #{$df-dark--gray};
    --file-card-button-bg-color-hover: #{$df-dark-text};
    --file-card-button-shadow-color: #{$main--orange};
    --drag-and-drop-text-color: #{$white};
    --drag-and-drop-icon-background: #{$df-dark-text};

    --dashboard-value-color: #{$white};
    --dashboard-card-color: #{$df-dark--element};
    --textarea-background-color: #{$df-dark--gray};
    --textarea-border-color: #{$df-dark--gray-text};
    --dashboard-table-tabs: #{$df-dark-text};
    --dashboard-table-border: #{$df-dark--element};
    --dashboard-table-header: #{$df-dark-text};
    --dashboard-table-row-border: #{$df-dark--gray-text};
    --dashboard-table-row-file-name: #{$white};
    --dashboard-table-row-hash-icon-hover: #{$white};
    --dashboard-table-row-count: #{$df-dark-text};
    --dashboard-table-row-count-text-color: #{$white};
    --dashboard-table-row-os: #{$df-dark-text};
    --dashboard-table-row-os-text-color: #{$white};
    --dashboard-table-progress: #{$df-dark-text};
    --dashboard-table-resubmit-icon: #{$white};
    --dashboard-table-resubmit-icon-hover: #{$df-grey--text};
    --dashboard-table-color: #{$df-grey--text};
    --dashboard-text-color: #{$df-grey--text};

    --analysis-reports-information-value: #{$white};
    --analysis-reports-information-bg: #{$df-dark-text};
    --analysis-reports-classification-bg: #{$df-dark--element};
    --analysis-reports-information-exe: #{$df-dark--element};
    --analysis-reports-information-exe-text: #{$df-grey--text};
    --analysis-reports-table-classification-bg: #{$df-dark-text};
    --analysis-reports-table-classification-color: #{$df-grey--text};
    --analysis-reports-table-tabs: #{$df-dark-text};
    --analysis-reports-table-border: #{$df-dark--element};
    --analysis-reports-table-header: #{$df-dark-text};
    --analysis-reports-table-footer: #{$df-dark-text};
    --toggle-button-bg: #{$df-dark--bg};
    --analysis-reports-color: #{$df-grey--text};

    --matrix-table-color: #{$df-dark--element};
    --matrix-table-color2: #{$df-dark--gray};
    --matrix-header-bg: #{$df-grey--text-dark};
    --matrix-card-color: #{$df-grey--text};
    --matrix-card-bg: #{$df-grey--text-dark};

    --new-comment-bg: #{$df-dark--bg};
    --new-comment-text-color: #{$df-grey--text};
    --comments-message-color: #{$white};
    --chart-value-color: #{$white};

    --map-region-color: #{#404759};
    --map-button-color: #{$white};
    --map-text-color: #{$df-grey--text};

    --submit-popup-background: #{$df-dark--element};
    --submit-popup-text: #{$white};
    --submit-popup-color-dark: #{$df-light--label};
    --submit-popup-color-bleached: #{$df-dark--gray-text};
    --submit-popup-color-bold: #{$white};
    --submit-popup-color-light: #{$df-grey--text};
    --submit-popup-br-line: #{$df-dark--gray-text};
    --submit-popup-buttons-background: #{$df-dark-text};
    --submit-popup-button-cancel: #{$df-dark--element};

    --submit-popup-submission-title: #{$df-grey--text};

    --collapse-menu-header: #{$df-dark-text};
    --collapse-menu-text: #{$df-grey--text};
    --collapse-content-background: #{$df-dark--element};
    --collapse-content-text-color: #{$df-grey--text};
    --api-call-text-color: #{$white};

    --dropped-file-lists-sidebar-card: #{$df-grey--text-dark};
    --dropped-file-lists-sidebar-color: #{$df-grey--text};
    --dropped--file-lists-sidebar-border-color: #{$df-dark--gray-text};
    --dropped--file-lists-table-row-border: #{$df-dark-text};
    --dropped--file-pagination-color: #{$white};
    --dropped--file-pagination-arrow-active-color: #{$df-grey--text};
    --dropped--file-pagination-arrow-color: #{$df-grey--text-dark};
    --dropped--file--hover--table: #{rgba(255, 255, 255, 0.06)};

    --table-yet-color: #{$df-grey--text};
    --search-input-bg: #{$df-dark-text};
    --search-input-text-color: #{$df-grey--text};

    --attack-map-popup-background: #{$df-dark-text};
    --attack-map-popup-file-background: #{$df-dark--element};
    --attack-map-popup-title-text: #{$df-grey--text};
    --attack-map-popup-button: #{$main--orange};
    --attack-map-title-text-color: #{$df-grey--text};
    --attack-map-button-text: #{$white};
    --attack-map-bg: #{$df-dark--element};
    --attack-map-card-default-bg: #{$df-grey--text-dark};
    --attack-map-card-text-color: #{$white};
    --attack-map-card-line-color: #{$main--orange};
    --attack-map-card-virus-line-color: #{$df-alert-red};
    --attack-map-card-bg-hover: #{$df-dark--gray};

    --details-tab-bg: #{$df-dark-text};
    --details-tab-block-bg: #{$df-dark-text};
    --details-text-color: #{$df-grey--text};
    --details-text-active-color: #{$df-grey--text};

    --analysis-report-modal-background: #{$df-dark-text};
    --analysis-report-modal-close-button: #{$white};
    --analysis-report-modal-file-background: #{$df-dark--element};
    --analysis-report-modal-file-text: #{$df-grey--text};
    --analysis-report-modal-title-color: #{$df-grey--text};
    --analysis-report-modal-text-data: #{$white};
    --analysis-report-modal-content-background: #{$df-dark--element};

    --av-yara-content-background: #{$df-dark--element};
    --files-info-text-color: #{$df-grey--text};
    --files-info-all-text-color: #{$white};

    --scroll-bar-color: #{rgba(0, 0, 0, 0.5)};
    --sort-color: #{$df-dark-text};
    --sort-color-asc: #{$df-grey--text};
    --sort-color-desc: #{$df-grey--text};

    --settings-background-page: #{$df-dark--element};
    --settings-sidebar-bg: #{$df-dark--bg};
    --settings-article-value: #{$white};
    --settings-table-header: #{$df-dark-text};
    --settings-button-bg: #{$df-dark-text};
    --settings-field-bg: #{$df-dark--bg};
    --settings-field-border-color: #{$df-dark--gray-text};
    --settings-description: #{$df-grey--text};
    --settings-sidebar-icon: #{$df-dark--gray-text};
    --settings-sidebar-border: #{$df-dark--gray-text};
    --settings-sub-bg: #{$df-dark-text};
    --settings-text-table: #{$df-grey--text};
    --settings-header-text: #{$df-grey--text};

    --toggle-background: #{$df-grey--text-dark};
    --tooltip-background: #{$df-dark--gray-text};
    --tooltip-modal-background: #{$df-dark--bg};

    --tags-fields-bg: #{$df-dark--bg};
    --tags-fields-border: #{$df-dark--gray-text};
    --tags-fields-color: #{$white};
    --tags-fields-placeholder: #{$df-grey--text};
    --tags-fields-bg-tags: #{$df-dark-text};
    --tags-filed-label: #{$df-grey--text};
    --tags-tag-color: #{$df-grey--text};

    --runningan-vm-connection: #{$df-dark-text};

    --profile-name-text-color: #{$white};
    --profile-text-color: #{$df-grey--text};

    --network-blocks-detail-header: #{$df-grey--text};
    --network-blocks-detail-text: #{$white};
    --network-blocks-domain-border: #{$yellow};
    --network-blocks-snort-url-border: #{$orange};
    --network-blocks-sbox-border: #{$red};
    --network-blocks-sbox-submit-border: #{$main--orange};
    --network-blocks-sbox-button-bg: #{$main--orange};
    --network-blocks-sbox-button-text: #{$white};
    --network-blocks-sbox-button-disabled-bg: #{$df-grey--text-dark};
    --network-blocks-sbox-button-disabled-text: #{$df-dark--element};

    --dark-theme-element: #{$main--orange};
    --shadow-color: #{rgba(255, 74, 28, 0.4)};

    --expandable-card-border-left: #{$green};
    --unexpandable-card-border-left: #{$main--orange};

    --datepicker-close-bg: #{$df-grey--text-dark};
    --datepicker-date-bg: #{$main--orange};

    --integrationmarket-card-bg: #{rgba(255, 255, 255, 0.1)};
}