.agent {
  position: relative;

  &__backdrop {
    z-index: 1100;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
  }

  &__modal {
    position: absolute;
    background: var(--analysis-report-modal-background);
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    animation: animate 0.3s linear;
    transition: 1ms;

    &__tabs {
      height: 86%;
      overflow-x: hidden;
      overflow-y: auto;

      &--more-info {
        .button-block {
          position: relative;
          top: 0 !important;
          margin-bottom: -1px;
          display: flex;
          flex-wrap: wrap-reverse;
        }

        .button {
          border: 1px solid var(--border);
          border-radius: 0;
          margin-right: -1px;
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 40px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #000000, $alpha: 0.5);
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: var(--header-search-text-color);
        border-radius: 10px;
      }
    }
  }

  @keyframes animate {
    0% {
      right: -1000px;
    }

    100% {
      right: 0;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
  }
}

.agent-reports-popup {
  font-size: 12px;
  position: relative;
  margin-bottom: 70px;

  &--more-info {
    margin-bottom: 25px;
  }

  &__files {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    width: max-content;
    background-color: var(--analysis-report-modal-file-background);
    color: var(--analysis-report-modal-file-text);
    border-radius: 4px;

    path,
    rect {
      stroke: var(--analysis-report-modal-file-text);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 18px;
    height: 18px;

    svg,
    path {
      fill: var(--analysis-report-modal-close-button);
    }
  }
}
