@import "../../../../../styles/colors";
.cfsbox {
  &__body {
    position: relative;
    margin-top: 64px;
  }

  &__content {
    border-radius: 0 4px 4px 4px;
    background: var(--card-background-color);
    padding: 15px;
  }

  .toggle-button {
    margin-left: 0;
  }
}
