.input-field {
  width: 200px;
  height: 40px;
  background-color: var(--submit-popup-background);
  border: 1px solid var(--border);
  color: var(--file-card-input-color);
  font-size: 14px;
  padding: 12px;
  margin: 1px 0;

  &:focus {
    outline: none;
  }
}