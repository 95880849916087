@import "../../../styles/colors";

.form-group {
  display: block;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  font-family: Nunito, serif;
  color: var(--text-color-checkbox);
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: var(--checkbox-background);
  border: 1px solid var(--checkbox-border);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: -1px;
}

.form-group input:checked+label:before {
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 7px;
  height: 12px;
  border: solid var(--header-search-text-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}