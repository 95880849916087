@import "../../../../../styles/colors.scss";

.guest-images {
  &__content {
    border-radius: 0 4px 4px 4px;
    background: var(--card-background-color);
    padding-bottom: 20px;
  }

  &__content-kvm {
    border-radius: 0 4px 4px 4px;
    background: var(--card-background-color);
    padding: 15px;
  }

  &__body {
    position: relative;
    margin-top: 64px;

    .custom-table th {
      background: var(--settings-table-header);
      padding: 10px 30px;
      @media screen and (max-width: 1300px) {
        padding: 10px 0 10px 15px;
      }
    }

    .custom-table td {
      padding: 10px 30px;
      @media screen and (max-width: 1300px) {
        padding: 10px 0 10px 15px;
      }
    }
  }

  .guest-images__button {
    margin: 30px 20px auto auto;
  }

  .actions__button {
    font-size: 12px;
    margin-right: 15px;
    color: var(--settings-article-value);
  }
}

.analysis-images__description {
  border-left: 1px solid var(--settings-header-text);
  padding-left: 15px;
  flex: 1;
  font-size: 12px;
  color: var(--settings-header-text);
}
