@import "../../../../styles/colors";
.system-health {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    &__heading {
      color: white;
      display: flex;
      align-items: center;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background: var(--card-background-color);
    border-radius: 4px 4px 4px 4px;

    &--icon {
      display: flex;
      width: 16px;
    }

    &--text {
      color: white;
      font-size: 12px;
      text-align: center;
    }

    &--signal {
      margin-left: auto;

      &--status {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        -ms-transform: none;
        transform: none;

        &.open {
          border-color: #78d965;
          background: #94e185;
        }

        &.close {
          background-color: #c9404d;
          border-color: #c42c3b;
        }

        @keyframes pulse {
          0% {
            opacity: 0.5;
          }

          70% {
            opacity: 0;
            transform: scale(2.5);
          }
          100% {
            opacity: 0;
          }
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          animation: pulse 2s infinite;
          opacity: 1;
          border-radius: 50%;
          top: 0;
          left: 0;
        }

        &.open:after {
          background: #94e185;
        }

        &.close:after {
          background: #c9404d;
        }
      }
    }
  }

  &__spinner {
    margin: 0;
    height: 20px;
    width: 21px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
