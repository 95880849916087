@import "../../../styles/colors";

.map {
  background: var(--matrix-table-color);
  border-radius: 0 4px 4px 4px;
  padding-top: 30px;
  width: 100%;
  height: 100%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1200px;
    padding: 7px 0;
  }

  &__button {
    width: 25%;
    outline: none;
    background: var(--matrix-table-color);
    color: var(--map-button-color);
    border: 1px solid var(--matrix-table-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 10px 0 0 10px;
    font-family: Nunito, serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__button::before {
    content: "";
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }

  &__button-yellow::before {
    background: #e6e055;
  }

  &__button-orange::before {
    background: #e6ac55;
  }

  &__button-orange-red::before {
    background: #e66f55;
  }

  &__button-red::before {
    background: #e65555;
  }

  &-scrollable {
    overflow-wrap: break-word;
    overflow: scroll;
    // max-height: 164px;
    max-height: 139px;
    min-height: 82px;
    position: relative;
  }

  &-scrollable::-webkit-scrollbar {
    display: none;
  }

  &-report__scrollable {
    overflow-wrap: break-word;
    overflow-y: hidden;
  }

  &-report__scrollable::-webkit-scrollbar {
    display: none;
  }

  &-body {
    width: 100%;
    height: 208px;
    padding: 0 18px;
    text-align: center;
    position: relative;

    &__no-region-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: bold;
      color: white;
      z-index: 1;
    }
  }

  &-report-body {
    max-width: 1000px;
    height: 300px;
    margin: auto;
    position: relative;

    &__no-region-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      font-weight: bold;
      color: white;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    // height: 17em;

    &__header {
      border-bottom: 1px solid var(--text-color-card);
      margin: 0 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-report__header {
      border-bottom: 1px solid var(--text-color-card);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__header-item {
      color: var(--map-text-color);
      font-size: 12px;
      line-height: 16px;
      width: 15%;
      padding: 12px 5px;

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 30%;
      }

      @media screen and (max-width: 1600px) {
        font-size: 11px;
      }

      @media screen and (max-width: 1250px) {
        font-size: 12px;
      }
    }

    &-report__header__item {
      color: var(--map-text-color);
      font-size: 14px;
      padding-left: 0.2cm;

      &:first-child {
        flex-basis: 20%;
      }

      &:nth-child(2) {
        flex-basis: 10%;
      }

      &:nth-child(3) {
        flex-basis: 20%;
      }

      &:nth-child(4) {
        flex-basis: 35%;
      }

      &:nth-child(5) {
        flex-basis: 15%;
      }
    }

    &__body {
      margin: 0 18px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--submit-popup-color-dark);
      padding-bottom: 5px;
      padding-top: 5px;
    }

    &-report__body {
      display: flex;
      // justify-content: space-between;
      border-bottom: 1px solid var(--submit-popup-color-dark);
      padding-bottom: 5px;
      padding-top: 5px;
    }

    &__body-item {
      color: var(--map-text-color);
      font-size: 12px;
      line-height: 15px;
      width: 15%;

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 30%;
      }

      @media screen and (max-width: 1600px) {
        font-size: 11px;
      }

      @media screen and (max-width: 1250px) {
        font-size: 12px;
      }
    }

    &-report__body__item {
      color: var(--map-text-color);
      font-size: 14px;
      padding-left: 0.2cm;
      word-break: break-all;

      &:first-child {
        flex-basis: 20%;
      }

      &:nth-child(2) {
        flex-basis: 10%;
      }

      &:nth-child(3) {
        flex-basis: 20%;
      }

      &:nth-child(4) {
        flex-basis: 35%;
      }

      &:nth-child(5) {
        flex-basis: 15%;
      }
    }
  }

  &__no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 11px;
  }
}
