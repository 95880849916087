@import "../../../styles/colors";

.message {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--analysis-reports-information-bg);
  padding: 12px 20px;
  border-radius: 4px;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;

    &__author {
      color: var(--comments-message-color);
    }

    &__datetime {
      color: var(--toggle-background);
    }

    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 6px;

    &__action {
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;
      background: transparent;
      border: none;
      outline: none;

      &:active,
      &:hover {
        background-color: transparent;
      }

      &:active:not(:disabled) {
        background-color: transparent !important;
      }

      &--delete {
        color: #ec374a;
      }

      &--edit {
        color: var(--color-blue);
      }

      &--reply {
        color: var(--color-blue);
      }
    }
  }

  &__body {
    color: var(--comments-message-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__edit {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .primary-button {
      &:disabled {
        background-color: grey;
        border-color: grey;
        color: var(--settings-sub-bg);
        cursor: not-allowed;

        &:active {
          background-color: grey !important;
        }
      }
    }
  }
}