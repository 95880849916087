.iocs {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  background: var(--matrix-table-color);
  border-radius: 0 4px 4px 4px;
  min-height: 572px;
  position: relative;

  &__sidebar {
    width: 16%;
    border-right: 1px solid var(--dropped--file-lists-sidebar-border-color);
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 2px;

    &__menu-item {
      color: white;
      background-color: var(--dashboard-table-tabs);
      outline: none;
      border: 1px solid var(--border);
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
      font-family: Nunito, sans-serif;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: var(--hover-item-color);
      }

      &--active {
        background-color: var(--hover-item-color);
      }
    }
  }

  &__content {
    width: 84%;
    padding: 10px 10px 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--sidebar-active-icon);
    height: 572px;

    &__header {
      display: flex;
      align-items: center;
      gap: 32px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--dropped--file-lists-sidebar-border-color);
    }

    &__download {
      cursor: pointer;
      font-size: 12px;
      border: none;
      background-color: var(--attack-map-popup-button);
      color: var(--attack-map-button-text);
      border-radius: 4px;
      padding: 8px;
    }

    &__bottom {
      padding-top: 10px;
      border-top: 1px solid var(--dropped--file-lists-sidebar-border-color);
    }

    &__list {
      height: 388px;
      overflow: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 40px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #000000, $alpha: 0.5);
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: var(--header-search-text-color);
        border-radius: 10px;
      }
    }

    &__row {
      border-bottom: 1px solid var(--dropped--file-lists-sidebar-border-color);
      padding: 6px;
      font-size: 12px;

      &:first-child {
        margin-top: 3px;
      }
    }

    &__no-data {
      padding: 6px;
      font-size: 12px;
      margin-top: 3px;
    }
  }
}
