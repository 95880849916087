.free-run {
  &__popup {
    position: relative;
    width: 400px;
    padding: 20px;
    background-color: var(--submit-popup-background);
    border-radius: 5px;
    -webkit-box-shadow: 2px 2px 2px 2px rgba(60, 130, 180, 0.3);
    -moz-box-shadow: 2px 2px 2px 2px rgba(60, 130, 180, 0.3);
    box-shadow: 2px 2px 2px 1px rgba(15, 75, 125, 0.4);
  }

  &__information-popup {
    position: absolute;
    top: 20px;
    right: 60px;
  }

  &__option-group {
    padding: 10px 0;
    border-bottom: 1px solid var(--submit-popup-br-line);

    &:first-of-type {
      margin-top: 20px;
    }
  }

  &__option-name {
    font-size: 12px;
    color: var(--submit-popup-color-light);
  }

  &__options {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__label {
    color: white;
  }

  &__btn-container {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  &__start-btn {
    margin-left: auto;
    width: 120px;
    height: 30px;
    font-weight: 600;
    color: var(--header-search-text-color);
    border-radius: 4px;
    margin-left: 30px;
    border: none;
    background: var(--dark-theme-element);
    cursor: pointer;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
}