.multiselect {
  &__options {
    border: 1px solid var(--checkbox-border);
    border-radius: 4px;
    padding: 4px 8px;
    position: absolute;
    width: 100%;
    top: 44px;
    background-color: var(--layout-bg);
    z-index: 9999;
  }

  &__option {
    padding: 2px 4px;
    border-radius: 4px;

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      color: white;
      cursor: pointer;
    }

    &:hover {
      background-color: var(--checkbox-border);
    }

    input{
      accent-color: var(--dark-theme-element) !important;
    }
  }

  &__select-all {
    border-bottom: 1px solid var(--checkbox-border);
    margin-bottom: 10px;
    padding-bottom: 4px;
  }

  &__single-option-area {
    overflow: auto;
  }
}