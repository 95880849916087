@import "../../../styles/colors";

.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin: 15px 0 5px 0;
  position: relative;
  color: var(--text-color-label);
  font-family: Nunito, serif;
}

.field {
  width: 350px;
  height: 48px;
  outline: none;
  border-radius: 4px;
  padding: 10px 50px 10px 10px;
  margin: 5px 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  color: var(--settings-article-value);
  font-family: Nunito, serif;
}

.field::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: var(--text-color-card);
  font-family: Nunito, serif;
}

.password__eye {
  position: absolute;
  top: 35px;
  right: 12px;
}
