@import "../../../../styles/colors.scss";

.agent__card {
  display: flex;
  padding: 20px;
  background: var(--card-background-color);
  border-radius: 4px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
  cursor: pointer;

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &-inner__icon {
    div {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid var(--color-blue);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:first-child {
      margin-bottom: 10px;
    }
  }

  &-inner__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &-inner__text-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: var(--dashboard-text-color);

    &-selected-color {
      color: white !important;
    }
  }

  &-inner__text-value {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--dashboard-value-color);

    b {
      font-size: 16px;
      font-weight: 700;
    }
  }
}