.web-config {
  &__body {
    position: relative;
    margin-top: 65px;
    background: var(--settings-background-page);

    .border {
      border-radius: 0 4px 4px 4px;
    }
  }

  &__button {
    margin-left: 30px;
  }
}

.toggle__article {
  color: var(--text-color-card);
  font-size: 12px;
  margin-bottom: 10px;
}