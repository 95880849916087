@import "../../../styles/colors";

@font-face {
  font-family: "HandelGotDLig";
  src: url("../../assets/Fonts/8fe0fa8c21f30eb8997eb14ef23f3a27.eot");
  /* IE9*/
  src: url("../../assets/Fonts/8fe0fa8c21f30eb8997eb14ef23f3a27.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/Fonts/8fe0fa8c21f30eb8997eb14ef23f3a27.woff2") format("woff2"),
    /* chrome、firefox */ url("../../assets/Fonts/8fe0fa8c21f30eb8997eb14ef23f3a27.woff")
      format("woff"),
    /* chrome、firefox */ url("../../assets/Fonts/8fe0fa8c21f30eb8997eb14ef23f3a27.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("../../assets/Fonts/8fe0fa8c21f30eb8997eb14ef23f3a27.svg") format("svg");
  /* iOS 4.1- */
}

.sidebar {
  background: var(--sidebar-background);
  border-right: 1px solid var(--sidebar-border-color-right);
  position: relative;

  span {
    cursor: pointer;
  }

  span:hover {
    background-color: var(--hover-item-color);
  }

  &__scrollableMenu {
    border-top: 1px solid #464c5e;
    position: fixed;
    margin-top: 80px;
    max-height: calc(100vh - 80px);
    width: 220px;
    padding-bottom: 69px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__scrollableMenu::-webkit-scrollbar {
    display: none;
  }

  &__logo {
    &__icon {
      float: left;
    }

    &__text {
      font-family: "HandelGotDLig" !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: 0.2px;
      -moz-osx-font-smoothing: grayscale;
      font-size: 20px;
      float: right;
      margin-top: 24px;
      margin-left: 10px;
    }

    &__text-cyber {
      color: var(--theme-logo-cyber-text);
    }

    &__text-fortress {
      color: var(--theme-logo-fortress-text);
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;

  &__border-bottom {
    position: relative;
    width: 220px;
    border-bottom: 1px solid var(--sidebar-border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 1300px) {
      margin-bottom: 10px;
    }
  }

  &__border-bottom2 {
    position: relative;
    width: 80px;
    border-bottom: 1px solid var(--sidebar-border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 1300px) {
      margin-bottom: 10px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    text-decoration: none;
    color: var(--text-color-card);
    padding: 25px 10px 25px 27px;

    @media screen and (max-width: 1300px) {
      padding: 20px 10px 20px 27px;
    }
  }

  &__item__active {
    border-left: 3px solid var(--color-blue);
    padding: 25px 10px 25px 24px;
    color: var(--sidebar-active-item-color);
    background: var(--sidebar-active-item-background);

    @media screen and (max-width: 1300px) {
      padding: 15px 10px 15px 24px;
    }
  }

  &__item-text {
    font-family: Nunito, serif;
    font-weight: 600;

    @media screen and (max-width: 1350px) {
      margin: 0 0 0 15px;
    }
  }

  &__subitem {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    text-decoration: none;
    color: var(--text-color-card);
    padding: 25px 20px 25px 35px;

    &--collapsed {
      gap: 5px;
      padding-left: 30px;
    }

    @media screen and (max-width: 1300px) {
      padding: 20px 10px 20px 27px;
    }
  }

  &__subitem-text {
    font-family: Nunito, serif;
    letter-spacing: 0.4px;

    @media screen and (max-width: 1350px) {
      margin: 0 0 0 15px;
    }
  }

  &__subitem__active {
    border-left: 3px solid var(--color-blue);

    color: var(--sidebar-active-item-color);
    background: var(--sidebar-active-item-background);

    @media screen and (max-width: 1300px) {
      padding: 15px 10px 15px 24px;
    }
  }

  &__subitem-sub {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    text-decoration: none;
    color: var(--text-color-card);
    padding: 25px 20px 25px 50px;

    &--collapsed {
      padding-left: 40px;
    }

    @media screen and (max-width: 1300px) {
      padding: 20px 10px 20px 27px;
    }
  }

  &__subitem-sub-text {
    font-family: Nunito, serif;
    letter-spacing: 0.5px;

    @media screen and (max-width: 1350px) {
      margin: 0 0 0 15px;
    }
  }

  &__subitem-sub__active {
    border-left: 3px solid var(--color-blue);
    color: var(--sidebar-active-item-color);
    background: var(--sidebar-active-item-background);

    @media screen and (max-width: 1300px) {
      padding: 15px 10px 15px 24px;
    }
  }
}

.logo {
  position: fixed;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--text-color-card);

  &__icon {
    margin-left: 25px;
  }

  &__img {
    width: 160px;
    height: 30px;

    @media screen and (max-width: 1350px) {
      width: 140px;
      height: 26px;
    }
  }

  h2 {
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin-left: 10px;

    @media screen and (max-width: 1350px) {
      font-size: 16px;
    }

    span {
      color: var(--color-blue);
    }
  }
}

.sidebar {
  background: var(--sidebar-background);
  border-right: 1px solid var(--sidebar-border-color-right);
  position: relative;

  &-item {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    text-decoration: none;
    color: var(--text-color-card);
    padding: 25px 10px 25px 27px;

    &__text {
      font-weight: 500;
    }

    &--active {
      border-left: 3px solid var(--color-blue);
      padding: 25px 10px 25px 24px;
      color: var(--sidebar-active-item-color);
      background: var(--sidebar-active-item-background);

      @media screen and (max-width: 1300px) {
        padding: 15px 10px 15px 24px;
      }
    }

    &--collapsed {
      // flex-direction: column;
      // gap: 4px;
      // height: unset;
      // padding: 6px 0;
      // text-align: center;
      // font-size: 10px;

      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 4px;
      justify-items: center;
      height: unset;
      font-size: 10px;
      padding: 6px 0;
      text-align: center;

      svg {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        justify-self: right;
      }
      p {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
      }
      div {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        justify-self: left;
      }
    }

    &:hover {
      background-color: var(--hover-item-color);
    }

    @media screen and (max-width: 1300px) {
      // padding: 20px 10px 20px 27px;
    }
  }

  &-subitem {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    text-decoration: none;
    color: var(--text-color-card);
    padding: 25px 20px 25px 35px;

    &__text {
      color: var(--sidebar-item-secondary);
      font-weight: 500;
    }

    &--collapsed {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 4px;
      justify-items: center;
      height: unset;
      font-size: 10px;
      padding: 6px 0;
      text-align: center;

      svg {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        justify-self: right;
      }
      p {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
        padding-left: 5px;
      }
      div {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        justify-self: left;
      }
    }

    &--active {
      border-left: 3px solid var(--color-blue);
      background: var(--sidebar-active-item-background);

      p {
        color: var(--sidebar-active-item-color);
      }
    }

    &:hover {
      background-color: var(--hover-item-color);
    }
  }

  &-grandsubitem {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    text-decoration: none;
    color: var(--text-color-card);
    padding: 25px 20px 25px 50px;

    &__text {
      font-weight: 300;
    }

    &--collapsed {
      gap: 5px;
      padding-left: 40px;
      flex-direction: column;
      gap: 4px;
      height: unset;
      font-size: 10px;
      padding: 6px 0;
      padding-left: 14px;
      text-align: center;
    }

    &--active {
      border-left: 3px solid var(--color-blue);
      color: var(--sidebar-active-item-color);
      background: var(--sidebar-active-item-background);
    }

    &:hover {
      background-color: var(--hover-item-color);
    }
  }
}
