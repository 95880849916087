.multiselect {
  &__container {
    position: relative;
    width: 178px;
    max-width: 300px;
  }

  &__select {
    position: relative;
    padding: 4px 8px;
    border: 1px solid var(--checkbox-border);
    border-radius: 4px;
    background-color: var(--layout-bg);
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &__expand-arrow {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      height: max-content;
    }

    &:hover {
      border-color: aliceblue;
    }

    &--active {
      border-color: var(--datepicker-date-bg);
    }
  }

  &__text {
    width: 94%;

    &--extralight {
      color: white;
    }

    &--light {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}