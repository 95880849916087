.integration-market {
  flex-direction: column;
  margin: 20px;

  @media screen and (max-width: 1280px) {
    margin: 20px;
  }

  &__header {
    margin-left: 0.5%;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin-bottom: 5px;
      margin-top: 20px;
    }

    p {
      color: var(--dashboard-text-color);
      margin: 10px 0 20px 0;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Kartları sola daya */
    margin: 0 auto;
  }

  &__images {
    height: 60px;
    width: 60px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
}
