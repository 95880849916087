@import "../../../../styles/colors.scss";

.reportpdf {
  display: block;
  width: 100%;
}

.reportpdf-main {
  width: 100%;
  height: auto;
  color: black;

  &__content {
    color: black !important;
    width: 100%;

    > * {
      border-radius: 4px 4px 4px 4px;

      h2 {
        cursor: pointer;
        color: black;
        padding: 10px;
        font-weight: 700;
        border-bottom: 1px solid black;
        width: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    
    &--date {
      width: 100%;
      text-align: right;
    }
    &--header {
      text-align: center;
      font-size: 40px;
      font-weight: bolder;
    }
    &--img{
      width: auto;
      height: 100px;
    }

    &--general {
      &-content {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        

        table {
          flex: 1;
          font-size: 12px !important;
          
          tbody{
            tr{
              td{
                span{
                  word-break: break-all
                }
              }
            }
          }
        }

        &_score {
          flex: 1;
          border-left: 1px solid black;
          padding-left: 10px;

          span {
            color: black !important;
            padding: 5px;
            font-size: 14px;
          }

          &-verdict {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &-chart {
            color: black !important;
            margin: 0 auto;
            text-align: center;

            .chart-doughnut__value {
              color: black !important;
            }
          }
        }
      }
      &-content.visible {
        display: flex;
      }
      &-content.hidden {
        display: none;
      }
    }

    &--classification {
      &-content {
        color: var(--tabs-button-color);
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        table {
          flex: 1;
          font-size: 12.5px !important;
        }

        &_score {
          flex: 1;
          border-left: 1px solid black;
          padding-left: 10px;

          span {
            padding: 5px;
            height: 25px;
            font-size: 14px;
          }

          &-chart {
            margin: 0 auto;
            text-align: center;
          }
        }
      }
      &-content.visible {
        display: flex;
      }
      &-content.hidden {
        display: none;
      }
    }

    &--signatures {
      height: 10% !important;

      .custom-table {
        border: none !important;
        margin-top: 10px;
      }
      .custom-table-report {
        background: none !important;
        border: none;
        td {
          background: none !important;
          color: black !important;
          svg {
            display: none;
          }
        }
        tr {
          height: 20px !important;
          background-color: white !important;
        }
        th {
          background-color: gray !important;
          font-weight: bold !important;
          color: black !important;
        }
      }

      &-content.visible {
        display: flex;
      }
      &-content.hidden {
        display: none;
      }
    }

    &--processes {
      height: 10% !important;
      width: 100%;
      word-wrap: break-word;

      &-content.visible {
        display: flex;
      }
      &-content.hidden {
        display: none;
      }

      label {
        width: 65%;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid black;
        background: none;
        padding: 10px;
        color: black;
      }

      .tree span {
        color: black !important;
        background: none !important;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid black;
      }
      input {
        visibility: hidden;
      }
    }

    &--network {
      height: 10% !important;
      width: 100%;
      word-wrap: break-word;

      &-content.visible {
        display: flex;
      }
      &-content.hidden {
        display: none;
      }

      .map {
        background: none;
        color: black !important;
        border: none;
      }
      .map-footer-report__header__item {
        color: black !important;
        font-weight: bold;
        border-color: black !important;
      }
      .map-footer-report__header {
        border-color: black !important;
      }
      .map-footer-report__body__item {
        color: black !important;
        border-color: black !important;
      }
      .map-footer-report__body {
        border-color: black !important;
      }
    }

    &--mitre {
      .matrix-card {
        color: black !important;

        p {
          background: none !important;
          border-left: none !important;
          border-bottom: 1px solid black !important;
        }
      }
      .matrix__block-column--report__header {
        color: black !important;
        font-weight: bold;
        background: none;
        border-bottom: 2px solid black;
      }
    }
  }
  .chart-doughnut {
    &__value {
      color: black !important;
    }
  }
  .classcategories {
    &__text {
      color: black !important;
    }

    &__border {
      margin-left: 20px;
    }
  }
}

.reportpdf-actions {
  background-color: var(--header-background);
  width: 25%;
  height: 245px;
  margin-left: 2%;
  margin-top: 20px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid var(--border);

  h2 {
    color: var(--dashboard-value-color);
    padding: 10px;
    font-weight: 500;
    background-color: var(--card-background-color);
    border-radius: 4px 4px 4px 4px;
  }
}

.reportpdf-loading {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: black;
  position: absolute;
}

