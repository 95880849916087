@import "../../../styles/colors";

.header__inner-cl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--sidebar-border-color-right);
  box-shadow: 0 3px 5px var(--shadow-color);
  background-color: var(--header-background);

  &-left {
    display: flex;
    align-items: center;

    .header__nav {
      display: flex;
      gap: 20px;
      margin-left: 20px;

      &-item {
        cursor: pointer;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 15px;
    border-left: 1px solid var(--text-color-card);
    padding-left: 2em;
  }

  .header__search-input {
    flex-grow: 1;

    .search-input {
      width: 100%;
      input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid var(--header-search-border-color);
        font-size: 16px;
        transition: box-shadow 0.3s ease;

        &:focus {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .header__profile-cl {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-card);
  }
}

.header__auth-buttons {
  display: flex;
  gap: 10px;
  align-items: center;

  .btn-sign-in,
  .btn-sign-up {
    display: flex;
    white-space: nowrap;
    padding: 8px 16px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;

    &.btn-sign-in {
      color: var(--text-color-card);

      &:hover {
        color: var(--sidebar-active-icon);
      }
    }

    &.btn-sign-up {
      background-color: #55a0e6;
      color: var(--primary-color);
    }
  }
}
