.processing {
  &__body {
    position: relative;
    margin-top: 64px;
  }

  &__content {
    border-radius: 0 4px 4px 4px;
    background: var(--card-background-color);
    padding: 15px;
  }

  &__description {
    font-size: 12px;
    color: var(--settings-header-text);

    &-block {
      width: 900px;
      margin: 10px 0 20px 0;

      p {
        line-height: 20px;
      }
    }
  }

  .toggle-button {
    margin-left: 0;
  }

  &-static__header {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    h3 {
      width: 50%;
    }
  }

  &-static__body {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  &-static__body-item {
    width: 50%;
  }
}
