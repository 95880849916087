@import "../../../styles/colors";

.react-table {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  min-height: 600px;
  scroll-margin: 150px;

  &__sub-table {
    min-height: 0 !important;
  }

  &__main-table {
    min-height: 600px;
  }

  &__empty {
    min-height: 600px !important;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--matrix-card-color);
    font-family: Nunito, serif;
    position: relative;

    &__buttons {
      position: absolute;
      top: -30px;
      right: -1px;
      display: flex;
      height: 31px;

      &--overview {
        right: 75px;
      }
    }

    &__filter {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: -5px;
      margin-left: 23px;

      p {
        color: var(--dashboard-text-color);
        font-size: 14px !important;
      }
    }
  }

  table {
    font-family: Nunito, sans-serif;
    border-collapse: collapse;
    width: 100%;
    padding: 10px 0;
    margin: 0 auto;
  }

  thead {
    tr {
      background: var(--analysis-reports-table-header) !important;
      vertical-align: top !important;
    }
  }

  th,
  td {
    border-bottom: 1px solid var(--border);
    padding: 8px;
    font-size: 12px;
  }

  th {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-color-card);
    letter-spacing: 0.3px;
  }

  .th-row {
    display: flex;
    align-items: stretch;
  }

  .th-sort {
    display: flex;
    align-items: stretch;
  }

  tbody {
    width: 100%;
    align-items: center;
    border: none;
    border-bottom: 1px solid var(--dashboard-table-row-border);
    padding: 10px 0;
    color: var(--text-color-card);
    font-size: 12px;
  }

  .spinner {
    // height: 20px;
    // width: 21px;
    animation: spin 1s linear infinite;
    // margin: 0 15px 0 15px !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  &-button-block {
    position: absolute;
    display: flex;
    align-items: center;
    right: -1px;
    top: -30px;

    &-filter {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-left: 23px;
      margin-top: -5px;

      p {
        color: var(--dashboard-text-color);
        font-size: 14px !important;
      }
    }

    &__clean-list {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin-right: 18px;

      &:hover {
        background-color: transparent;
      }
    }

    &--overview {
      right: 75px;
    }
  }

  &-buttons {
    display: flex;
  }

  &-button {
    width: 100%;
    display: block;
    margin: 0 0 25px 0;
    outline: none;
    padding: 10px;
    border: 1px solid var(--border);
    border-top: none;
    background: var(--attack-map-bg);
  }

  &-button__pagination {
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
  }

  span {
    color: var(--text-color-card);
  }

  &-input {
    width: 50px;
    height: 42px;
    outline: none;
    border: 1px solid var(--text-color-card);
    background: var(--matrix-table-color);
    padding: 0 0 0 10px;
    border-radius: 4px;
    font-size: 16px;
    margin: 0 14px;
    color: var(--dropped--file-pagination-color);

    @media screen and (max-width: 1300px) {
      font-size: 13px;
      width: 44px;
      height: 36px;
    }
  }

  .filter__search {
    position: absolute;
    bottom: -35px;
    left: 0;
  }

  .search-input {
    width: 146px;
    border: 1px solid var(--text-color-card);
    outline: none;
    border-radius: 4px;
    height: 32px;
    background: var(--card-background-color);
    color: var(--analysis-reports-information-value);
  }

  .filter {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
  }

  &__footer {
    &-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 0 0 4px 4px;
      border: 1px solid var(--border);
      height: 30px;
      padding: 25px;
      position: absolute;
      bottom: -51px;
      width: calc(100% + 2px);
      margin-left: -1px;
      background: var(--attack-map-bg);

      div[disabled] {
        cursor: default;
      }

      &-arrow {
        padding: 10px 15px;
        cursor: pointer;
      }

      &__number-box {
        input {
          background-color: transparent;
          width: 30px;
          height: 30px;
          color: var(--text-color-card);
          text-align: center;
          margin-left: 10px;
        }

        span {
          color: var(--text-color-card);
        }
      }

      &__number {
        margin: 0 2px;
        font-size: 11px;
        width: 25px;
        height: 25px;
        line-height: 16px;
        color: var(--text-color-card);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__number__active {
        margin: 0 2px;
        color: var(--header-search-text-color) !important;
        width: 25px;
        height: 25px;
        background: var(--color-blue);
        font-size: 11px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  &__running-popup {
    &__file-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 235px;
    }
  }

  &__no-wrap {
    white-space: nowrap;
  }

  &__word-break {
    word-break: break-word !important;
  }

  &__ellipsis-two-lines {
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.table__body-column__score {
  border-radius: 43px;
  max-width: 43px;
  padding: 6px 10px;
  text-align: center;
  font-size: 12px;
  color: var(--header-search-text-color);
  background: #e65555;

  &-one {
    background: #efe07b;
  }

  &-two {
    background: #ebdb50;
  }

  &-three {
    background: #ebc050;
  }

  &-four {
    background: #e68055;
  }

  &-five {
    background: #e65555;
  }
}

.table__body-column__classification {
  width: 90px;
  color: var(--analysis-reports-table-classification-color);
  background: var(--analysis-reports-table-classification-bg);
  padding: 6px;
  border-radius: 4px;
  text-align: center;
}
